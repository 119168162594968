import styled from 'styled-components';

export const ThingsSection = styled.section`
  padding: 70px 0;
  @media (max-width: 600px) {
    padding-bottom: 20px;
  }
  .card {
    border: none;
    h2 {
      font-weight: bold;
      @media (max-width: 600px) {
        font-size: 28px;
        line-height: 45px;
      }
    }
    p {
      @media (max-width: 600px) {
        font-size: 18px;
        line-height: 26px;
      }
    }
    &:not(:last-of-type) {
      margin-bottom: 40px;
      @media (max-width: 600px) {
        margin-bottom: 0px;
      }
    }
  }
`;
