/* eslint-disable react/style-prop-object */
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './style.scss';
import { ReactComponent as StartUpIcon } from '../../../../assets/images/startUpIcon.svg';
import { ReactComponent as MeduimCompanies } from '../../../../assets/images/meduimCompanies.svg';
import { ReactComponent as Enterprises } from '../../../../assets/images/enterprises.svg';

const NeedLayouti = ({ sectionData }) => {
  return (
    <div className="need_layouti">
      <Container>
        <Row className="mb-3 m-2">
          {sectionData?.title && (
            <Col sm="6" className="mb-3 p-0">
              <h2
                className="need_layouti_card_title"
                style={{
                  fontSize: '50px',
                  fontWeight: 'bold',
                  lineHeight: '60px',
                  paddingRight: '20px',
                }}
                data-aos="fade-up"
                data-aos-once="true"
              >
                {sectionData?.title}
              </h2>
            </Col>
          )}
          {sectionData?.description && (
            <Col sm="6">
              {sectionData?.description?.split('|').map((parg) => (
                <p
                  className="fs-5 need_layouti_card_p"
                  data-aos="fade-up"
                  data-aos-once="true"
                  dangerouslySetInnerHTML={{ __html: parg }}
                />
              ))}
            </Col>
          )}
        </Row>
        
       {sectionData&&<Row className="need_layouti_cards_wrap flex-md-nowrap ">
          <Col
            md={4}
            xs={12}
            className="need_layouti_card  m-2"
            data-aos="fade-up"
            data-aos-once="true"
          >
            {/* <StartUpIcon/> */}
            <img src={sectionData?.cards[0]?.image} height="100" />
            {sectionData?.cards[0]?.title && (
              <h2 className="mt-4 fw-bold">{sectionData?.cards[0]?.title}</h2>
            )}
            {sectionData?.cards[0]?.description && (
              <p className="fs-5 mt-md-4">
                {sectionData?.cards[0]?.description}
              </p>
            )}
          </Col>
          <Col
            md={4}
            xs={12}
            className="need_layouti_card  m-2"
            data-aos="fade-up"
            data-aos-once="true"
          >
            {/* <MeduimCompanies/> */}
            <img src={sectionData?.cards[1]?.image} height="100" />
            {sectionData?.cards[1]?.title && (
              <h2 className="mt-4 fw-bold">{sectionData?.cards[1]?.title}</h2>
            )}
            {sectionData?.cards[1]?.description && (
              <p className="fs-5 mt-md-4">
                {sectionData?.cards[1]?.description}
              </p>
            )}
          </Col>
          <Col
            md={4}
            xs={12}
            className="need_layouti_card  m-2"
            data-aos="fade-up"
            data-aos-once="true"
          >
            {/* <Enterprises/> */}
            <img src={sectionData?.cards[2]?.image} height="100" />
            {sectionData?.cards[2]?.title && (
              <h2 className="mt-4 fw-bold">{sectionData?.cards[2]?.title}</h2>
            )}
            {sectionData?.cards[2]?.description && (
              <p className="fs-5 mt-md-4">
                {sectionData?.cards[2]?.description}
              </p>
            )}
          </Col>
        </Row>}


      </Container>
    </div>
  );
};

export default NeedLayouti;
