import React from 'react';
import { Container } from 'reactstrap';
import SkillsCard from './SkillsCard';
import './style.scss';
import { MobileButton } from '../LayoutiComponents/Utilities';
import { useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

const LayoutiSkills = ({ sectionData }) => {
  const navigate = useNavigate();
  return (
    <div className="layouti__skills">
      <Container className="p-0">
        <div className="layouti_skills_head">
          {<h1>{sectionData?.title|| <><Skeleton  width={"100%"} /> <Skeleton  width={"30%"} /> </>}</h1>}
          <div>
            {sectionData?.description ?
              sectionData?.description
                ?.split('|')
                .map((parg) => (
                  <p dangerouslySetInnerHTML={{ __html: parg }} />
                )):<><Skeleton   width={"100%"} height={32}/> <Skeleton   width={"100%"} height={32}/> <Skeleton   width={"70%"} height={32}/></>}
          </div>
        </div>
        <div
          className="skills_card_wraper"
          data-aos="fade-up"
          data-aos-once="true"
        >
          <SkillsCard cardData={sectionData?.cards[0]} cardIndex="01" />
          <SkillsCard cardData={sectionData?.cards[1]} cardIndex="02" />
          <SkillsCard cardData={sectionData?.cards[2]} cardIndex="03" />
          <SkillsCard cardData={sectionData?.cards[3]} cardIndex="04" />
          <SkillsCard cardData={sectionData?.cards[4]} cardIndex="05" />
          <SkillsCard cardData={sectionData?.cards[5]} cardIndex="06" />
        </div>
      </Container>
    </div>
  );
};

export default LayoutiSkills;
