import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import UseScrollHover from '../../../../Hooks/UseScrollHover';
import { CardSection } from './style';
import { Container, Row, Col } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import useWidth from '../../../../Hooks/useWidth';

function UICards({ data }) {
  const [showCursor, hideCursor] = UseScrollHover();
  const navigate = useNavigate();
  const [cardData, setCardData] = useState([]);
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    setProjects(data.projects);
    setCardData(data.cards);
  }, [projects, cardData]);

  const navigationHandler = () => {
    const title = cardData.map((data) => data.title);
    navigate(`/works/details/${title.split(' ').join('_')}`);
  };

  const size = useWidth();

  return (
    <CardSection>
      <div className="container p-0 wrapper">
        <div data-aos="fade-up" data-aos-once="true">
          <h3>{data.title}</h3>
          <p className="desc">{data.firstText}</p>
          {data?.secondText?.split('|').map((parg) => (
            <p
              className="mb-5 desc"
              dangerouslySetInnerHTML={{ __html: parg }}
            />
          ))}
        </div>
        <div className="projects">
          <Container>
            <Row>
              {projects.length !== 0
                ? projects.map((project, index) =>
                    project.status === 1 ? (
                      <div className="col-md-4 project-col-cont" key={index}>
                        <div className="project">
                          <NavLink
                            to={`/works/details/${project.ProjectName.name
                              .split(' ')
                              .join('_')}`}
                            className="w-100"
                            onMouseEnter={showCursor}
                            onMouseLeave={hideCursor}
                          >
                            <div
                              className={`${
                                index < 4 ? 'project-img-cont--full-height' : ''
                              } project-img-cont`}
                            >
                              <div
                                data-aos="new-animation"
                                data-aos-once="true"
                              >
                                <img
                                  src={project?.thumbnailImage}
                                  className="img-fluid h-100"
                                  alt="proj1"
                                />
                              </div>
                            </div>
                          </NavLink>
                        
                            <div className="works__project__name">
                              <h6
                                style={{
                                  fontSize: index >= 4 ? '0.9rem' : '1.2rem',
                                }}
                              >
                                {project?.ProjectName?.name?.split('-')[0]}
                              </h6>
                              <h6>
                               
                                <i className="fas fa-arrow-right"></i>
                              </h6>
                            </div>
                         
                        </div>
                      </div>
                    ) : (
                      <div className="col-md-4 project-col-cont" key={index}>
                        <div className="project">
                          <div
                            className={`${
                              index < 4 ? 'project-img-cont--full-height' : ''
                            } project-img-cont`}
                          >
                            <div data-aos="new-animation" data-aos-once="true">
                              <img
                                src={project?.thumbnailImage}
                                className="img-fluid h-100"
                                alt="proj1"
                              />
                            </div>
                          </div>
                          
                            <div className="works__project__name">
                              <h6
                                style={{
                                  fontSize: index >= 4 ? '0.9rem' : '1.2rem',
                                }}
                              >
                                {project?.ProjectName?.name?.split('-')[0]}
                              </h6>
                            </div>
                          
                        </div>
                      </div>
                    ),
                  )
                : [1, 2, 3, 4].map((sec) => (
                    <Col md={6} key={sec} className="project-col-cont">
                      <Skeleton height={500} width={550} />
                      <Skeleton height={30} width={550} className="mt-3" />
                    </Col>
                  ))}
            </Row>
          </Container>
        </div>
      </div>
    </CardSection>
  );
}

export default UICards;
