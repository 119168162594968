import React, { useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import Card from '../../../Components/365HomeCard/Card';
import './category.scss';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import axiosConfig from '../../../axiosConfig';
import Skeleton from 'react-loading-skeleton';
import CardSkelaton from '../../../Components/365HomeCard/CardSkelaton';
const Category = () => {
  const params = useParams();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  function getData() {
    axiosConfig
      .get(
        `/frontend/365Dsign/projects?category=${params.id
          .split('_')
          .join(' ')}`,
        {
          headers: {
            language: `en`,
          },
        },
      )
      .then((res) => {
        setData(res.data.data.projcts);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const newData = data.filter(
      (item) => item.category.title === params.id.split('_').join(' '),
    );
    setFilteredData(newData);
  }, [data]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="category_cover ">
        {filteredData[0]?<img
          src={filteredData[0]?.category.coverImage}
          alt=""
          className="w-100 d-block "
        />:<Skeleton width={"100%"} height={"100%"}/>}
      </div>
      <Container className="p-0">
        <div className="link_tree mt-5 d-flex align-items-center gap-1">
        {filteredData[0]?<>
            <p>Category </p>
            <p>. {filteredData[0]?.category.title} </p>
          </>: <Skeleton width={200} height={30}/>}
        </div>
        <div>
          <h2 className=" page-title fw-bold mt-20 mb-20">
            {filteredData[0]?.category.title||<Skeleton width={200} height={45}/>}
          </h2>
        </div>
        <div className="mt-5">
          <Container className=" card365_cont ">
            <Row>
              {filteredData.length !== 0?filteredData.map((d) => (
                <Col lg={4} md={6} xs={12} key={d.id}>
                  <Card data={d} />
                </Col>
              )):[1,2,3,4,5,6,7,8,9,10,11,12].map(e =>  <Col lg={4} md={6} xs={12} key={e}><CardSkelaton /></Col> )}
            </Row>
          </Container>
        </div>
      </Container>
    </div>
  );
};

export default Category;
