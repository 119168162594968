import React from "react";
import './ResoursesCategoryBreadCrumb.scss'
import { Container } from "reactstrap";
import { Link } from "react-router-dom";

const ResoursesCategoryBreadCrumb = ({data}) => {
    return (
        <div className="header-bread-crumb mt-5 mb-5">
            <Container>
                <div data-aos='fade-right' data-aos-delay='500' data-aos-duration='1000' data-aos-once='true'>
                    <Link to='/resources' className="header-resourses_bread-crumb-link">
                    {data?.Category?.name} -
                    </Link>
                    <span className="header-resourses_bread-crumb-page">
                        {data?.name}
                    </span>
                </div>
                <p className='header-resourses__description' data-aos='fade-right' data-aos-delay='700' data-aos-duration='1000' data-aos-once='true'>
                {data?.description}
                </p>
            </Container>
        </div>
    );
};

export default ResoursesCategoryBreadCrumb;
