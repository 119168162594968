import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import './Projects.scss';

import { NavLink } from 'react-router-dom';
import UseScrollHover from '../../../../Hooks/UseScrollHover';
import { useState } from 'react';
import { useEffect } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import useWidth from '../../../../Hooks/useWidth';

const Projects = ({ filteredProjects }) => {
  const [projectsData, setProjectData] = useState([]);
  const [showCursor, hideCursor] = UseScrollHover();
  const size = useWidth();
  useEffect(() => {
    setProjectData(filteredProjects);
  }, [filteredProjects]);
  return (
    <div className="projects">
      <Container>
        <Row>
          {projectsData.length !== 0
            ? projectsData.map((project, index) =>
                project.status === 1 ? (
                  <div
                    className={`${
                      index < 4 ? 'col-md-6' : 'col-md-4'
                    } project-col-cont`}
                    key={index}
                  >
                    <div className="project">
                      <NavLink
                        to={`details/${project?.ProjectName?.name
                          .split(' ')
                          .join('_')}`}
                        className="w-100"
                        onMouseEnter={showCursor}
                        onMouseLeave={hideCursor}
                      >
                        <div
                          className={`${
                            index < 4 ? 'project-img-cont--full-height' : ''
                          } project-img-cont`}
                        >
                          <div data-aos="new-animation" data-aos-once="true">
                            <img
                              src={project?.thumbnailImage}
                              className="img-fluid h-100"
                              alt="proj1"
                            />
                          </div>
                        </div>
                      </NavLink>
                        <div className="works__project__name">
                          <h6
                            style={{
                              fontSize: index >= 4 ? '0.9rem' : '1.2rem',
                            }}
                          >
                            {project?.ProjectName?.name?.split('-')[0]}
                          </h6>
                          <h6>
                            {' '}
                            <i className="fas fa-arrow-right"></i>
                          </h6>
                        </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className={`${
                      index < 4 ? 'col-md-6' : 'col-md-4'
                    } project-col-cont`}
                    key={index}
                  >
                    <div className="project">
                      {/* <NavLink to={`details/${project?.ProjectName?.name.split(" ").join("_")}`} className='w-100' onMouseEnter={showCursor} onMouseLeave={hideCursor}> */}
                      <div
                        className={`${
                          index < 4 ? 'project-img-cont--full-height' : ''
                        } project-img-cont`}
                      >
                        <div data-aos="new-animation" data-aos-once="true">
                          <img
                            src={project?.thumbnailImage}
                            className="img-fluid h-100"
                            alt="proj1"
                          />
                        </div>
                      </div>
                      {/* </NavLink> */}
                    
                        <div className="works__project__name">
                          <h6
                            style={{
                              fontSize: index >= 4 ? '0.9rem' : '1.2rem',
                            }}
                          >
                            {project?.ProjectName?.name?.split('-')[0]}
                          </h6>
                        </div>
                     
                    </div>
                  </div>
                ),
              )
            : [1, 2, 3, 4].map((sec) => (
                <Col md={6} key={sec} className="project-col-cont">
                  <Skeleton height={500} width={550} />
                  <Skeleton height={30} width={550} className="mt-3" />
                </Col>
              ))}
        </Row>
      </Container>
    </div>
  );
};

export default Projects;
