import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import styles from './ContactUsAccordionCont.module.css';
import ContactUsAccordion from '../ContactUsAccordion/ContactUsAccordion';
function ContactUsAccordionCont({ sectionData }) {
  const [visibleForm, setVisibleForm] = useState(1);
  let changeVisibleForm = (formNum) => {
    setVisibleForm(formNum);
  };
  return (
    <section className={styles['contact-us__accordion']}>
      <Container>
        <div
          className={styles['contact-us__accordion-buttons-cont']}
          data-aos="fade-up"
          data-aos-once="true"
        >
          {sectionData &&
            sectionData?.map(
              (card, index) =>
                card?.faqsCards.length != 0 && (
                  <button
                    className={`${styles['contact-us__accordion-button']} ${
                      visibleForm === index + 1 &&
                      styles['contact-us__accordion-button--active']
                    }`}
                    onClick={() => {
                      changeVisibleForm(index + 1);
                    }}
                    key={index + 1}
                  >
                    {card?.name}
                  </button>
                ),
            )}
        </div>
        <div className={styles.menu_mobile}>
          <select
            value={visibleForm}
            onChange={(e) => changeVisibleForm(e.target.value)}
          >
            {sectionData &&
              sectionData?.map((category, index) => (
                <option value={index + 1} key={index}>
                  {category.name}
                </option>
              ))}
          </select>
        </div>
        {sectionData &&
          sectionData?.map((card, index) => (
            <ContactUsAccordion
              isVisible={visibleForm == index + 1}
              key={index + 1}
              cardData={card?.faqsCards}
            />
          ))}
      </Container>
    </section>
  );
}

export default ContactUsAccordionCont;
