import {createSlice} from "@reduxjs/toolkit"


const initialState = {header: 1, color: "#26e71e"}

const Layouti = createSlice({
    name: "Layouti",
    initialState,
    reducers: {
        setHeader (state, {payload}) {
            state.header = payload
        },
        setColor (state, {payload}) {
            state.color = payload
        }
    }
})



export const LayoutiActions = Layouti.actions
export default Layouti.reducer