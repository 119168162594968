import React from 'react';
import { Section } from './style';

function AboutServices({ sectionData }) {
  return (
    <Section>
      <div className="container">
        <div className="row">
          <div className="col-md-6 p-0 ">
            {sectionData?.title && <h2>{sectionData?.title}</h2>}
          </div>
          <div className="col-md-6 p-0">
            {sectionData?.description?.split('|').map((parg) => (
              <p dangerouslySetInnerHTML={{ __html: parg }} />
            ))}
          </div>
        </div>
      </div>
    </Section>
  );
}

export default AboutServices;
