import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import axiosConfig from '../../../axiosConfig';
import ContactUsAccordionCont from '../../../Components/LayoutiComponents/ContactUsNew/ContactUsAccordionCont/ContactUsAccordionCont';
import ContactUsDeck from '../../../Components/LayoutiComponents/ContactUsNew/ContactUsDeck/ContactUsDeck';
import ContactUsFAQ from '../../../Components/LayoutiComponents/ContactUsNew/ContactUsFAQ/ContactUsFAQ';
import ContactUsForm from '../../../Components/LayoutiComponents/ContactUsNew/ContactUsForm/ContactUsForm';
import ContactUsGetInTouch from '../../../Components/LayoutiComponents/ContactUsNew/ContactUsGetInTouch/ContactUsGetInTouch';
import ContactUsLetsStart from '../../../Components/LayoutiComponents/ContactUsNew/ContactUsLetsStart/ContactUsLetsStart';
import LayoutiSlider from '../../../Components/LayoutiComponents/Global/LayoutiSlider/LayoutiSlider.jsx';
import { LayoutiActions } from '../../../store/Layouti';
import { useOutletContext } from 'react-router-dom';

function ContactUs() {
  const [pageData, setPageData] = useState([]);
  const [loadactive, setLoadActive] = useOutletContext()
  const [sliderContent, setSliderContent] = useState({
    img: 'images/layouti/contact-us/bg.jpg',
    head: 'Make Layouti your digital agency partner',
    text: 'Our experts blend proprietary methodologies and innovative approaches with tried-and-true digital techniques to deliver extraordinary results.',
  });
  const dispatch = useDispatch();
  
  function getData() {
    setLoadActive(true)
    axiosConfig
      .get(`frontend/contact`, {
        headers: {
          language: `en`,
        },
      })
      .then((res) => {
        // localStorage.setItem('contctUsPage',JSON.stringify(res.data.data))
        setPageData(res.data.data);
        setTimeout(() => {
          setLoadActive(false)
        }, 1500)
      })
      .catch((err) => {
        console.error(err);
      });
  }
  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    dispatch(LayoutiActions.setHeader(1));
  }, [dispatch]);
  useEffect(() => {
    setSliderContent({
      img: pageData?.HeaderContent?.image
        ? pageData?.HeaderContent?.image
        : null,
      head: pageData?.HeaderContent?.title
        ? pageData?.HeaderContent?.title
        : null,
      text: pageData?.HeaderContent?.description
        ? pageData?.HeaderContent?.description
        : null,
    });
  }, [pageData]);
  return (
    <>
      <LayoutiSlider data={sliderContent} />
      <ContactUsLetsStart sectionData={pageData?.WeFiredUpInnovated} />
      <ContactUsForm />
      <ContactUsGetInTouch sectionData={pageData?.Information} />
      <ContactUsDeck sectionData={pageData?.CompanyDeck} />
      <ContactUsFAQ sectionData={pageData?.FAQs} />
      <ContactUsAccordionCont sectionData={pageData?.FAQsCards} />
    </>
  );
}

export default ContactUs;
