import React from 'react';
import { SkillsCards } from './SkillsCardStyle';
import Skeleton from 'react-loading-skeleton';

const SkillsCard = ({ cardData, cardIndex }) => {
  return (
    <div className="skills_card_new">
      <h1>{cardData?.title|| <Skeleton width={'80%'} height={"100%"}/>}</h1>
    <p>{cardData?.description||<><Skeleton count={2} width={'100%'} height={"100%"}/><Skeleton count={1} width={'50%'} height={"100%"}/></>}</p>
    </div>
  );
};

export default SkillsCard;
