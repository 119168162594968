/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useState } from 'react';
import styles from './ContactUsForm.module.css';
import { Container } from 'react-bootstrap';
import ContactUsHireUsForm from '../ContactUsHireUsForm/ContactUsHireUsForm';
import ContactUsSayHelloForm from '../ContactUsSayHelloForm/ContactUsSayHelloForm';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useRef } from 'react';

function ContactUsForm() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [visibleForm, setVisibleForm] = useState(1);
  const [scrollToTopPage, setScrollToTopPage] = useState(true);
  const formRef = useRef();
  let changeVisibleForm = (formNum) => {
    setVisibleForm(formNum);
  };
  useEffect(() => {
    setTimeout(() => {
      searchParams.get('scroll') == 'true' &&
        window.scrollTo({
          top: formRef.current.offsetTop - 250,
        });
      // :
      // window.scrollTo({
      //   top:0
      // })
      searchParams.get('open') == 'hire-us' && setVisibleForm(2);
    }, 50);
  }, [scrollToTopPage]);

  return (
    <div className={styles['contact-us__form']}>
      <Container>
        <div className={styles['contact-us__types-cont']}>
          <button
            className={`${styles['contact-us__types-button']} ${
              visibleForm === 2 && styles['contact-us__types-button--active']
            }`}
            onClick={() => {
              changeVisibleForm(2);
            }}
          >
            Hire Us
          </button>
          <button
            className={`${styles['contact-us__types-button']} ${
              visibleForm === 1 && styles['contact-us__types-button--active']
            }`}
            onClick={() => {
              changeVisibleForm(1);
            }}
          >
            Say Hello
          </button>
        </div>
        <div className="position-relative" ref={formRef}>
          <ContactUsHireUsForm
            isVisible={visibleForm === 2}
            setScrollToTopPage={setScrollToTopPage}
          />
          <ContactUsSayHelloForm
            isVisible={visibleForm === 1}
            setScrollToTopPage={setScrollToTopPage}
          />
        </div>
      </Container>
    </div>
  );
}

export default ContactUsForm;
