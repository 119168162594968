import React, { Fragment } from 'react'
import { useEffect } from 'react'
import { useRef } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './style.scss'


const Indepth = ({data, title, active, setActive, num}) => {
    const oneRef = useRef()
    const twoRef = useRef()
    const threeRef = useRef()
    const fourRef = useRef()
    const topRef = useRef()
    useEffect(() => {
        if (data) {
            switch (num) {
                case 1:
                    return oneRef.current.scrollIntoView()
                case 2:
                    return twoRef.current.scrollIntoView()
                case 3:
                    return threeRef.current.scrollIntoView()
                case 4:
                    return fourRef.current.scrollIntoView()
               default:
                    return topRef.current.scrollIntoView();
            }
        }
    },[data, num]) 
  return( <>
        <div className={`indepth_overlay ${active}`} onClick={() =>setActive('') }></div>
        <div className={`indepth_inner ${active}`}>
            <Container>
                <Row>
                    <Col md={4} className="indepth_left" ref={topRef}>
                        <h3>In depth</h3>
                        <h1>{title}</h1>
                    </Col>
                    <Col md={8} className="indepth_right">
                        {data?.map((card,i) => <div className="indepth_cards" key={i}>
                            <div className="indepth_cards_info">
                                <h1 ref={card.category===1? oneRef:card.category===2? twoRef:card.category===3? threeRef:fourRef}>{card.headLine}</h1>
                            {card.description.split('/').map((text, i) => <p key={i}>{text}</p>)}
                            </div>
                            {card.image&&<img  src={card.image}  alt=""/>}  
                        </div> )}
                    </Col>
                </Row>
            </Container>
        </div>
  </>
   
  )
}

export default Indepth