import React from "react";
import { ArticaleSection } from "./style";
import { FaFacebookF, FaLinkedinIn, FaInstagram, FaLink } from "react-icons/fa";
import srcimg from "../../../assets/images/Awrads_etoyapp.jpeg";
import { Link } from "react-router-dom";
function SingleArticle({ dataContent }) {

  return (
    <ArticaleSection>
      <div className="container position-relative">
        <div className="row article-img-cont position-absolute">
          <div className="col-lg-8">
            {dataContent?.blog?.Images[0] ? (
              <img
                src={dataContent?.blog?.Images[0]?.image}
                alt="article img"
                className="article-img"
              />
            ) : (
              <img src={srcimg} alt="article img" className="article-img" />
            )}
          </div>
          <div className="col-lg-4">
            <Link to="/" className="article-link">
              {dataContent?.blog?.category?.name}
            </Link>
            <h1 className="article-title">{dataContent?.blog?.title}</h1>
          </div>
        </div>
        <div className="row article-content-cont">
          <div className="col-lg-8 pe-lg-5 pe-0 order-2 order-lg-1">
            <div className="left-side" data-aos="fade-up" data-aos-once="true">
              {dataContent?.blog &&
                dataContent?.blog.Paragraphs.map((d, i) => (
                  <div className="article-content" key={i}>
                    {d.title ? <h3>{d.title}</h3> : null}
                    {d.description ?d?.description?.split("|").map(parg => <p dangerouslySetInnerHTML={{__html: parg}} />): null}
                    {d.cards
                      ? d.cards.map((e, ii) => (
                          <img
                            key={ii}
                            className="img-fluid w-100"
                            src={e.image}
                            alt=""
                          />
                        ))
                      : null}
                  </div>
                ))}

              <div className="social" data-aos="fade-up" data-aos-once="true">
                <h4>Share post:</h4>
                <div className="d-flex">
                  <Link className="me-2" to="/">
                    <FaFacebookF />
                  </Link>
                  <Link className="me-2" to="/">
                    <FaLinkedinIn />
                  </Link>
                  <Link className="me-2" to="/">
                    <FaInstagram />
                  </Link>
                  <Link to="/">
                    <FaLink />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 order-1 order-lg-2">
            <div
              className="banner sticky-lg-top"
              data-aos="fade-up"
              data-aos-once="true"
            >
              <div className="d-flex auther-blox pt-4 pt-1 mb-3">
                <img src={dataContent?.blog?.author?.image} alt="" />
                <div className="ms-3 auther-info">
                  <h5>{dataContent?.blog?.author?.name} </h5>
                  <p>{dataContent?.blog?.author?.position}</p>
                </div>
              </div>
              {dataContent?.blog?.author?.description?.split("|").map(parg => <p dangerouslySetInnerHTML={{__html: parg}} />)}
            </div>
          </div>
        </div>
      </div>
    </ArticaleSection>
  );
}

export default SingleArticle;
