/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import styles from './ContactUsSayHelloForm.module.css';
import { Col, Row } from 'react-bootstrap';
import { ReactComponent as TrueIcon } from '../../../../assets/images/true.svg';
import { NavLink } from 'react-router-dom';
import { useState } from 'react';
import axiosConfig from '../../../../axiosConfig';
import { useRef } from 'react';
import { useEffect } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import ReactLoading from 'react-loading';
import { emailPattern } from '../../../../util/features';
function ContactUsSayHelloForm({ isVisible, setScrollToTopPage }) {
  const [values, setValues] = useState({
    fullName: '',
    email: '',
    phone: '',
    message: '',
  });
  const [success_msg, setsuccess_msg] = useState(false);
  const [error_msg, setError_msg] = useState(false);
  const [msg, setMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [acceptCondition, setAcceptConditions] = useState(false);
  const acceptConditionRef = useRef(null);
  const fullNameRef = useRef(null);
  const phoneNumberRef = useRef(null);
  const emailRef = useRef(null);
  const messageRef = useRef(null);
  const formRef = useRef(null);
  function removeRequiredClass(event) {
    if (acceptCondition) {
      acceptConditionRef.current.classList.remove(
        styles['contact-us__form-field-required'],
      );
    }
    if (values.fullName) {
      fullNameRef.current.classList.remove(
        styles['contact-us__form-field-required'],
      );
    }
    if (values.phone) {
      phoneNumberRef.current.classList.remove(
        styles['contact-us__form-field-required'],
      );
    }
    if (emailPattern.test(values.email)) {
      emailRef.current.classList.remove(
        styles['contact-us__form-field-required'],
      );
    }
    if (values.message) {
      messageRef.current.classList.remove(
        styles['contact-us__form-field-required'],
      );
    }
  }
  function submitData() {
    if (!acceptCondition) {
      acceptConditionRef.current.classList.add(
        styles['contact-us__form-field-required'],
      );
    }
    if (!values.fullName) {
      fullNameRef.current.classList.add(
        styles['contact-us__form-field-required'],
      );
    }
    if (!values.phone) {
      phoneNumberRef.current.classList.remove(
        styles['contact-us__form-field-required'],
      );
    }
    if (!values.email || !emailPattern.test(values.email)) {
      emailRef.current.classList.add(styles['contact-us__form-field-required']);
    }
    if (!values.message) {
      messageRef.current.classList.add(
        styles['contact-us__form-field-required'],
      );
    }
    if (
      !values.fullName ||
      !values.email ||
      !values.message ||
      !acceptCondition
    ) {
      setError_msg(true);
      setMsg('Please Fill All Required Fileds');
      return;
    }
    setIsLoading(true);
    axiosConfig
      .post(`frontend/sayHello`, { ...values })
      .then((res) => {
        setIsLoading(false);
        setMsg('Thanks For Applying');
        setsuccess_msg(true);
        setScrollToTopPage((prevValue) => !prevValue);
        setValues({
          fullName: '',
          email: '',
          phone: '',
          message: '',
        });
        formRef.current.reset();
      })
      .catch((err) => {
        setIsLoading(false);
        setError_msg(true);
        setMsg('Sorry Something Went Wrong');
      });
  }
  function handleChangeData(changedName, changedValue) {
    setValues((prevValue) => {
      return {
        ...prevValue,
        [changedName]: changedValue,
      };
    });
  }
  useEffect(() => {
    if (fullNameRef) {
      removeRequiredClass();
    }
  }, [values, acceptCondition]);
  return (
    <form
      className={`${styles['contact-us__say-hello-form']} ${
        !isVisible && styles['contact-us__say-hello-form--hidden']
      }`}
      ref={formRef}
    >
      {success_msg ? (
        <SweetAlert
          title={msg}
          success
          confirmBtnBsStyle="success"
          onConfirm={() => {
            setsuccess_msg(false);
          }}
          onCancel={() => {
            setsuccess_msg(false);
          }}
        ></SweetAlert>
      ) : null}
      {error_msg ? (
        <SweetAlert
          title={msg}
          error
          confirmBtnBsStyle="error"
          onConfirm={() => {
            setError_msg(false);
          }}
          onCancel={() => {
            setError_msg(false);
          }}
        ></SweetAlert>
      ) : null}
      <Row>
        <Col lg="6" md="12">
          <Row>
            <Col lg="12" className={styles['contact-us__form-field-cont']}>
              <label
                htmlFor="contact-us__name"
                className={styles['contact-us__form-field-label']}
              >
                Full name{' '}
                <span className={styles['contact-us__form-field-required']}>
                  *
                </span>
              </label>
              <input
                type="text"
                id="contact-us__name"
                className={styles['contact-us__form-field-input']}
                placeholder="e.g. Yasser Nazzel"
                name="fullName"
                value={values.fullName}
                onChange={(e) => {
                  handleChangeData(e.target.name, e.target.value);
                }}
                ref={fullNameRef}
              />
            </Col>
            <Col lg="12" className={styles['contact-us__form-field-cont']}>
              <label
                htmlFor="contact-us__phone"
                className={styles['contact-us__form-field-label']}
              >
                Phone Number{' '}
                <span className={styles['contact-us__form-field-required']}>
                  *
                </span>
              </label>
              <input
                type="text"
                id="contact-us__phone"
                className={styles['contact-us__form-field-input']}
                placeholder="01********"
                name="phone"
                value={values.phone}
                onChange={(e) => {
                  handleChangeData(e.target.name, e.target.value);
                }}
                ref={phoneNumberRef}
              />
            </Col>

            <Col lg="12" className={styles['contact-us__form-field-cont']}>
              <label
                htmlFor="contact-us__email"
                className={styles['contact-us__form-field-label']}
              >
                Email address
                <span className={styles['contact-us__form-field-required']}>
                  *
                </span>
              </label>
              <input
                type="email"
                id="contact-us__email"
                className={styles['contact-us__form-field-input']}
                placeholder="e.g. hello@layouti.com"
                name="email"
                value={values.email}
                onChange={(e) => {
                  handleChangeData(e.target.name, e.target.value);
                }}
                ref={emailRef}
              />
            </Col>

            <Col lg="12" className={styles['contact-us__form-field-cont']}>
              <label
                htmlFor="contact-us__project-details"
                className={styles['contact-us__form-field-label']}
              >
                Message{' '}
                <span className={styles['contact-us__form-field-required']}>
                  *
                </span>
              </label>
              <textarea
                id="contact-us__project-details"
                className={styles['contact-us__form-field-textarea']}
                placeholder="e.g. Enter your comments here"
                rows="10"
                name="message"
                value={values.message}
                onChange={(e) => {
                  handleChangeData(e.target.name, e.target.value);
                }}
                ref={messageRef}
              ></textarea>
            </Col>

            <Col
              lg="12"
              className={`${styles['contact-us__form-field-cont']} ${styles['contact-us__form-field-privacy-cont']}`}
              style={{ marginBottom: '40px' }}
            >
              <input
                type="checkbox"
                name="privacy-check-hire"
                id="terms-conditions-hire"
                className={styles['contact-us__terms-conditions-input']}
                value={acceptCondition}
                onChange={() => setAcceptConditions(!acceptCondition)}
              />
              <label
                htmlFor="terms-conditions-hire"
                className={styles['contact-us__terms-conditions-label']}
                ref={acceptConditionRef}
              >
                <span
                  className={styles['contact-us__terms-conditions-input-shown']}
                >
                  <TrueIcon
                    className={styles['contact-us__terms-conditions-icon']}
                  />
                </span>
                I accept you{' '}
                <NavLink
                  to="/"
                  className={styles['contact-us__form-field-privacy']}
                >
                  {' '}
                  &nbsp;Terms & Conditions
                </NavLink>
                <span className={styles['contact-us__form-field-required']}>
                  *
                </span>
              </label>
            </Col>

            <div className={styles['contact-us__submit-form-cont']}>
              <button
                type="button"
                onClick={submitData}
                className={styles['contact-us__submit-form']}
                disabled={isLoading ? true : false}
                style={{ height: '60px' }}
              >
                {isLoading ? (
                  <ReactLoading
                    type={'spin'}
                    color={'#ffffff'}
                    height={30}
                    width={30}
                  />
                ) : (
                  'Send request'
                )}
              </button>
            </div>
          </Row>
        </Col>
      </Row>
    </form>
  );
}

export default ContactUsSayHelloForm;
