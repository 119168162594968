import React from "react";
import { Container } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
// import "swiper/css";
// import "swiper/css/pagination";
// import { Pagination } from "swiper";
import "./CategoryWrapper.scss";
import CategorySliderItem from "../CategorySliderItem/CategorySliderItem";
function CategoryWrapper({ data }) {
  return (
    <div className="category-wrapper">
      <Container>
        <div
          className="category-header__wrapper"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-once="true"
        >
          <h2 className="category-header__title">{data?.name}</h2>
          <p className="category-header__description">{data?.description}</p>
        </div>
      </Container>
      <div
        className="category__slider-wrapper"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-once="true"
      >
        <Swiper
          slidesPerView={1}
          spaceBetween={40}
          breakpoints={{
            0: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            1440: {
              slidesPerView: 4,
              spaceBetween: 40,
            },
          }}
          // pagination={{
          // clickable: true,
          // }}
          // modules={[Pagination]}
          className="category-swiper"
        >
          {data?.subCategoeries?.map((subCategory) => (
            <>
              <SwiperSlide>
                <CategorySliderItem  data={subCategory}/>
              </SwiperSlide>
            </>
          ))}
        </Swiper>
      </div>
      {/* </Container> */}
    </div>
  );
}

export default CategoryWrapper;
