import React from 'react';
import useWidth from '../../../../Hooks/useWidth';
import './AboutFeactureCard.scss';

const AboutFeactureCard = (props) => {
  let Icon = props.comp.type;
  let cardData = props.cardData;
  let cardIndex = props.cardIndex;

  const size = useWidth();
  return (
    <div className="about-features__card">
      {/* <Icon className='about-features__card-icon'/> */}
      {size < 600 ? null : (
        <img src={cardData?.image} className="about-features__card-icon" />
      )}
      {cardData?.name && (
        <h3 className="about-features__card-title">
          {cardIndex + 1}. {cardData?.name}
        </h3>
      )}

      {cardData?.description?.split('|').map((parg) => (
        <p
          className="about-features__card-desc"
          dangerouslySetInnerHTML={{ __html: parg }}
        />
      ))}
    </div>
  );
};

export default AboutFeactureCard;
