import React from "react";
import { Accordion } from "react-bootstrap";
import { Container } from "reactstrap";

import styles from "./QuestionsLearnui.module.css";
import { ReactComponent as MinusIcon } from "../../assets/images/minus.svg";
function QuestionsLearnui({ data }) {
  return (
    <div className={`${styles["contact-us__accordion-cont"]}`}>
      <Container>
        <div
          className={`${styles["contact-us__accordion-header"]} text-center`}
        >
          <h2>{data?.QuestionsCollapse?.title}</h2>
          <p>{data?.QuestionsCollapse?.subTitle}</p>
        </div>
        <Accordion defaultActiveKey="0">
          {data?.QuestionsCollapse?.cards?.map((card, index) => (
            <>
              <Accordion.Item
                eventKey={index}
                className={styles["contact-us__accordion-item"]}
              >
                <Accordion.Header
                  className={styles["contact-us__accordion-header"]}
                >
                  <span className={styles["contact-us__accordion-title"]}>
                    {card?.questions}
                  </span>
                  <div className={styles["contact-us__accordion-icon-cont"]}>
                    <MinusIcon
                      className={styles["contact-us__accordion-minus-icon"]}
                    />
                    <MinusIcon
                      className={styles["contact-us__accordion-minus-icon"]}
                    />
                  </div>
                </Accordion.Header>
                <Accordion.Body
                  className={styles["contact-us__accordion-body"]}
                >
                  <p className="contact-us__accordion__desc w-75 mb-0 mt-3">{card?.answer}</p>
                </Accordion.Body>
              </Accordion.Item>
            </>
          ))}
        </Accordion>
      </Container>
    </div>
  );
}

export default QuestionsLearnui;
