import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './style.scss'
const ExportScreen = ({data}) => {
  return (
    <>
       {data?.DesignAppImagesSection?.image&&<><div >
       {data?.DesignAppMobileExportScreen?.title&&<Container className='export_screen'>
                <Row className='align-items-center export_screen_cont'> 
                    <Col className="col-md-6 col-12 d-flex align-items-center justify-content-md-end justify-content-center">
                        <img src={data.DesignAppMobileExportScreen.image} alt="" />
                    </Col>
                    <Col>
                        <h2>{data.DesignAppMobileExportScreen.title}</h2>
                        {data.DesignAppMobileExportScreen?.description?.split('/').map((text, i) => <p key={i}>{text}</p>)}
                    </Col>
                </Row>
            </Container>}
        </div>
        {data?.DesignAppImagesSection&&<div className='export_screen_img'>
            <Container>
                <img  src={data.DesignAppImagesSection.image} alt="" />
            </Container>
        </div>}</>
        }
    </>
    
  )
    
}

export default ExportScreen