import React from "react";
import "./CategorySliderItem.scss";
import itemImageSrc from "../../../assets/images/Awrads_etoyapp.jpeg";
import { Link } from "react-router-dom";
function CategorySliderItem({ data }) {
  return (
    <div className="category-item">
      <Link to={`/resources/${data?.name.split(" ").join("_")}`} className="category-item__link"></Link>
      <div className="category-item__img-wrapper">
        <img
          src={data?.image ? data?.image : itemImageSrc}
          alt="sub category"
          className="category-item__img"
        />
      </div>
      <div className="category-item__content-wrapper">
        <h4 className="category-item__content-title">{data?.name}</h4>
        <p className="category-item__content-description">
          {data?.description}
        </p>
      </div>
    </div>
  );
}

export default CategorySliderItem;
