/* eslint-disable eqeqeq */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container } from 'reactstrap';
import Card from './Card';
import './style.scss';

const LayoutiProducts = ({ sectionData }) => {
  const navigate = useNavigate();
  return (
    <div className="layouti_product js-layouti_product">
      <Container className="p-0" data-aos="fade-up" data-aos-once="true">
        <div className="layouti_product_head">
          {sectionData?.title && <h1>{sectionData?.title}</h1>}
          {sectionData?.description && (
            <p
              dangerouslySetInnerHTML={{ __html: sectionData?.description }}
            ></p>
          )}
        </div>
      </Container>
    </div>
  );
};

export default LayoutiProducts;
