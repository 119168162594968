import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import './products.scss';
import { useDispatch } from 'react-redux';
import { LinkActive } from '../../../store/linkactive';
import { Link } from 'react-router-dom';
import ProductItem from './ProductItem';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

// import {ReactComponent as UiAvatarLogo} from '../../assets/images/UiAvatarLogo.svg'
// import {ReactComponent as Ui365Design} from '../../assets/images/365UiDesign.svg'
import mainImage from '../../../assets/images/365UiDesign.svg';
import axios from 'axios';
import useWidth from '../../../Hooks/useWidth';
// /frontend/365Dsign/staticProducts
const Products = () => {
  const [dataContent, setDataContent] = useState([]);
  const size = useWidth();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(LinkActive.setType('product'));
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    axios
      .get('https://laravel.layouti.com/api/frontend/365Dsign/staticProducts', {
        headers: {
          'Content-Type': 'application/json',
          language: `en`,
        },
      })
      .then((res) => {
        // console.log(res.data.data);
        if (res.data) {
          setDataContent(res.data.data);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  return (
    <>
      {dataContent?.image?<div className="products__header">
        <img src={dataContent?.image} alt="products header" />
      </div>:<Skeleton height={450} width={"100%"}/>}
      <Container className="p-0">
        <div className="prod-card-cont">
          {dataContent?.cards
            ? dataContent?.cards.map((card) => <ProductItem product={card} />)
            : [1, 2, 3, 4].map((sec) => (
                <div key={sec} className="project-col-skel  mt-5">
                    <Skeleton height={318} width={size>600?"100%":"100%"} />
                </div>
              ))}
        </div>
      </Container>
    </>
  );
};

export default Products;
