import React from 'react'
import { useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Deliverables, StepsCovers, StepsInfo } from '../Styled/Index'
import './style.scss'
const ProjectSteps = ({cardDetails}) => {
// console.log(cardDetails);
  
    return (
        <div className='project_steps'>
            <Container className="mb-5">
                <Row >
                    <Col md={7} className=' p-0'>
                        <StepsInfo>
                            <h2>{cardDetails?.TemplateBody?.DesignAppIntorducing?.title}</h2>
                            {cardDetails?.TemplateBody?.DesignAppIntorducing?.description.split("/").map((text,i) => <p key={i}>{text}</p>)}
                        </StepsInfo>
                        <StepsInfo>
                            <h2>{cardDetails?.TemplateBody?.DesignAppTaskDescription?.title}</h2>
                            <p>{cardDetails?.TemplateBody?.DesignAppTaskDescription?.description}</p>
                        </StepsInfo>
                    </Col>
                    <Col md={5}  className='Deliverables_steps'>
                        <Deliverables bgColor={cardDetails?.TemplateBody?.DesignAppDeliverables?.color}>
                            <h2>{cardDetails?.TemplateBody?.DesignAppDeliverables?.title}</h2>
                            {
                                cardDetails?.TemplateBody?.DesignAppDeliverables&&
                                <ul>
                                    {
                                        cardDetails?.TemplateBody?.DesignAppDeliverables?.deliverables&&cardDetails?.TemplateBody?.DesignAppDeliverables?.deliverables.map((item,index)=>(
                                            <li key={index}>{item?.title}</li>
                                            ))
                                    }
                                </ul>
                            }
                        </Deliverables>
                    </Col>
                </Row>
            </Container>
            <Row className="p-0 m-0 mt-5" style={{"marginTop": "150px !important"}}>
                <Col className="p-0 m-0">
                    {/* <StepsCovers bgImg={cardDetails?.TemplateBody?.DesignAppImage?.image}></StepsCovers> */}
                    <img src={cardDetails?.TemplateBody?.DesignAppImage?.image}  className="w-100 mb-md-5" alt=''/>
                </Col>
            </Row>
            
            <Container className='mt-5'>
                <Row>
                    <Col className='d-md-block d-none'>
                    {
                        cardDetails?.TemplateBody?.DesignAppProjectInfo&&cardDetails?.TemplateBody?.DesignAppProjectInfo.map((member,index)=>(
                                <StepsInfo>
                                    <h3>{member?.label}</h3>
                                    <h1>{member?.text}</h1>
                                </StepsInfo>
                            ))
                        }
                    </Col>
                    <Col>
                        <StepsInfo>
                            <h2>{cardDetails?.TemplateBody?.DesignAppWhatIsTheProject?.title}</h2>
                            <p>{cardDetails?.TemplateBody?.DesignAppWhatIsTheProject?.description}</p>
                        </StepsInfo>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ProjectSteps