import React, {useEffect, useState} from 'react'
import { Container } from 'reactstrap'
import './links.scss'
import { useDispatch, useSelector } from 'react-redux'
import {LinkActive} from '../../../store/linkactive'
import axios from 'axios'
import Skeleton from 'react-loading-skeleton'

const Links = () => {
    const dispatch = useDispatch()
    let [links, setLinks] = useState(null);
    useEffect(() => {
        dispatch(LinkActive.setType('links'))
        window.scrollTo(0,0)
    },[dispatch])
   
    useEffect(()=>{
        window.scrollTo(0,0)
        axios.get(`${process.env.REACT_APP_API}frontend/365Dsign/links`)
        .then(res => {
            window.scrollTo(0,0)
            setLinks(res.data.data)
        })
    },[])


    return (
        <Container className='p-0'>
            <div className='links_header_container'>
                {/* top-right */}
                {links?.topRightImage?<img className='absolute__imgs' 
                    src={links?.topRightImage}
                    alt="topRightImage" 
                />: <div className='absolute__imgs'><Skeleton  width={154} height={116}/></div>}
                
                {/* top-left */}
                {/* <img className='absolute__imgs' 
                    src={links?.topLeftImage}
                    alt="topLeft" /> */}
                {links?.topLeftImage?<img className='absolute__imgs' 
                    src={links?.topLeftImage}
                    alt="topLeft" 
                />: <div className='absolute__imgs'><Skeleton  width={154} height={116}/></div>}
                
                {/* bottom-left */}
                {/* <img className='absolute__imgs' 
                    src={links?.bottomLeftImage}
                    alt="bottomLeftImage" /> */}
                {links?.bottomLeftImage?<img className='absolute__imgs' 
                    src={links?.bottomLeftImage}
                    alt="bottomLeftImage" 
                />: <div className='absolute__imgs'><Skeleton  width={154} height={116}/></div>}


                {/* bottom-right */}
                {/* <img className='absolute__imgs' 
                    src={links?.bottomRightImage}
                    alt="bottomRight" /> */}
                 {links?.bottomRightImage?<img className='absolute__imgs' 
                    src={links?.bottomRightImage}
                    alt="bottomRightImage" 
                />: <div className='absolute__imgs'><Skeleton  width={154} height={116}/></div>}

                {/* emoji-right */}
                <img className='emoji__imgs' src='https://365design.layouti.com/wp-content/uploads/2021/09/%E2%89%A1%C6%92yy.png' alt="" />
                {/* emoji-left */}
                <img className='emoji__imgs' src='https://365design.layouti.com/wp-content/uploads/2021/09/%E2%89%A1%C6%92ye.png' alt="" />
                
                <div className='links_header text-center mt-5'>
                    {links?.image?<img className="main-img" src={links?.image} alt=""/>:<Skeleton circle  width={230} height={230}/>}
                    <h3 className='fs-1 mt-3'>
                        {links?.title||<Skeleton   width={"80%"} height={30}/>}
                    </h3>
                    <p className='fs-4 d-block mt-3'>
                        {links?.description||<><Skeleton width={"90%"} height={30}/><Skeleton width={"50%"} height={30}/></>}
                    </p>
                </div>
            </div>
            <div className='social_media_links'>
                    <h1 className=' text-center'>{links?"Social Media Links":<Skeleton width={"50%"} height={30}/>}</h1>
                    <div className='links text-center'>
                        {
                            links?.cards.map(el=> (
                                <a key={el.id} href={el.link} alt={el.title} className='social_media_link'>
                                    {el.title}
                                </a>
                            ))
                        }

                        {!links&&<Skeleton count={10} width={"40%"} height={100}/>}
                    </div>
            </div>
        </Container>
    )
}

export default Links