import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Container } from 'reactstrap';
import axiosConfig from '../../../axiosConfig';
import AboutFeaturesSection from '../../../Components/LayoutiComponents/About/AboutFeaturesSection/AboutFeaturesSection';
import AboutProjectsSection from '../../../Components/LayoutiComponents/About/AboutProjectsSection/AboutProjectsSection';
import AboutTextSection from '../../../Components/LayoutiComponents/About/AboutTextSection/AboutTextSection';
import TeamSection from '../../../Components/LayoutiComponents/About/TeamSection/TeamSection';
import LayoutiSlider from '../../../Components/LayoutiComponents/Global/LayoutiSlider/LayoutiSlider';
import './About.scss';
import { useOutletContext } from 'react-router-dom';

const About = () => {
  const [pageData, setPageData] = useState(null);
  const [loadactive, setLoadActive] = useOutletContext()
  const [sliderContent, setSliderContent] = useState({
    img: 'images/layouti/about/about-bg.jpg',
    head: 'Our work demonstrates our efficiency and speaks about us',
    text: 'We devote all of our efforts to making our work satisfactory for you. The most beautiful thing is to let our work speak for us.',
    buttonText: 'Check Out Our Works',
    buttonLink: '/works',
  });
  let changeSectionBG = () => {
    let teamSection = document.querySelector('.js-team-section');
    let aboutFeatures = document.querySelector('.js-about-features');
    let aboutText = document.querySelector('.js-about-section');

    // transiton between process and design section and our latest works section
    if (window.pageYOffset + 200 > aboutFeatures.offsetTop) {
      aboutFeatures.classList.add('activeUp');
      teamSection.classList.add('activeUp');
    } else {
      aboutFeatures.classList.remove('activeUp');
      teamSection.classList.remove('activeUp');
    }

    // transiton between process and design section and accomplish ui/ux
    if (window.pageYOffset + 200 > aboutText.offsetTop) {
      aboutFeatures.classList.add('activeDown');
      aboutText.classList.add('activeDown');
    } else {
      aboutFeatures.classList.remove('activeDown');
      aboutText.classList.remove('activeDown');
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    document.addEventListener('scroll', changeSectionBG);
    return () => document.removeEventListener('scroll', changeSectionBG);
  }, []);
  function getData() {
    setLoadActive(true)
    axiosConfig
      .get(`frontend/about`, {
        headers: {
          language: `en`,
        },
      })
      .then((res) => {
        // localStorage.setItem('aboutPage',JSON.stringify(res.data.data))
        setTimeout(() => {
          setLoadActive(false)
        }, 1500)
        setPageData(res.data.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    setSliderContent({
      img: pageData?.AboutHeaderContent?.image
        ? pageData?.AboutHeaderContent?.image
        : null,
      head: pageData?.AboutHeaderContent?.title
        ? pageData?.AboutHeaderContent?.title
        : null,
      text: pageData?.AboutHeaderContent?.description
        ? pageData?.AboutHeaderContent?.description
        : null,
      buttonText: 'Check Out Our Works',
      buttonLink: '/works',
    });
  }, [pageData]);
  return (
    <div>
      <LayoutiSlider data={sliderContent} />
      <TeamSection
        sectionData={pageData?.AboutLearnAboutLayouti}
        teamData={pageData?.AboutTeam}
      />
      <AboutFeaturesSection sectionData={pageData?.AboutThroughOurValues} />
      <div className="about-section js-about-section">
        <Container>
          <AboutTextSection
            title={pageData?.AboutCheckOutOurClients?.title}
            content={pageData?.AboutCheckOutOurClients?.description}
          />
        </Container>
        <AboutProjectsSection
          cards={pageData ? pageData.AboutCheckOutOurClients.cards : []}
        />
      </div>
    </div>
  );
};

export default About;
