import styled from "styled-components";


export const OurClients = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background:url('${props=>props.bg}') no-repeat;
    min-height: 570px;
    background-size:cover;
    p { 
        width: 500px;
        font-weight: 400;
        font-size: 24px;
        line-height: 34px;
        margin-top: 20px;
        color: #fff;
    }
    .user_info {
        color: #fff;
        h3 { 
            margin: 0;
            font-size: 20px;
            font-weight: 400;
        }
        p { 
            font-size: 18px;
            font-weight: 300;
            margin: 0;
            line-height: 25px;
        }
    }
    @media (max-width: 600px) {
        padding: 20px;
        min-height: 400px;
        p { 
            width: 100%;
        }
    }
`
