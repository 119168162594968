import React from 'react';
import './PowerCardsLearnui.scss'
import { Container, Row, Col } from 'reactstrap';
import PriceCardsLearnuiUI from '../PriceCardsLearnui/PriceCardsLearnuiUI';
import PriceCardsLearnuiUX from '../PriceCardsLearnui/PriceCardsLearnuiUX';
import PriceCardsLearnuiUIUX from '../PriceCardsLearnui/PriceCardsLearnuiUIUX';


const PowerCardsLearnui = ({data,setValuePackage}) => {
  return (
    <div className='powerCardsLearnui'>
        <Container>
            <div className='cards-header text-center'>
                <h2>One course with a whole lot of power</h2>
                <p>Pick your preferred package of the UI design course</p>
            </div>
            <Row>
                <Col lg={4} md={6} sm={12}>
                    <PriceCardsLearnuiUI data = {data} setValuePackage={setValuePackage} />
                </Col>
                <Col lg={4} md={6} sm={12}>
                    <PriceCardsLearnuiUIUX data = {data} setValuePackage={setValuePackage}/>
                </Col>
                <Col lg={4} md={6} sm={12}>
                    <PriceCardsLearnuiUX  data = {data} setValuePackage={setValuePackage}/>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default PowerCardsLearnui;