import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import LayoutiSlider from '../../../../Components/LayoutiComponents/Global/LayoutiSlider/LayoutiSlider';
import './HeaderContent.scss';

const HeaderContent = ({ secionData }) => {
  const [sliderContent, setSliderContent] = useState({
    img: 'images/layouti/works/works-bg.jpg',
    head: 'Work that drives impact',
    text: 'The only people more fired up about our work than us are our clients. And they have the outcomes to prove it.',
    buttonText: 'Reach Out',
    buttonLink: '/contact-us?scroll=true',
  });
  useEffect(() => {
    setSliderContent({
      img: secionData?.image ? secionData?.image : null,
      head: secionData?.title ? secionData?.title : null,
      text: secionData?.description ? secionData?.description : null,
      buttonText: 'Reach Out',
      buttonLink: '/contact-us?scroll=true',
    });
  }, [secionData]);

  return (
    <div>
      <LayoutiSlider data={sliderContent} />
    </div>
  );
};

export default HeaderContent;
