import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import AboutProjectCard from '../AboutProjectCard/AboutProjectCard';
// import AboutProjectCard from '../AboutProjectCard/AboutProjectCard'
import './AboutProjectsSection.scss';
const AboutProjectsSection = ({ cards }) => {
  return (
    <section>
      <Container>
        <Row>
          {cards.map((card, indx) => {
            return (
              <Col lg={4} md={6} className="mb-5" key={indx}>
                <AboutProjectCard data={card} />
              </Col>
            );
          })}
        </Row>
      </Container>
    </section>
  );
};

export default AboutProjectsSection;
