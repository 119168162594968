import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Container } from 'reactstrap';
import { Slider } from './style';
import Skeleton from 'react-loading-skeleton';

const LayoutiSlider = ({ data }) => {
  return (
    <Slider className="layouti__slider js-layouti__slider" bg={data?.img}>
      {data.head?<div className="layouti_slider_overlay">
        <div className="w-100 d-flex  align-items-center">
          <Container className="p-0 w-100">
            {data?.head && (
              <h1
                data-aos="fade-right"
                data-aos-delay="500"
                data-aos-duration="1000"
              >
                {data?.head}
              </h1>
            )}
            {data?.text && (
              <div
                data-aos="fade-right"
                data-aos-delay="700"
                data-aos-duration="1000"
              >
                {data?.text?.split('|').map((parg) => (
                  <p dangerouslySetInnerHTML={{ __html: parg }} />
                ))}
              </div>
            )}

            {data.buttonText && data.buttonLink && (
              <div className="open-page__cont">
                <NavLink
                  className="open-page"
                  to={data.buttonLink}
                  data-aos="fade-right"
                  data-aos-delay="800"
                  data-aos-duration="1000"
                >
                  {data.buttonText}
                </NavLink>
              </div>
            )}
          </Container>
        </div>
      </div>:<Skeleton style={{padding: 0, margin: 0}} width={"100%"} height={"100%"} />}
    </Slider>
  );
};

export default LayoutiSlider;
