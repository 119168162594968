import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import Footer from '../../../Components/Footer/Footer'
import HeaderOne from '../../../Components/HeaderOne/HeaderOne'
import ScrollTop from '../../../Components/ScrollTop/ScrollTop'
import SideBar from '../../../Components/SideBar/SideBar'
import Cursor from '../../../Cursor';
import Alert from '../../../Components/Alert/Alert'
import { useDispatch } from 'react-redux'
import { avatarActions } from '../../../store/UiAvatarSlice'
import axios from 'axios'
import './style.scss'
import { MetaTags } from 'react-meta-tags'
import LoadingPage from '../../../Components/LoadingPage/LoadingPage'
const Index = () => {
    const dispatch = useDispatch()
    const [data, setData] = useState(null)
    const [loadactive, setLoadActive] = useState(false);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API}contents`)
        .then((res) => {
            dispatch(avatarActions.setData(res.data.data))
        })
        
    }, [dispatch])
    useEffect(()=>{
        window.scrollTo(0,0)
        axios.get(`${process.env.REACT_APP_API}frontend/avatars/navbar`).then((res) => {
            setData(res.data.data);
        });
    },[])
    return (
        <div className='uiavatar_index'>
            <Cursor />
            <HeaderOne data={data}/>
            <MetaTags>
                <link rel='icon' href={data?.favIcon} />
            </MetaTags>
            <Alert />
            {loadactive&&<LoadingPage />}
            <SideBar /> 
            <Outlet context={[loadactive, setLoadActive]}/>
            <ScrollTop />
            <Footer />
        </div>
    )
}

export default Index