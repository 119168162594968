import React, { useState } from 'react'
// import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import Footer from '../../../Components/Footer/Footer'
import ScrollTop from '../../../Components/ScrollTop/ScrollTop'
import SideBar from '../../../Components/SideBar/SideBar'
import Cursor from '../../../Cursor';
import LayoutiHeader from '../../../Components/LayoutiComponents/Global/LayoutiHeader/LayoutiHeader'
import { useEffect } from 'react'
import ProductsLayouti from '../../../Components/ProductsLayouti/ProductsLayouti'
import axios from 'axios'
import { MetaTags } from 'react-meta-tags'
import LoadingPage from '../../../Components/LoadingPage/LoadingPage'
const LayoutiIndex = () => {
    const [activeProd, setActiveProd] = useState(false)
    const [data, setData] = useState(null)
    const [navData, setNavData] = useState(null)
    const [loadactive, setLoadActive] = useState(false);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API}frontend/ourProducts`)
        .then(res => {
            setData(res.data.data);
        })
        axios.get(`${process.env.REACT_APP_API}frontend/navbar`).then((res) => {
            setNavData(res.data.data);
            window.scrollTo(0,0)
        });
    }, [])
    
    return (
        <>
            <LayoutiHeader setActiveProd={setActiveProd} data={navData} activeProd={activeProd}/>
            <SideBar />
            <MetaTags>
                <link rel='icon' href={navData?.favIcon} />
            </MetaTags>
            {loadactive&&<LoadingPage />}
            {activeProd&&<ProductsLayouti setActiveProd={setActiveProd} data={data}/>}
            <Cursor />
            <Outlet context={[loadactive, setLoadActive]}/>
            <ScrollTop />
            <Footer />
        </>
    )
}

export default LayoutiIndex