import React from 'react'
// import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { useEffect, useState } from 'react'
import SideBar from '../../Components/SideBar/SideBar'
import Cursor from '../../Cursor'
import ScrollTop from '../../Components/ScrollTop/ScrollTop'
import Footer from '../../Components/Footer/Footer'
import HeaderTerms from '../../Components/HeaderTerms/HeaderTerms'
import ProductsLayouti from '../../Components/ProductsLayouti/ProductsLayouti'
import axios from 'axios'
const TermsIndex = () => {
  const [activeProd, setActiveProd] = useState(false)
  const [data, setData] = useState(null)
  useEffect(() => {
      window.scrollTo(0,0)
      axios.get('https://laravel.layouti.com/api/frontend/ourProducts')
      .then(res => {
          setData(res.data.data);
      })
  }, [])
  return (
    <>
        <HeaderTerms setActiveProd={setActiveProd} activeProd={activeProd}/>
        <SideBar />
          {activeProd&&<ProductsLayouti data={data} setActiveProd={setActiveProd}/>}
        <Cursor />
        <Outlet />
        <ScrollTop />
        <Footer />
    </>
  )
}

export default TermsIndex