import React from 'react'
import './style.scss'
import { Col, Row } from 'reactstrap'


const ProductsLayouti = ({data, setActiveProd}) => {
  return (
    <>
    <div className='overlay' onClick={() => setActiveProd(false)}></div>

    <div className='products_layouti' >
        <div className='products_layouti_body'>
            <Row>
                <Col className='products_layouti_head' >
                    <p >🔥  {data?.OurProductsStatic?.description} 🔥</p>
                </Col>
            </Row>
            <Row className='products_layouti_cont'>
                <Col className='products_layouti_cards'>
                    <div className='products_layouti_cards_text'>
                        <p>
                            {data?.OurProductsStatic?.leftDescription}
                        </p>
                        <a href={data?.OurProductsStatic.leftLink}>View all Products</a>
                    </div>
                </Col>
                {data?.OurProducts?.map((card, i) => <Col className='products_layouti_cards' key={i}>
                    <Row>
                        <Col xs="3">
                            <img src={card.image} alt='' />
                        </Col>
                        <Col xs="9" className='products_layouti_cards_head'>
                            <h2>{card.title}</h2>
                            <p>{card.description}</p>
                        </Col>
                    </Row>
                    <Row className='justify-content-center'>
                        <a href={card.link} className='link_btn' style={{backgroundColor: card.color}}>Learn More</a>
                    </Row>
                </Col> )}
                
                
                <Col className='products_layouti_cards'>
                    <p>
                        {data?.OurProductsStatic?.rightDescription}
                    </p>
                    <a href={data?.OurProductsStatic.rightLink}>View all Products</a>
                </Col>
            </Row>
        </div>
    </div>
    </>
  )
}

export default ProductsLayouti