import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import Card from './Card';
import './style.scss';

const LayoutiProccessDesgin = ({ sectionData }) => {
  return (
    <div className="layouti_proccess_desgin js-layouti_proccess_desgin">
      <Container className="layouti_proccess_desgin_cont">
        <Row className="layouti_proccess_desgin_head">
          {sectionData?.title && (
            <Col md={6} xs={12}>
              <h1 className="fw-bold mb-3 layouti_proccess_desgin_head_h1">
                {sectionData?.title}
              </h1>
            </Col>
          )}
          {sectionData?.description && (
            <Col className="parag_cont_home" md={6} xs={12}>
              {sectionData?.description?.split('|').map((parg) => (
                <p dangerouslySetInnerHTML={{ __html: parg }} />
              ))}
            </Col>
          )}
        </Row>
        {sectionData?.cards.length != 0 && (
          <Row className="mt-md-5">
            <Col md={3} sm={12}>
              <Card cardData={sectionData?.cards[0]} />
            </Col>
            <Col md={3} sm={12}>
              <Card cardData={sectionData?.cards[1]} />
            </Col>
            <Col md={3} sm={12}>
              <Card cardData={sectionData?.cards[2]} />
            </Col>
            <Col md={3} sm={12}>
              <Card cardData={sectionData?.cards[3]} />
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};

export default LayoutiProccessDesgin;
