import React from 'react'
import './styles.scss'

const Card = ({cardsData,cardIndex}) => {
    return (
        <div className="scope_card">
            <img src={cardsData?.icon}   alt=""/>
            <h2>
                {cardIndex}. {cardsData?.title}
            </h2>
            <p>{cardsData?.description} </p>
        </div>
    )
}

export default Card