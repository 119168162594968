import styled  from "styled-components";
import Color from "color";

export const WorkDetailsCard = styled.div`
    color: ${props => Color(props.bg).isLight()?"#000":"#fff"};
    padding: 25px 0;
    background-color: ${props => props.bg};
    .indepth {
        border-top: 1px solid ${props => Color(props.bg).isLight()?"#000":"#fff"};
    }
    @media (max-width: 600px) {
        padding:25px 20px;
    }
`
