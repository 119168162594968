import styled from 'styled-components';

export const Learned = styled.section`
  background: #ffffff;
  padding: 140px 0;
  h2 {
    font-size: 50px;
    line-height: 60px;
    margin-bottom: 0;
    font-weight: 700;
    padding-right: 20px;
    padding-right: 60px;
  }
  p {
    color: #23262b;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    padding-right: 30px;
  }
  @media only screen and (max-width: 600px) {
    h2 {
      font-size: 35px;
      padding-right: 0;
    }
    .head_p {
      display: none;
    }
  }
  transition: all 0.25s linear;
  .container {
    // transform:translateY(100px)
  }
  > * {
    transition: all 0.25s linear;
    // opacity: 0;
  }

  @media (max-width: 600px) {
    padding: 80px 8px;
    h2 {
      font-size: 35px;
      line-height: 45px;
      margin-bottom: 0;
      font-weight: 700;
    }
    .things_head {
      display: none;
    }
    p {
      font-size: 20px;
      font-weight: 400;
      line-height: 25px;
      padding-right: 0;
    }
  }
`;
