import styled from 'styled-components';

export const CardSection = styled.div`
  h3 {
    font-size: 30px;
    font-weight: 700;
  }
  .desc {
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    width: 55%;
  }
  @media (max-width: 600px) {
    padding: 20px;
    h3 {
      font-size: 36px;
      line-heigt: 45px;
      font-weight: 700;
    }
    .desc {
      width: 100%;
      font-size: 18px;
      line-height: 26px;
    }
  }
  .card {
    padding: 0;
    border: 0;
    margin-bottom: 100px;
    overflow: hidden;
    transition: all 0.25s ease-in-out;
    &:hover {
      transform: translateY(-20px);
    }
    .card-body {
      .card-title {
        font-size: 20px;
        font-weight: 700;
        line-height: 30px;
      }
    }
    @media (max-width: 600px) {
      padding-right: 20px;
      margin-bottom: 20px;
    }
  }
  .caed-img-cont {
    width: 100%;
    min-height: 376px;
    overflow: hidden;
  }
`;
