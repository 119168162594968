import React from 'react'

const Card = ({cardData,cardIndex}) => {
    return (
        <div className="scope_card">
           {cardData?.icon&& <img src={cardData?.icon} width="72" height="72" alt=''/>}
            {
                cardData?.title&&
                <h3>
                    {cardIndex+1}. {cardData?.title}
                </h3>
            }
            {
                cardData?.description&&
                    <p>
                    {cardData?.description}
                    </p>
            }
        </div>
    )
}

export default Card