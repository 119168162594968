import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import './headerone.scss';
import { useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';

const HeaderOne = ({data}) => {
  const [active, setActive] = useState({ ui: '', content: '' });
  const linkType = useSelector((state) => state.linkactive.type);
  function sideToggle() {
    document.querySelector('.sidebar').classList.toggle('active');
  }
  useEffect(() => {
    let offset = 0;
    let scrollFunction = (e) => {
      if (window.scrollY > offset) {
        document.querySelector('.header_one').classList.add('active');
      } else {
        document.querySelector('.header_one').classList.remove('active');
      }
      if (window.scrollY > 200) {
        document.querySelector('.scroll_top').style.display = 'flex';
      } else {
        document.querySelector('.scroll_top').style.display = 'none';
      }
      offset = window.scrollY;
    };
    window.addEventListener('scroll', scrollFunction);
    return () => {
      window.removeEventListener('scroll', scrollFunction);
    };
  }, []);
  useEffect(() => {
    switch (linkType) {
      case 'ui':
        setActive({ ui: 'active', content: '' });
        break;
      case 'content':
        setActive({ ui: '', content: 'active' });
        break;
      default:
        break;
    }
  }, [linkType]);
  return (
    <div className={`header_one`}>
      <Container className=" h-100">
        <Row className="align-items-center justify-content-between h-100">
        <Col xs="2" className="p-0 d-flex align-items-center gap-4">
            <NavLink to="/uiavatar">
              {data?<img
                src={data?.lightImage}
                alt=""
              />:<Skeleton circle width={60} height={60}/>}
            </NavLink>
            <h1 className='fs-5 m-0'>{data?.titleEn||<Skeleton height={24} width={95}/>}</h1>
          </Col>
          <Col xs={8} className='d-none d-md-block'>
            <Row className="navs align-items-center justify-content-between h-100 fs-6 ">
              <ul className="navbar_one">
                <li className="nav_item">
                  <Link className={`nav_item_link ${active.ui}`} to="/uiavatar">
                    UI Avatar
                  </Link>
                </li>
                <li className="nav_item">
                  <Link
                    className={`nav_item_link ${active.content}`}
                    to="/uiavatar/contentgenerator"
                  >
                    Content Generator
                  </Link>
                </li>
                <li>
                  <Link className="nav_item_link" to="/365design">
                    365 Design
                  </Link>
                </li>
                <li className="nav_item">
                  <Link className={`nav_item_link`} to="/365design/products">
                    Products
                  </Link>
                </li>
                <li className="nav_item">
                  <Link className="nav_item_link" to="/365design/links">
                    Links
                  </Link>
                </li>
                <li className="nav_item nav_item_btn ">
                  <Link
                    className={`nav_item_link`}
                    to="/contact-us?scroll=true"
                  >
                    Reach out
                  </Link>
                </li>
                <li className="nav_item nav_item_btn ">
                  <button className="toggle-btn" onClick={sideToggle}>
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 26 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M3 6V7.66667H23V6H3ZM3 12.6667V14.3333H23V12.6667H3ZM3 19.3333V21H23V19.3333H3Z"
                        fill="black"
                      />
                    </svg>
                  </button>
                </li>
              </ul>
            </Row>
          </Col>
          <Col className="toggle_mob p-0">
            <button className="toggle-btn" onClick={sideToggle}>
              <svg
                width="30"
                height="30"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M3 6V7.66667H23V6H3ZM3 12.6667V14.3333H23V12.6667H3ZM3 19.3333V21H23V19.3333H3Z"
                  fill="black"
                />
              </svg>
            </button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HeaderOne;
