import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import ProductDetails from '../../../Components/ProductDetails/ProductDetails';
import InfoCard from '../../../Components/InfoCard/InfoCard';
import './details.scss';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Card from '../../../Components/365HomeCard/Card';
import Masonry from 'react-masonry-css';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Slider365 from '../../../Components/Slider365/Slider365';
import { DesignActions } from '../../../store/Design365';
import axios from 'axios';
import useWidth from '../../../Hooks/useWidth';
import Skeleton from 'react-loading-skeleton';

const Details = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const designs = useSelector((state) => state.design.data);
  const realStore = useSelector((state) => state.design.real);
  const catStore = useSelector((state) => state.design.cat);
  const allData = useSelector((state) => state.design.allData);
  const [next, setNext] = useState(null);
  const [prev, setPrev] = useState(null);
  const [data, setData] = useState(null);
  const [real, setReal] = useState([]);
  const size = useWidth();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    axios
      .get(
        `${
          process.env.REACT_APP_API
        }frontend/365Dsign/projects/find?search=${params.id
          .split('_')
          .join(' ')}`,
      )
      .then((res) => {
        setData(res.data.data.projcts);
        setReal(res.data.data.related_projcts);
        setNext(res.data.data.next);
        setPrev(res.data.data.prev);
        window.scrollTo(0, 0);
      });
  }, [params]);

  function increaseClicks(id) {
    axios
      .post(`${process.env.REACT_APP_API}frontend/365Dsign/click`, { id })
      .then((res) => {
        // console.log("**click** increase one");
      });
  }

  function increaseDownloads(id) {
    axios
      .post(`${process.env.REACT_APP_API}frontend/365Dsign/download`, { id })
      .then((res) => {
        // console.log("**download** increase one");
      });
  }
  return (
    <div className="">
      {data && data.coverImage.length === 1 && (
        <div className="details__header">
          <img src={data ? data.coverImage[0].image : null} alt="" />
        </div>
      )}
      {!data&&<div className="p-0 m-0 details__header">
          <Skeleton width={"100%"} height={"100%"}/>
        </div>
      }
      {data && data.coverImage.length !== 1 ? (
        <Slider365 data={data.coverImage} />
      ) : null}

      {size < 600 ? (
        data ? (
          <div
            className="mobile-card"
            data-aos="fade-left"
            data-aos-once="true"
          >
            <div className="info_card_head">
              <p className="info_card_head_date">
                <i className="fas fa-calendar-alt"></i> <span>{data.date}</span>
              </p>
              <h2 className="info_card_head_title">{data.name}</h2>
              <p className="info_card_head_cearor">{data.createdBy}</p>
            </div>
            <div className="info_card_av">
              <p>{data.availabilityPrograms}</p>
            </div>
            <div className="info_card_desc">
              <p>{data.smallDescription}</p>
            </div>
            <div
              className="info_card_btns"
              data-aos="fade-up"
              data-aos-once="true"
            >
              <Link
                to="/contact-us?scroll=true"
                onClick={() => increaseClicks(data?.id)}
                className="get_in_touch"
              >
                Get in touch
              </Link>
              {data.link ? (
                <Link
                  to="/contact-us?scroll=true&open=hire-us"
                  className="get_in_touch"
                >
                  Make Your Order Now
                </Link>
              ) : (
                <a className="download mb-3" target="blank">
                  Make Your Order Now
                </a>
              )}

              {data.state === 'free' ? (
                <>
                  {data.link ? (
                    <a
                      className="get_in_touch"
                      href={data.link}
                      onClick={() => increaseDownloads(data?.id)}
                      target="blank"
                    >
                      Download
                    </a>
                  ) : (
                    <a className="download" target="blank">
                      Download
                    </a>
                  )}
                </>
              ) : (
                <a href={data.link} target="blank" className="get_in_touch">
                  <span className="get_in_touch">${data.price}</span>
                </a>
              )}
            </div>
          </div>
        ) : null
      ) : null}

      <Container className="p-0">
        <Row className="m-0">
          <Col md="7" className="p-0">
            <ProductDetails data={data} />
          </Col>
          {size > 600 ? (
            <Col md="5" className="p-0">
              <InfoCard data={data} />
            </Col>
          ) : (
            ''
          )}
        </Row>
      </Container>

      <div className="arrow_ctrl">
        <Container className="p-0">
          <Row className="m-0">
            {prev && (
              <Col className="p-0">
                <div className="arrow_content">
                  <span
                    className=""
                    onClick={() =>
                      navigate(
                        `/365design/details/${prev.split(' ').join('_')}`,
                      )
                    }
                  >
                    <i className="fas fa-arrow-left"></i>
                  </span>
                  <div className="arrow_content_info">
                    <p>Previous</p>
                    <p>{size < 600 ? '' : prev}</p>
                  </div>
                </div>
              </Col>
            )}

            {next && (
              <Col className="p-0">
                <div className="arrow_content right">
                  <div className="arrow_content_info">
                    <p>Next</p>
                    <p>{size < 600 ? '' : next}</p>
                  </div>
                  <span
                    className=""
                    onClick={() =>
                      navigate(
                        `/365design/details/${next.split(' ').join('_')}`,
                      )
                    }
                  >
                    <i className="fas fa-arrow-right"></i>
                  </span>
                </div>
              </Col>
            )}
          </Row>
        </Container>
      </div>

      {real.length !== 0 && (
        <div className="similar_apps">
          <Container className="p-0">
            <div className="similar_apps_title d-flex justify-content-between align-items-center">
              <h3 className="">Latest inspiration for {data.category.title}</h3>
              <Link to={`/365design/category/${data.category.title.split(" ").join("_")}`}>View more</Link>
            </div>
            <Row className="mt-3 mt-md-5">
              {real &&
                real.map((d) => (
                  <Col md="4" key={d.id} className="mb-3">
                    <Card data={d} />
                  </Col>
                ))}
            </Row>
          </Container>
        </div>
      )}
    </div>
  );
};

export default Details;
