import React from "react";
import "./PriceCardsLearnui.scss";

const PriceCardsLearnuiUIUX = ({ data, setValuePackage }) => {
  let points1 = data?.UXUIDesignPackage?.points?.slice(0, 11);
  let points2 = data?.UXUIDesignPackage?.points?.slice(11, 18);

  function buttonActinRegister(e) {
    e.preventDefault();
    window.scrollTo({ top: 0 });
    setValuePackage(
      `UX/UX Design ($${data?.UXUIDesignPackage?.price}) ${data?.UXUIDesignPackage?.hours}H`
    );
  }

  return (
    <>
      <div className="cards">
        <div className="header-cards">
          <div className="icon-star">
            {/* <i className="fas fa-star-half-alt"></i> */}
            {data?.UXUIDesignPackage?.image ? (
              <img
                className="img-iconLearn"
                src={data?.UXUIDesignPackage?.image}
                alt="icon uxui"
              />
            ) : null}
          </div>
          {/* <h2>UI Design</h2> */}
          <h2>{data?.UXUIDesignPackage?.title}</h2>
          <div className="price">
            {/* <span className='dolar'>$300</span> <span className='hours'>/ 20H</span> */}
            <span className="dolar">${data?.UXUIDesignPackage?.price}</span>{" "}
            <span className="hours">/ {data?.UXUIDesignPackage?.hours}H</span>
          </div>
        </div>
        <ul className="lists">
          {points1?.map((point, index) => (
            <>
              <li key={index}>
                <span className="icon-check">
                  <i className="fas fa-check me-3"></i>
                </span>
                <span className="prag">{point.point}</span>
              </li>
            </>
          ))}

          <hr className="line-list" />

          <li className="listBorder">{data?.UXUIDesignPackage?.description}</li>

          <hr className="line-list" />

          {points2?.map((point, index) => (
            <>
              <li key={index}>
                <span className="icon-check">
                  <i className="fas fa-check me-3"></i>
                </span>
                <span className="prag">{point.point}</span>
              </li>
            </>
          ))}

          <hr className="line-list" />

          <button
            onClick={(e) => buttonActinRegister(e)}
            className="register-button"
          >
            Register Now for ${data?.UXUIDesignPackage?.price}
          </button>
        </ul>
        {/* <ul className='lists'>
                <li>
                    <span className='icon-check'>
                        <i className="fas fa-check me-3"></i>
                    </span>
                    <span className='prag'>Introduction</span>
                </li>
                <li>
                    <span className='icon-check'>
                        <i className="fas fa-check me-3"></i>
                    </span>
                    <span className='prag'>UI vs. UX</span>
                </li>
                <li className='remove'>
                    <span className='icon-check'>
                        <i className="fas fa-check me-3"></i>
                    </span>
                    <span className='prag'>persons</span>
                </li>
                <li className='remove'>
                    <span className='icon-check'>
                        <i className="fas fa-check me-3"></i>
                    </span>
                    <span className='prag'>Information Architecture</span>
                </li>
                <li className='remove'>
                    <span className='icon-check'>
                        <i className="fas fa-check me-3"></i>
                    </span>
                    <span className='prag'>User Flows</span>
                </li>
                <li className='remove'>
                    <span className='icon-check'>
                        <i className="fas fa-check me-3"></i>
                    </span>
                    <span className='prag'>Wireframe</span>
                </li>
                <li>
                    <span className='icon-check'>
                        <i className="fas fa-check me-3"></i>
                    </span>
                    <span className='prag'>UI design principles</span>
                </li>
                <li>
                    <span className='icon-check'>
                        <i className="fas fa-check me-3"></i>
                    </span>
                    <span className='prag'>Content/Elements</span>
                </li>
                <li>
                    <span className='icon-check'>
                        <i className="fas fa-check me-3"></i>
                    </span>
                    <span className='prag'>Functional design</span>
                </li>
                <li>
                    <span className='icon-check'>
                        <i className="fas fa-check me-3"></i>
                    </span>
                    <span className='prag'>Helping developers</span>
                </li>
                <li>
                    <span className='icon-check'>
                        <i className="fas fa-check me-3"></i>
                    </span>
                    <span className='prag'>Designed for your success</span>
                </li>
                <hr className='line-list' />
                <li className='listBorder'>
                    All the following materials are not available from this package
                </li>
                <li className='remove'>
                    <span className='icon-check'>
                        <i className="fas fa-check me-3"></i>
                    </span>
                    <span className='prag'>Free Design system kit</span>
                </li>
                <li className='remove'>
                    <span className='icon-check'>
                        <i className="fas fa-check me-3"></i>
                    </span>
                    <span className='prag'>Free Icons kit</span>
                </li>
                <li className='remove'>
                    <span className='icon-check'>
                        <i className="fas fa-check me-3"></i>
                    </span>
                    <span className='prag'>Free Eng & Arb font kit</span>
                </li>
                <li className='remove'>
                    <span className='icon-check'>
                        <i className="fas fa-check me-3"></i>
                    </span>
                    <span className='prag'>Free Color palette kit</span>
                </li>
                <li className='remove'>
                    <span className='icon-check'>
                        <i className="fas fa-check me-3"></i>
                    </span>
                    <span className='prag'>Free Persona kit</span>
                </li>
                <li className='remove'>
                    <span className='icon-check'>
                        <i className="fas fa-check me-3"></i>
                    </span>
                    <span className='prag'>Free Wireframe kit</span>
                </li>
                <li className='remove'>
                    <span className='icon-check'>
                        <i className="fas fa-check me-3"></i>
                    </span>
                    <span className='prag'>Internship with Layouti team</span>
                </li>
                <hr className='line-list' />

                <button className='register-button'>Register Now for $300</button>

            </ul> */}
      </div>
    </>
  );
};

export default PriceCardsLearnuiUIUX;
