import React from 'react'
import { Col, Row } from 'reactstrap'
import './CV2022Experience.scss'
function CV2022Experience({job}) {
    return (
        <Row className='cv-category'>
            <Col lg='3' className='cv-categoreis__year-cont'>
                {job.job_year &&<p className='cv-categoreis__year'>{job.job_year}</p>}
            </Col>
            <Col lg='9' className='cv-categories__content'>
                {job.job_title &&<h3 className='cv-categories__job-title'>{job.job_title}</h3>}
                {job.job_place &&<h4 className='cv-categories__job-place'>{job.job_place}</h4>}
                {
                    job.job_category &&job.job_category.map(cat=>(
                <div className='cv-categories__job-responsiblity-cont' key={cat.id+cat.title}>
                    {cat.title&&<h4 className='cv-categories__job-responsiblity-title'>{cat.title}:</h4>}
                    {cat.desc&&<p className='cv-categories__job-responsiblity-desc'>{cat.desc}</p>}
                    <ul className='cv-categories__job-responsiblity-list'>
                        {
                            cat.items && cat.items.map(item=>(
                                item.name &&
                                <li className='cv-categories__job-responsiblity-item' key={item.id +item.name}>
                                    {item.name}
                                </li>
                            ))
                        }
                        
                    </ul>
                </div>
                ))
            }
            </Col>
        </Row>
    )
}

export default CV2022Experience