import styled from "styled-components";
import { imgBgStyle } from "../../Utilities";

export const ProcessB = styled.div
`
 position:relative;
 min-height:100vh;
 background-image:url('https://res.cloudinary.com/elgaml/image/upload/v1668935664/Mask-Group-2_py3yxu.png');
 margin-top: 100px;
 color:#FFF;
 @media(max-width:767px){
    max-height: fit-content;
     margin-top: 20px;
     padding: 60px 20px; 
 }
 ${imgBgStyle}
 .row-top{
    position: relative;
    top: -240px;
    @media(max-width:767px){
        top:initial
    }
    .image-top{
        // width: 465px;
        // width: 700px;
        max-height:380px;
        min-height:380px;
        overflow:hidden;
        img{
            transition: all .25s ease-in-out;
            min-height: inherit;
        }
        img:hover{
            transform: scale(1.2);
        }
        @media(max-width:1024px){
            width: 100%;
        }
        @media(max-width:767px){
            width:100%;
        }
    }
    p{
        margin-top:100px;
        width:64%;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        color: rgb(255, 255, 255);
        @media(max-width:767px){
            width:100%;
            margin-top:0;
            font-size: 18px;
            line-height: 26px;
            padding: 0;
        }
     }
 }
.image-down{
    
    img{
        transition: all .25s ease-in-out;
        min-height: inherit
    }
    img:hover{
        transform: scale(1.2);
    }
}
.image-down-cont{
    width: 100%;
    max-height: 320px;
    min-height: 320px;
    overflow: hidden;
}

`