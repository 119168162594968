/* eslint-disable no-unused-vars */
import styled, { keyframes } from 'styled-components';
export const SkillsCards = styled.div`
  flex-basis: calc(100% / 3);
  transition: all 0.5s ease;
  padding: 100px 0;
  padding-left: 50px;
  position: relative;
  overflow: hidden;
  @media only screen and (max-width: 600px) {
    width: 100%;
    margin-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .skills__back {
    // background-image: url('https://layouti.com/wp-content/uploads/2021/04/2s.png') !important;
    background-image: linear-gradient(
        to right,
        rgb(0, 0, 0, 0.8),
        rgba(0, 0, 0, 0.8)
      ),
      url('${(props) => (props.bg ? props.bg : '')}') !important;
    background: no-repeat center;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: transform 2s ease;
    // transition: opacity 0.1s ease;
    z-index: -1;
    opacity: 1;
  }
  :first-of-type .skills__back,
  :last-of-type .skills__back {
    opacity: 0.9;
  }
  .skills_card_info {
    > h1 {
      font-size: 30px;
      font-weight: 600;
      margin-bottom: 10px;
      color: #fff;
      &:first-of-type {
        color: #dfe5ee;
        font-size: 45px;
        font-weight: 600;
        margin-bottom: 10px;
        @media only screen and (max-width: 600px) {
          display: none;
        }
      }
      &:last-of-type {
        margin-bottom: 50px;
        @media only screen and (max-width: 600px) {
          margin-bottom: 20px;
        }
      }
    }
    > button {
      background: transparent;
      border: 1px solid #42bf64;
      color: #42bf64;
      border-radius: 30px;
      transition: all 0.3s ease-in-out;
      width: 240px;
      height: 50px;
      @media only screen and (max-width: 600px) {
        display: none;
      }
      &:hover {
        animation: ButtonAnimation 0.3s linear 1;
      }
    }
    > p {
      font-size: 18px;
      line-height: 28px;
      color: #fff;
      margin-top: 40px;
      transition: all 0.3s ease;
      width: 400px;
      width: 100%;
      @media only screen and (max-width: 600px) {
        margin-top: 0px;
      }
    }
  }
  &:hover {
    flex-basis: 40%;
    background-color: rgba($color: #42bf64, $alpha: 0.5);
    &:nth-of-type(2) {
      background-color: rgba($color: #000, $alpha: 0.4);
    }
    &:last-of-type {
      background-color: rgba($color: #cf2e2e, $alpha: 0.4);
    }
    .skills_card_info {
      > h1 {
        color: #fff;
      }
      > button {
        border: 1px solid #fff;
        color: #fff;
      }
    }
    .skills__back {
      transform: scale(1.5);
      transform-origin: center;
    }
    :first-of-type .skills__back,
    :last-of-type .skills__back {
      opacity: 0.9;
    }
    @media only screen and (max-width: 600px) {
      flex-basis: 100%;
      color: #fff;

      .skills__back {
        opacity: 1;
      }
      .skills_card_info {
        > h1 {
          font-size: 45px;
          font-weight: 600;
          margin-bottom: 10px;
          &:first-of-type {
            color: #dfe5ee;
            font-size: 45px;
            font-weight: 600;
            margin-bottom: 10px;
          }
          &:last-of-type {
            margin-bottom: 30px;
          }
        }
        > button {
          background: transparent;
          border: 1px solid #42bf64;
          color: #42bf64;
          width: 240px;
          height: 50px;
          border-radius: 30px;
          display: none;
          transition: all 0.3s ease-in-out;
          &:hover {
            animation: ButtonAnimation 0.3s linear 1;
          }
        }
      }
      &:hover {
        flex-basis: 100%;
      }
    }
  }
`;
