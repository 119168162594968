import React, {useEffect, useState} from 'react'
import ContentCard from '../../../Components/ContentCards/ContentCard'
import Header from '../../../Components/ContentCards/Pages/Header'
import { useDispatch } from 'react-redux'
import {LinkActive} from '../../../store/linkactive'
import {useSelector } from 'react-redux'
import ScrollToTop from '../../../Components/ScrollToTop/ScrollToTop'
import axios from 'axios'
import { useOutletContext } from 'react-router-dom'

const ContentGenerator = () => {
    const dispatch = useDispatch()
    let ui = useSelector(state => state.ui.ui)
    const [headline, setHeadline] = useState([])
    const [headlineTotal, setHeadlineTotal] = useState([])
    const [paragraphs, setParagraphs] = useState([])
    const [paragraphsTotal, setParagraphsTotal] = useState([])
    const [data, setData] = useState([])
    const [pageOne, setPageOne] = useState(1)
    const [pageTwo, setPageTwo] = useState(1)
    const [loading, setLoading] = useState(false)
    const [loadactive, setLoadActive] = useOutletContext()

    const getHeadlines = () => {
        pageOne > 1&& setLoading(true)
        pageOne == 1&& setLoadActive(true)
        axios.get(`${process.env.REACT_APP_API}frontend/content?page=${pageOne}`)
        .then((res) => {
            setHeadline(all => all.concat(res.data.data.Content))
            setHeadlineTotal(res.data.data.pagination.total)
            setPageOne(page => page+1)
            setLoading(false)
            setTimeout(() => {
                pageOne == 1&& setLoadActive(false)
              }, 2000)
            pageOne == 1&& window.scrollTo(0,0)
        })
    }

    const getParagraphs = () => {
        pageTwo > 1&& setLoading(true)
        axios.get(`${process.env.REACT_APP_API}frontend/content?status=paragrah&page=${pageTwo}`)
        .then((res) => {
            setParagraphs(all => all.concat(res.data.data.Content))
            setParagraphsTotal(res.data.data.pagination.total)
            setPageTwo(page => page+1)
            setLoading(false)
        })
    }


    useEffect(() => {
        dispatch(LinkActive.setType('content'))
        getHeadlines()
        getParagraphs()

        axios.get(`${process.env.REACT_APP_API}frontend/contentGenerator`)
        .then((res) => {
            setData(res.data.data)
        })

        if(ui) {
            setHeadline(ui.headlines)
            setParagraphs(ui.paragraphs)
        }
    }, [dispatch, ui])
    useEffect(()=>{
        window.scrollTo(0,0)
      },[])

    return (
        <>
            <Header data={data}/>
            <ContentCard 
                headline={headline} 
                headlineTotal={headlineTotal} 
                paragraphs={paragraphs} 
                paragraphsTotal={paragraphsTotal} 
                getHeadlines={getHeadlines}
                getParagraphs={getParagraphs}
                loading={loading}
            />
            <ScrollToTop />
        </>
    )
}

export default ContentGenerator