import React from 'react'
import { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
// import { ChallengeCard, StepsCovers, StepsInfo } from '../Styled/Index'
import './style.scss'

const ProjectInformation = ({cardDetails}) => {

    return (
        <>
            <div className='sections_info'>
                <Container>
                    <Row className='justify-content-between'>
                        {cardDetails?cardDetails.DesignAppSections.map((info, index) => {
                            return (
                                <Col lg='5' className='p-0'>
                                    <div>
                                        <h2 className='fw-bold'>{info.title}</h2>
                                         {info.description.split('/').map((text, i) => <p key={i}>{text}</p>)}
                                    </div>
                                </Col>
                            )
                        }):null}
                    </Row>
                    
                </Container>
            </div>
        </>
    )
}

export default ProjectInformation