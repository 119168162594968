import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import UseScrollHover from '../../../Hooks/UseScrollHover'

import { NavLink } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
const SameWorks = ({cardsData}) => {
    const [showCursor , hideCursor] =UseScrollHover()
 
    const [projectsData,setProjectsData]=useState(null)
    useEffect(()=>{
        setProjectsData(cardsData)
    },[cardsData])
    return (
        <div className='projects'>
        <Container>
            <Row>
                {
                    projectsData && projectsData.map((project,index)=>(
                        project.status === 1 ? <div className={`${index<4 ? 'col-md-4' :'col-md-4'} project-col-cont`} key={index} >
                        <div className='project' >
                            <NavLink to={`/works/details/${project?.ProjectName?.name.split(" ").join("_")}`} className='w-100' onMouseEnter={showCursor} onMouseLeave={hideCursor}>
                                <div className={`${index < 4 ? 'project-img-cont--same-works' :''} project-img-cont`}>
                                    <div data-aos="new-animation" data-aos-once='true'>
                                        <img src={project?.thumbnailImage} className='img-fluid h-100'  alt="proj1"/>
                                    </div>
                                </div>
                            </NavLink>
                            <div className='works__project__name'>
                                <h6 style={{fontSize: index>=4 ? '0.9rem' :'1.2rem'}}>{project?.ProjectName?.name?.split('-')[0]}</h6>
                                <h6> <i className="fas fa-arrow-right"></i></h6>
                            </div>
                        </div>
                    </div>:<div className={`${index<4 ? 'col-md-4' :'col-md-4'} project-col-cont`} key={index} >
                        <div className='project' >
                            {/* <NavLink to={`details/${project?.ProjectName?.name.split(" ").join("_")}`} className='w-100' onMouseEnter={showCursor} onMouseLeave={hideCursor}> */}
                                <div className={`${index < 4 ? 'project-img-cont--same-works' :''} project-img-cont`}>
                                    <div data-aos="new-animation" data-aos-once='true'>
                                        <img src={project?.thumbnailImage} className='img-fluid h-100'  alt="proj1"/>
                                    </div>
                                </div>
                            {/* </NavLink> */}
                            <div className='works__project__name'>
                                <h6 style={{fontSize: index>=4 ? '0.9rem' :'1.2rem'}}>{project?.ProjectName?.name?.split('-')[0]}</h6>
                            </div>
                        </div>
                    </div>
                    ))
                }
            </Row>
        </Container>
    </div>
    )
}

export default SameWorks