import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { createBrowserHistory } from 'history';
// import { toast } from 'react-toastify';
const axiosConfig = axios.create({
    baseURL: `${process.env.REACT_APP_API}`,
    headers: {
        // 'Content-Type': 'multipart/form-data',
        'Content-Type': 'application/json',
    }
});

export default axiosConfig;