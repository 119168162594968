import React from 'react'
import './CategoryPageItem.scss'
import itemImageSrc from '../../../assets/images/Awrads_etoyapp.jpeg'
import {ReactComponent as DownloadIcon} from '../../../assets/images/download.svg'
import {ReactComponent as LinkIcon} from '../../../assets/images/link.svg'
function CategoryPageItem({data}) {
  return (
    <div className='category-item'>
        {/* <Link to='/' className='category-item__link'></Link> */}
        <div className='category-item__img-wrapper'>
            <img src={data?.image?data?.image : itemImageSrc} alt='category' className='category-item__img'/>
        </div>
        <div className='category-item__content-wrapper'>
            <h4 className='category-item__content-title'>{data?.title}</h4>
            <p className='category-item__content-description'>{data?.description}</p>
        </div>
        <div className='category-item__actions-wrapper d-flex'>
          <a href={data?.link} target="blank" className='category-item__action-button'>
            <LinkIcon className='category-item__action-icon'/>
            Link
          </a>
          <a href={data?.file} target="blank"  className='category-item__action-button'>
            <DownloadIcon className='category-item__action-icon'/>
            Download
          </a>
        </div>
    </div>
  )
}

export default CategoryPageItem