import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: null,
};

const UiLearn = createSlice({
  name: "uilearn",
  initialState,
  reducers: {
    setData(state, action) {
      state.data = action.payload.data;
    },
  },
});

export const UiLearnActions = UiLearn.actions;

export default UiLearn.reducer;
