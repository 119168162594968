import React, { useState } from 'react'
import { Container, Row, Col, Nav,
    NavItem,
    NavLink, TabContent,
    TabPane, } from 'reactstrap'
import './uicards.scss'
import classnames from 'classnames'
import Paragraphs from './Pages/Paragraphs'
import Headlines from './Pages/Headlines'
import { useDispatch } from 'react-redux'
import { avatarActions } from '../../store/UiAvatarSlice'
import {InfinitySpin } from 'react-loader-spinner'
import axios from 'axios'
import useWidth from '../../Hooks/useWidth'

const ContentCard = (props) => {
    const dispatch = useDispatch()
    const [verticalActiveTab, setverticalActiveTab] = useState("1");
    const size = useWidth()
    const toggleVertical = tab => {
        if (verticalActiveTab !== tab) {
            setverticalActiveTab(tab);
        }
    };

    function getMoreData(id) {
        if(id == 1) { 
            props.getHeadlines()
        }else {
            props.getParagraphs()
        }
    }

    return (
        <Container>
            <Row >
            {size > 600?<Col md="12" className='  p-0'>
                    <Nav pills className="flex-row tabs_cont  gap-3 flex-nowrap justify-content-center navs_aside">
                    <NavItem  className="nav_tap_to">
                        <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                            "": true,
                            active: verticalActiveTab === "1",
                            "tab_link":true
                        })}
                        onClick={() => {
                            toggleVertical("1");
                        }}
                        >
                            Headlines ({props.headline?props.headlineTotal: 0})
                        </NavLink>
                    </NavItem>

                    <NavItem  className="nav_tap_to">
                        <NavLink
                        style={{ cursor: "pointer", borderRadius: 0}}
                        className={classnames({
                            "": true,
                            active: verticalActiveTab === "2",
                            "tab_link":true
                        })}
                        onClick={() => {
                            toggleVertical("2");
                        }}
                        >
                            Paragraphs ({props.paragraphs?props.paragraphsTotal: 0})
                        </NavLink>
                    </NavItem>
                    </Nav>
                </Col>:<div className='menu_mobile'>
                        <select value={verticalActiveTab} onChange={(e) => toggleVertical(e.target.value)}>
                            <option value={"1"}>  Headlines ({props.headline?props.headlineTotal: 0})</option>
                            <option value={"2"}> Paragraphs ({props.paragraphs?props.paragraphsTotal: 0})</option>
                        </select>
                    </div>}
                <Col md="12  p-0 mt-4">
                    <TabContent
                    activeTab={verticalActiveTab}
                    className="text-muted table__content mt-1 mt-md-0"
                    >
                        <TabPane tabId="1">
                            <Headlines data={props.headline}/>
                        </TabPane>
                        <TabPane tabId="2">
                            <Paragraphs data={props.paragraphs}/>
                        </TabPane>
                        </TabContent>
                </Col>
            </Row>
            {props.loading?<div className='spin'><InfinitySpin color="#3aaa35" width='150' height="45"/></div>:
                <button className='load_more' onClick={() => getMoreData(verticalActiveTab)}>
                    Load More
                </button>
            }
        </Container>
    )
}

export default ContentCard