import React from 'react'
import './style.scss'
import { TailSpin } from 'react-loader-spinner'


const LoadingPage = () => {
  return (
    <div className='loading_page'>
        <div className={'inner_loading_page'}>
            <TailSpin
                height="80"
                width="80"
                color="#3aaa35"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
            />
        </div>
    </div>
  )
}

export default LoadingPage