import React, { useCallback, useEffect, useRef } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar } from "swiper";
import {BsArrowRight, BsArrowLeft} from 'react-icons/bs'
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import Card from './Card1';
import { FlexDiv, RoundedArrowButton } from '../../../ProductDetailsComponents/Styled/Index';
import { ProcessCards } from './style.js';


const ProcessTimeline = ({sectionData}) => {

    const sliderRef = useRef(null);

    const handlePrev = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slidePrev();
        if(sliderRef.current.swiper.isBeginning) {
            document.querySelector('.prev-arrow').classList.add("not_active")
        }
        document.querySelector('.next-arrow').classList.remove("not_active")

    }, []);

    const handleNext = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slideNext();
        if(sliderRef.current.swiper.isEnd) {
            document.querySelector('.next-arrow').classList.add("not_active")
        }
        document.querySelector('.prev-arrow').classList.remove("not_active")
    }, []);

    useEffect(() => {
        if(sliderRef.current.swiper.isBeginning) {
            document.querySelector('.prev-arrow').classList.add("not_active")
        }
        if(sliderRef.current.swiper.isEnd) {
            document.querySelector('.next-arrow').classList.add("not_active")
        }
    }, [])

    return (
        <ProcessCards className='scope__of__work' data-aos='fade-up' data-aos-once='true'>
            <Container className="p-0">
                <Row className='align-items-center' >
                    {
                        sectionData?.title&&
                        <Col>
                            <h2 className='m-0'>{sectionData?.title}</h2>
                        </Col>
                    } 
                    <Col className='process_nav_btns'>
                        <FlexDiv>
                            <RoundedArrowButton className={`prev-arrow`} onClick={handlePrev}>
                                <BsArrowLeft/>
                            </RoundedArrowButton> 
                            <RoundedArrowButton className={`next-arrow`}  onClick={handleNext}>
                                <BsArrowRight/>
                            </RoundedArrowButton> 
                        </FlexDiv>
                    </Col>
                </Row>
                {/* {
                    sectionData&&
                    <Row>
                        <Col md={7}><p className='padding-responsive'>{sectionData.description?sectionData.description:""}</p></Col>
                    </Row>
                } */}
                
            </Container>

            <div className='container-md mt-4'>
                <Row className="g-5">
                    <Col className= "card_col mt-3">
                        <Swiper
                        scrollbar={{
                            hide: false,
                        }}
                        modules={[Scrollbar]}
                        ref={sliderRef}
                        pagination={{
                        clickable: true,
                        }}
                        breakpoints={{
                            640: {
                                slidesPerView: 2,
                                spaceBetween: 10,
                            },
                            
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: -10,
                            },
                        }}      
                        className="card-swiper"
                        >
                            {
                                sectionData?.cards.length!=0&&sectionData?.cards.map((category,index)=>(
                                    <SwiperSlide key={index}>
                                        <Card cardData={category} cardIndex={index}/>
                                    </SwiperSlide>
                                ))
                            }
                        </Swiper>
                    </Col>
            </Row>
            </div>
           
        </ProcessCards>
    )
}

export default ProcessTimeline