import React from 'react'
import { Container } from 'reactstrap'
import CV2022Content from '../../../Components/CV2022/CV2022Content/CV2022Content'
import CV2022Header from '../../../Components/CV2022/CV2022Header/CV2022Header'

function CV2022() {
  return (
    <Container className='p-0'>
        <CV2022Header/>
        <CV2022Content/>
    </Container>
  )
}

export default CV2022