import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import AboutTextSection from '../AboutTextSection/AboutTextSection';
import './style.scss';
const TeamSection = ({ sectionData, teamData }) => {
  return (
    <div className="js-team-section team-section">
      <Container className="team-section-cont">
        <AboutTextSection
          title={sectionData?.title}
          content={sectionData?.description}
        />
        <Row className=" about-text--margin">
          <Col lg={8} className="d-none d-sm-flex gap-4 justify-content-center">
            <svg
              className="d-none d-lg-flex me-auto"
              width="65"
              height="110"
              viewBox="0 0 65 110"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M-1.38171e-06 78.3902L29.9661 107.635L32.5 110L35.0339 107.635L65 78.3902L59.9322 73.4445L36.0254 96.7755L36.0254 6.05467e-06L28.9746 6.36287e-06L28.9746 96.7755L5.0678 73.4445L-1.38171e-06 78.3902Z"
                fill="#DFE5EE"
              />
            </svg>
            <div className="about-img-cont--first">
              <div data-aos="new-animation" data-aos-once="true">
                <img src={sectionData?.leftImage} alt="" />
              </div>
            </div>
          </Col>
          <Col
            lg={4}
            className="d-flex d-sm-none d-lg-flex justify-content-center"
          >
            <div className="about-img-cont">
              <div data-aos="new-animation" data-aos-once="true">
                <img src={sectionData?.rightImage} alt="" />
              </div>
            </div>
          </Col>
        </Row>

        <AboutTextSection
          title={teamData?.title}
          content={teamData?.description}
        />

        <div className="team_grid">
          <Row>
            {teamData?.cards.length != 0 &&
              teamData?.cards.map((team, indx) => (
                <Col
                  xl={3}
                  lg={4}
                  md={6}
                  sm={6}
                  className=" empolyee-card "
                  key={indx}
                >
                  <div className="empolyee-img-cont">
                    {team?.image && (
                      <div data-aos="new-animation" data-aos-once="true">
                        <img src={team?.image} alt="" />
                      </div>
                    )}
                  </div>
                  <div className="title">
                    {team?.name && (
                      <h4 className="mt-3 fw-bold">{team?.name}</h4>
                    )}
                  </div>
                  <div className="title">
                    {team?.jobTitle && <p className="fs-5">{team?.jobTitle}</p>}
                  </div>
                </Col>
              ))}
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default TeamSection;
