import styled, { keyframes } from "styled-components";
import { Link } from 'react-router-dom'

export const HeadTitle = {
    fontSize: '35px !important',
    lineHeight: '45px !important',
    fontWeight:700,
}
export const HeadTitle2 = {
    fontSize: '50px !important',
    lineHeight: '45px !important',
    fontWeight:700,
}
 
export const ColorGreen = '#26e71e'

const ButtonAnimation = keyframes
`50% {
    transform: scale(0.8);
}
100% {
    transform: scale(1);
}`

export const FixedButton = styled(Link)
` 
    font-size: 15px;
    // padding: 19px 69px;
    width:240px;
    height:50px;
    display:flex;
    align-items:center;
    justify-content:center;
    border-radius: 30px;
    background:${ColorGreen};
    color: #000;
    font-weight: bold;
    border:none;
    margin-top:0px;
    text-decoration: none;
    @media only screen and (max-width: 768px)  {
        padding: 19px 49px;
    }
    @media only screen and (max-width: 576px)  {
        width: 100%;
        margin-top: 15px!important;
        padding: 16px 39px;
    }
    @media only screen and (max-width: 426px)  {
        width: 100%;
    }
    &:hover{
        color: #000000;
        animation: ${ButtonAnimation} 0.3s linear 1;
    }
`
export const MobileButton = styled.button
` 
    font-size: 15px;
    padding: 15px 50px;
    border-radius: 30px;
    background:${ColorGreen};
    color: #FFF;
    border:none;
    display: none;
    margin: 40px 20px; 
    width: 100%;
    text-transform: capitalize;
    @media only screen and (max-width: 600px)  {
        display: block;
        width: 100%;
        margin: 20px auto ;
    }
    &:hover{
        animation: ${ButtonAnimation} 0.3s linear 1;
    }
`
export const imgBgStyle = {
    backgroundSize:'cover',
    'background-position': 'center',
    'background-repeat': 'no-repeat'
}

export const transitonEffect = 'all .3s ease-in-out'