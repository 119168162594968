import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const ExitButton = styled.div`
  width: 66px;
  height: 66px;
  background-color: ${(props) => props.color} !important;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const StartButton = styled(NavLink)`
  background-color: ${props => props.color};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 30px;
  cursor: pointer;
  color: #fff !important;
  border: none;
  border-radius: 100px;
  font-weight: 600;
  text-decoration: none;
  @media (max-width: 600px) {
    display: none;
  }
`;
