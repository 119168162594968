import React from "react";
import News from "../../../Components/LayoutiComponents/Global/News";
import Posts from "../../../Components/LayoutiComponents/Blog/Posts";
import Subscribe from "../../../Components/LayoutiComponents/Blog/Subscribe";
import LayoutiSlider from "../../../Components/LayoutiComponents/Global/LayoutiSlider/LayoutiSlider";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { LayoutiActions } from "../../../store/Layouti";
import axios from "axios";
import { useOutletContext } from "react-router-dom";

function LayoutiBlog() {
  const [dataContant, setDataContent ] = useState([])
  const [loadactive, setLoadActive] = useOutletContext()
  
  const sliderContent = {
    img: "images/layouti/blog/bg.jpg",
    head: "This blog is full of creative articles with hints, tips, and advice!",
    text: "",
  };
  const dispatch = useDispatch();

  useEffect(() => {
    setLoadActive(true)
    axios.get(`${process.env.REACT_APP_API}frontend/blog`).then((res) => {
      // console.log(res.data);
      setLoadActive(false)
      setDataContent(res.data.data)
    }).catch(err =>{
      console.log(err);
    });
  }, []);

  useEffect(() => {
    dispatch(LayoutiActions.setHeader(1));
  }, [dispatch]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <LayoutiSlider data={sliderContent} />
      <Posts data={dataContant}/>
      <Subscribe />
      {/* <News title="Popular News" /> */}
    </>
  );
}

export default LayoutiBlog;
