import React from 'react';
import './TestmonialsLearnui.scss';
import { Container } from 'reactstrap';
import SwiperTestmonials from './SwiperTestmonials';


const TestmonialsLearnui = ({data}) => {
  return (
    <div className='testmonialsLearnui'>
        <Container>
            <div className='header-testmonials text-center mb-5'>
                <h2>{data?.Testimonials?.title}</h2>
                <p>{data?.Testimonials?.description}</p>
            </div>
            <SwiperTestmonials  data={data}/>
        </Container>
    </div>
  )
}

export default TestmonialsLearnui;