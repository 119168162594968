import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styles from './ContactUsGetInTouch.module.css';
function ContactUsGetInTouch({ sectionData }) {
  return (
    <div className={styles['contact-us__get-in-touch']}>
      <Container>
        <Row>
          <Col md="6">
            <div
              className={styles['contact-us__get-in-touch-cont']}
              data-aos="fade-up"
              data-aos-once="true"
            >
              {sectionData?.title && (
                <h3 className={styles['contact-us__get-in-touch-heading']}>
                  {sectionData?.title}
                </h3>
              )}
              {sectionData?.description && (
                <p className={styles['contact-us__deck-content']}>
                  {sectionData?.description}
                </p>
              )}
            </div>
          </Col>
          <Col md="6">
            <div
              className={styles['contact-us__get-in-touch-cont']}
              data-aos="fade-up"
              data-aos-once="true"
            >
              <h3 className={styles['contact-us__get-in-touch-second-heading']}>
                {sectionData?.informationTitle}
              </h3>
              {sectionData?.emailCards &&
                sectionData?.emailCards.map((item, index) => (
                  <p
                    className={styles['contact-us__get-in-touch-desc']}
                    key={index}
                  >
                    {item.title}: {item.email}
                  </p>
                ))}
              {sectionData?.mobileCards &&
                sectionData?.mobileCards.map((item, index) => (
                  <p
                    className={styles['contact-us__get-in-touch-desc']}
                    key={index}
                  >
                    {item.title}: {item.mobile}
                  </p>
                ))}
              {sectionData?.whatsAppCards &&
                sectionData?.whatsAppCards.map((item, index) => (
                  <p
                    className={styles['contact-us__get-in-touch-desc']}
                    key={index}
                  >
                    {item.title}: {item.whatsApp}
                  </p>
                ))}
            </div>
            <div
              className={styles['contact-us__get-in-touch-cont']}
              data-aos="fade-up"
              data-aos-once="true"
            >
              {sectionData?.Address && (
                <h3
                  className={styles['contact-us__get-in-touch-second-heading']}
                >
                  {sectionData?.Address}
                </h3>
              )}
              {sectionData?.countryCards &&
                sectionData?.countryCards.map((item, index) => (
                  <p
                    className={styles['contact-us__get-in-touch-desc']}
                    key={index}
                  >
                    {item?.title}
                  </p>
                ))}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ContactUsGetInTouch;
