import React from 'react'
import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import Footer from '../../../Components/Footer/Footer'
import HeaderLearn from '../../../Components/HeaderLearn/HeaderLearn'
import ScrollTop from '../../../Components/ScrollTop/ScrollTop'
import SideBar from '../../../Components/SideBar/SideBar'
import Cursor from '../../../Cursor'
import './style.scss'


const LearnUi = () => {
    useEffect(()=>{
        window.scrollTo(0,0)
      },[])
    return (
        <div className='learnui_index'>
            <Cursor />
            <HeaderLearn />
            <SideBar />
            <Outlet />
            <ScrollTop />
            <Footer />
        </div>
    )
}

export default LearnUi