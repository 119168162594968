import React, { useEffect } from 'react';
import './card.scss';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { DesignActions } from '../../store/Design365';
import { ReactComponent as HeartIcon } from '../../assets/images/heart.svg';
import UseScrollHover from '../../Hooks/UseScrollHover';

const Card = ({ data }) => {
 
  const dispatch = useDispatch();
  const navigate = useNavigate();
  function link(id) {
    axios
      .post(`${process.env.REACT_APP_API}frontend/365Dsign/likes`, { id })
      .then((res) => {});
    dispatch(DesignActions.updateDesignLikes(id));
  }
  const [showCursor, hideCursor] = UseScrollHover();

  function showDetails(id, name) {
    navigate(`/365design/details/${name?.split(' ').join('_')}`);
  }

 
  return (
    <>
      {data && (
        <div
          className="card_365"
          onMouseEnter={showCursor}
          onMouseLeave={hideCursor}
        >
          <div>
            <div className="card_365_head m-0 d-flex align-items-center w-100 px-4">
              <p className="m-0 name">{data?.name}</p>
              <p className="price m-0 ms-auto">
                {data?.state === 'free' ? 'Free' : `$${data?.price}`}
              </p>
            </div>

            <div
              className="p-0 mt-2 card_365_card_image_cont"
              onClick={() => showDetails(data?.id, data?.name)}
            >
              {data?.image ? (
                <img src={data?.image} alt="" className="w-100 p-0 h-100" />
              ) : null}
              <span className="img__arrow"></span>
            </div>

            <div className="card_365_foot m-0 px-4">
              <div className="d-flex h-100 align-items-center gap-1 w-100">
                <span
                  className={`fav_span ${data?.ip ? '' : 'favourited'}`}
                  onClick={() => link(data?.id)}
                >
                  <HeartIcon />
                </span>
                <p className="mb-0 fs-5">{data?.likes}</p>
                <div
                  onClick={() => showDetails(data?.id, data?.name)}
                  className="details_link ms-auto"
                >
                  View details
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Card;
