import React from 'react';
import { ProcessB } from './style';

function ProcessBlog({ sectionData }) {
  return (
    <ProcessB>
      <div className="container-md p-0 py-md-5">
        <div className="row row-top py-md-5 g-0 g-md-3">
          <div className="col-12  d-flex justify-content-between">
            {sectionData?.topImage && (
              <div className="image-top col-md-6">
                <div data-aos="new-animation" data-aos-once="true">
                  <img className="" src={sectionData?.topImage} alt="pic" />
                </div>
              </div>
            )}
            <div className="arrow-icon d-none d-md-block col-6 text-center">
              <svg
                width="65"
                height="110"
                viewBox="0 0 65 110"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M-1.38171e-06 78.3902L29.9661 107.635L32.5 110L35.0339 107.635L65 78.3902L59.9322 73.4445L36.0254 96.7755L36.0254 6.05467e-06L28.9746 6.36287e-06L28.9746 96.7755L5.0678 73.4445L-1.38171e-06 78.3902Z"
                  fill="#DFE5EE"
                ></path>
              </svg>
            </div>
          </div>
          {sectionData?.downImageDescription && (
            <div className="col-md-7 pt-5 mt-md-5">
              {sectionData?.downImageDescription?.split('|').map((parg) => (
                <p
                  data-aos="fade-up"
                  data-aos-once="true"
                  className="padding-responsive"
                  dangerouslySetInnerHTML={{ __html: parg }}
                />
              ))}
            </div>
          )}
          {sectionData?.downImage && (
            <div className="col-md-5 pt-5 mt-md-5 image-down">
              <div className="image-down-cont">
                <div data-aos="new-animation" data-aos-once="true">
                  <img className="" src={sectionData?.downImage} alt="pic" />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </ProcessB>
  );
}

export default ProcessBlog;
