import React, { useEffect } from 'react';
import './card.scss';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { DesignActions } from '../../store/Design365';
import { ReactComponent as HeartIcon } from '../../assets/images/heart.svg';
import UseScrollHover from '../../Hooks/UseScrollHover';
import Skeleton from 'react-loading-skeleton';

const Card = ({ data }) => {
 
  const dispatch = useDispatch();
  const navigate = useNavigate();
  function link(id) {
    axios
      .post(`${process.env.REACT_APP_API}frontend/365Dsign/likes`, { id })
      .then((res) => {});
    dispatch(DesignActions.updateDesignLikes(id));
  }
  const [showCursor, hideCursor] = UseScrollHover();

  function showDetails(id, name) {
    navigate(`/365design/details/${name?.split(' ').join('_')}`);
  }

 
  return (
    <>
 
        <div
          className="card_365 skel"
        >
          <div>
            <div className="card_365_head m-0 d-flex align-items-start justify-content-between w-100 px-4">
              <Skeleton count={2}  height={24} width={150}/>
              <Skeleton height={24} width={100}/>
            </div>

            <div
              className="p-0  card_365_card_image_cont px-4"
            >
              <Skeleton className='img_style_skel'/>
            </div>

            <div className="card_365_foot m-0 px-4">
              <div className="d-flex h-100 align-items-center gap-1 w-100">
                <span
                  className={`fav_span ${data?.ip ? '' : 'favourited'}`}
                  onClick={() => link(data?.id)}
                >
                  <Skeleton width={80} height={30}/>
                </span>
                <p className="mb-0 fs-5">{data?.likes}</p>
                <div
                  className="details_link ms-auto"
                >
                  <Skeleton width={150} height={30}/>
                </div>
              </div>
            </div>
          </div>
        </div>
   
    </>
  );
};

export default Card;
