import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './style.scss'

const Branding = ({data}) => {
  return (
    <div className='branding'>
        <Container>
            {data?.BodyBrandingImages&&<div className='body_branding_images d-block mb-5'> 
                {<img src={data.BodyBrandingImages[0].image} className='w-100' alt=""/> }
            </div>}
            
            {data?.BodyBranding.length > 0 &&<div className='body_branding'>
                <Row>
                    <Col>
                        <h2>{data?.BodyBranding[0]?.title}</h2>
                    </Col>
                    <Col>
                        {data?.BodyBranding[0]?.description?.split("/").map((text,i) => <p key={i}>{text}</p> )}
                    </Col>
                </Row>
            </div>}

            {data?.BodyBrandingImages&&<div className='body_branding_images d-block mb-5'> 
                {[...data.BodyBrandingImages.slice(1)].map((image, i) => <img src={image.image} key={i} className='w-100' alt=""/> )}
            </div>}
        </Container>
    </div>
  )
}

export default Branding