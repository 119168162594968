import React, { useEffect, useState } from 'react'
import './style.scss'
import { Col, Container, Row } from 'reactstrap'
import useWidth from '../../Hooks/useWidth'
import axios from 'axios'
const Terms = () => {
  const size = useWidth()
  const [data, setData] = useState([])
  const [activeTerm, setActiveTerm] = useState(null)
  const [selected, setSelected] = useState([])
    

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API}frontend/terms`)
    .then((res) => {
      setData(res.data.data)
      console.log(res.data.data);
      setActiveTerm(res.data.data[0].Category.mainTitle)
    })
  }, [])

  useEffect(() => {
    if(activeTerm) {
      setSelected(data.filter(d => d.Category.mainTitle == activeTerm)[0].Collections)
      window.scrollTo(0,0)
    }
    
  }, [activeTerm])

  return (
    <div className='term_condition'>
      <Container>
        <Row>
          <Col md={4} xs={12}>
              {size > 600?<ul className='terms_list sticky-top'>
                  {data.map((d, i) => <li className={d.Category.mainTitle==activeTerm&&"active"} key={i} onClick={() => setActiveTerm(d.Category.mainTitle)}>{d.Category.mainTitle}</li>)}
              </ul>: <div className='menu_mobile p-0 mb-3'>
                        <select value={activeTerm} onChange={(e) => setActiveTerm(e.target.value)}>
                            {data.map((d, i) => <option key={i} value={d.Category.mainTitle}>{d.Category.mainTitle}</option>)}
                        </select>
                    </div>}
          </Col>
          <Col md={8} xs={12}>
              {selected.map((item, i) => <>
                {item.Type == "Title"? <h2 style={{fontSize: item.compmonent.fontSizeID.size, fontWeight: item.compmonent.fontTypeID.size}}>{item.compmonent.title}</h2>:<div dangerouslySetInnerHTML={{__html: item.compmonent.description}}></div>}
              </>)}
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Terms