import React, {useEffect} from 'react'
import { Outlet } from 'react-router-dom'
import Footer from '../../../Components/Footer/Footer'
import ScrollTop from '../../../Components/ScrollTop/ScrollTop'
import Searchbar from '../../../Components/SearchBar/Searchbar';
import Cursor from '../../../Cursor';
import SideBar from '../../../Components/SideBar/SideBar'
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { DesignActions } from '../../../store/Design365';
import "./style.scss"
import HeaderResourses from '../../../Components/Resourses/HeaderResourses/HeaderResourses';


const IndexResourses = () => {
    const dispatch =  useDispatch()

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API}Design365`)
        .then(res => {
            dispatch(DesignActions.setDesignData(res.data.data))
        })
    }, [dispatch])
    useEffect(()=>{
        window.scrollTo(0,0)
      },[])
    return (
        <div className='IndexResourses'>
            <HeaderResourses />
            <Searchbar />
            <SideBar />
            <Cursor />
            <Outlet />
            <ScrollTop />
            <Footer />
        </div>
    )
}

export default IndexResourses