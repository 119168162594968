import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styles from './ContactUsLetsStart.module.css';
function ContactUsLetsStart({ sectionData }) {
  return (
    <div className={styles['contact-us__lets-start']}>
      <Container>
        <Row>
          {sectionData?.title && (
            <Col lg="6">
              <h2 className={styles['contact-us__lets-start-heading']}>
                {sectionData?.title}
              </h2>
            </Col>
          )}
          {sectionData?.description && (
            <Col lg="6">
              <p
                className={styles['contact-us__lets-start-content']}
                dangerouslySetInnerHTML={{ __html: sectionData?.description }}
              />

              {/* <p className={styles['contact-us__lets-start-content']}>
                {sectionData?.description}
                Have a project for us? Or a general question? Contact us! kindly
                fill us this simple form at
                <span className={styles['contact-us__lets-start-mail']}>
                  hello@layouti.com
                </span>{' '}
                and we’ll reach out.
              </p> */}
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
}

export default ContactUsLetsStart;
