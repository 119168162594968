import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './style.scss'
const Persona = ({data}) => {
  return (
    <>
        {data?.length !== 0 &&<div className='persona'>
            <Container>
                <Row>
                    <Col>
                        <h2 className='persona_title'>Persona</h2>
                    </Col>
                </Row>
                {data?.length > 1&&<Row>
                    {data.map((card, i) =>
                        <Col xs={6}>
                            <img className="persona_img" src={card.image} alt=""/>
                            <div className="persona_info">
                                <h2>{card.title}</h2>
                                {/* <p>{card.description}</p> */}
                                {card?.description?.split('/').map((text, i) => <p key={i}>{text}</p>)}
                            </div>
                            <div className="persona_info">
                                <h2>{card?.otherTitle}</h2>
                                {card?.otherDescription?.split('/').map((text, i) => <p key={i}>{text}</p>)}
                            </div>
                        </Col>)
                    }
                   
                   
                </Row>}
                {data?.length === 1&&<Row>
                    <Col xs={6}>
                        <img className="persona_img" src="/images/layouti/works/persona_one.png" alt=""/>
                    </Col>
                </Row>}
            </Container>
        </div>}
    </> 
  )
}

export default Persona