import styled from 'styled-components';

export const Testim = styled.div`
  background-color: #f7f9fc;
  padding: 150px;
  transition: all 0.25s linear;
  .container {
  }
  > * {
    transition: all 0.25s linear;
  }
  @media only screen and (max-width: 600px) {
    padding: 40px 20px;
  }

  .testim-content {
    width: 75%;
    h2 {
      font-size: 50px !important;
      line-height: 60px !important;
    }
    p {
      margin-top: 20px;
      font-size: 18px;
    }
    @media only screen and (max-width: 600px) {
      width: 100%;
      padding: 50px 0px;
      padding: 0;
      h2 {
        font-size: 35px !important;
        line-height: 45px !important;
      }
      p {
        display: none;
      }
    }
  }
  .second-side {
    @media only screen and (max-width: 600px) {
      padding: 30px 0px;
      img {
        display: none;
      }
    }
    .card {
      margin-left: 16px;
      margin-bottom: 20px;
      // background-color:#23262B!important;
      transition: all 0.25s linear;
      @media only screen and (max-width: 600px) {
        margin-left: 0px;
      }
      .card_body_test {
        @media only screen and (max-width: 600px) {
          padding: 0px;
        }
      }
      svg {
        margin-bottom: 20px;
      }
      .info {
        margin-top: 25px !important;
        img {
          width: 80px;
          height: 80px;
          border-radius: 50%;
        }
        h4 {
          margin-bottom: 0;
          font-size: 18px;
          font-weight: 700;
          @media (max-width: 767px) {
            margin-top: 10px;
            font-size: 18px;
          }
          @media only screen and (max-width: 600px) {
            font-size: 16px;
          }
        }
        p {
          font-size: 16px;
          margin: 0;
        }
      }
    }
  }
  [data-aos='scroll-card-animation'] {
    // opacity: 0;
    transition: all 0.4s 0.2s linear;
    &.aos-animate {
      // opacity: 1
    }
  }
`;
