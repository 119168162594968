import React from 'react'
import {ReactComponent as SuccessPopUp} from '../../../assets/images/successPopUp.svg'
import {ReactComponent as FaliurePopUp} from '../../../assets/images/failurePopUp.svg'
import styles from './PopUp.module.css'
function PopUp({isOpen,closeModal,type,msg}) {
  return (
    <div className={`${styles['popup']} ${isOpen?styles['popup-active']:''}`}>
      <div className={styles['popup-background-color']} onClick={closeModal}></div>
      <div className={styles['popup-container']}>
        <div className={styles['popup-header']}>
          {msg?.title&&<h2 className={styles['popup-header__title']}>{msg?.title}</h2>}
          {msg?.descriptionSuccess&&<p className={styles['popup-header__desc']}>{msg?.descriptionSuccess}</p>}
          {msg?.descriptionError&&<p className={styles['popup-header__error']}>{msg?.descriptionError}</p>}
        </div>
        <div className={styles['popup-image__container']}>
        {type=='success'? 
          <SuccessPopUp className={styles['popup-image__icon']}/>
        :
          <FaliurePopUp className={styles['popup-image__icon']}/>
        }
        </div>
        <div className={styles['popup-footer__close-container']}> 
          <button type='button' onClick={closeModal} className={styles['popup-footer__close-button']}>
            {type=='success'?'Cancel':'Try Again'}
          </button>
        </div>
      </div>
    </div>

  )
}

export default PopUp