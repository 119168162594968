import React from 'react';
import { Link } from 'react-router-dom';
import './details.scss';
import useWidth from '../../Hooks/useWidth';
import Skeleton from 'react-loading-skeleton';

const ProductDetails = (props) => {
  const size = useWidth();

  return (
    <>
        <div className="prod__details">
          <div className="d-flex">
            {props.data?<Link className="categ_details_link" to="/365design/category">
              {props?.data?.category?.title}
            </Link>: <Skeleton width={150} height={35}/>}
          </div>
          <div className="details_body">
            {props?.data?.Informations?.map((rec) => {
              return (
                <div className="details_body_card" key={rec.id}>
                  {rec.title && rec.title !== 'null' ? (
                    <h2 className="details_body_card_title">{rec.title}</h2>
                  ) : null}
                  <p className="details_body_card_desc"> {rec.description} </p>
                </div>
              );
            })}
          </div>
          <div
            className="info_wrap"
            dangerouslySetInnerHTML={{ __html: props?.data?.informationEn }}
          />
          <div className="details_body_img">
            {props?.data?.Images?.map((img) => (
              <img src={img.image} key={img.id} alt="" />
            ))}
          </div>
           {!props.data&&<div className="details_body">
              <div className="details_body_card">
                  <Skeleton width={"90%"} height={30}/>
                  <Skeleton width={"100%"} height={30}/>
                  <Skeleton width={"70%"} height={30}/>
              </div>
              <div className="details_body_card mt-5">
                  <Skeleton width={"40%"} height={30}/>
                  <Skeleton width={"90%"} height={30}/>
                  <Skeleton width={"100%"} height={30}/>
                  <Skeleton width={"70%"} height={30}/>
              </div>
              <div className="details_body_img">
              <Skeleton width={"100%"} height={500}/>
              </div>
              <div className="details_body_img">
              <Skeleton width={"100%"} height={500}/>
              </div>
              <div className="details_body_img">
              <Skeleton width={"100%"} height={500}/>
              </div>
            </div>}
        </div>
    </>
  );
};

export default ProductDetails;
