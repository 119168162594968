import React, { useEffect, useState } from 'react';
import LayoutiSlider from '../../../Components/LayoutiComponents/Global/LayoutiSlider/LayoutiSlider';
import AboutServices from '../../../Components/LayoutiComponents/Services/AboutServices.js';
import ProcessBlog from '../../../Components/LayoutiComponents/Services/ProcessBlog';
import ProcessTimeline from '../../../Components/LayoutiComponents/Services/ProcessTimeline/ProcessTimeline1';
import UICards from '../../../Components/LayoutiComponents/Services/UICards/UICards';
import { LayoutiActions } from '../../../store/Layouti';
import { useDispatch } from 'react-redux';
import axiosConfig from '../../../axiosConfig';
import { useOutletContext, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Projects from '../Works/Projects/Projects';

function LayoutiServices() {
  const dispatch = useDispatch();
  const [pageData, setPageData] = useState(null);
  const [uiCardData, setUiCardData] = useState([]);
  const [cardData, setCardData] = useState([]);
  const [sliderContent, setSliderContent] = useState({
    img: 'images/layouti/services/bg.jpg',
    head: 'We are about masterly UI, UX, and branding solutions',
    text: 'Dreamers. Makers. Doerddds. layoutis’ believe in the power of collaboration to drive impact, change minds, and take actions.',
    buttonText: 'Check out our works',
    buttonLink: '/works',
  });
  const [loadactive, setLoadActive] = useOutletContext()
  useEffect(() => {
    dispatch(LayoutiActions.setHeader(1));
  }, [dispatch]);
 
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function getData() {
    setLoadActive(true)
    axiosConfig
      .get(`frontend/services`, {
        headers: {
          language: `en`,
        },
      })
      .then((res) => {
        localStorage.setItem('servicesPageData', JSON.stringify(res.data.data));
        setPageData(res.data.data);
        setTimeout(() => {
          setLoadActive(false)
        }, 1500)
      })
      .catch((err) => {
        // console.log(err);
      });
  }
  
  useEffect(() => {
    getData();
    // console.log(uiCardData);
  }, []);

  useEffect(() => {
    setSliderContent({
      img: pageData?.HeaderContent?.image
        ? pageData?.HeaderContent?.image
        : null,
      head: pageData?.HeaderContent?.title
        ? pageData?.HeaderContent?.title
        : null,
      text: pageData?.HeaderContent?.description
        ? pageData?.HeaderContent?.description
        : null,
      buttonText: 'Check out our works',
      buttonLink: '/works',
    });

    let modififedUiCards = [];
    pageData?.Categories &&
      pageData?.Categories[0]?.cards.forEach((item, index) => {
        if (item.projects.length != 0) {
          let projectCards = [];
          item.projects &&
            item.projects.forEach((project) => {
              projectCards.push({
                id: project?.id,
                image: project?.image,
                desc: project?.name,
                title: project?.category?.name,
              });
            });

          modififedUiCards.push({
            id: index + 1,
            title: item?.category?.name,
            firstText: item?.tags.length != 0 ? +item : '',
            secondText: item?.description,
            cards: projectCards,
          });
        }
      });
    // setUiCardData()
    if (pageData) {
      setUiCardData(
        pageData.Categories[0].cards.map((card, i) => ({
          ...card,
          id: i + 1,
          title: card?.category?.name,
          firstText: card?.tags.length !== 0 ? card?.tags.join(' ') : '',
          secondText: card?.description,
          cards: card.projects.map((proj) => ({
            id: proj.id,
            image: proj.thumbnailImage,
            title: proj.ProjectName.name,
          })),
        })),
      );
    }
  }, [pageData]);

  // console.log(uiCardData);

  return (
    <>
      <Helmet>
        <title>Layouti Services Page</title>
        <meta
          name="description"
          content="eToy App is a kid's toys app that allows you to exchange toys, sell toys, and gift toys to make another kid happy. Teach your kids how to recycle toys."
        />
        <meta
          name="og:image"
          content="https://laravel.layouti.com/media/EToy/HomePage/Seo/1670570897-Marketing-Campaigns-4.png"
        />
        <link rel="canonical" href="/services" />
      </Helmet>
      <LayoutiSlider data={sliderContent} />
      <AboutServices sectionData={pageData?.LearnAboutLayouti} />
      <ProcessBlog sectionData={pageData?.LearnAboutLayouti} />
      <ProcessTimeline sectionData={pageData?.ProcessTimeline} />
      <AboutServices
        sectionData={pageData?.Categories ? pageData?.Categories[0] : []}
      />

      {/* <Projects filteredProjects={uiCardData} /> */}

      {uiCardData.length !== 0 &&
        uiCardData.map((category, index) => <UICards data={category} />)}

      {/* <Projects data={uiCardData} /> */}
    </>
  );
}

export default LayoutiServices;
