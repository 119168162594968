import styled from "styled-components";

export const ProdDetailsTitle = styled.h1`
    font-size: 30px;
    font-weight: 800;
    line-height: 47px;
`

export const FlexDiv = styled.div`
    display: flex;
    justify-content: right;
    align-items: center;
    gap: 20px;
`

export const RoundedArrowButton = styled.button`
    background: ${props => props.c};
    color: #fff;
    border: none;
    outline: none;
    width: 88px;
    height: 88px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transition: all 0.5s linear;
    &.not_active {
        opacity: 0.3;
    }
`

export const Deliverables = styled.div`
    background: ${props=>props.bgColor? props.bgColor : '#F6F6F6'};
    padding: 50px;
    width: 100%;
    height: 100%;
    display: block;
    >h2 {
        font-size: 30px;
        font-weight: 700;
        line-height: 47px;
        margin-bottom: 20px;
    }
    >ul {
        padding: 0;
        li {
            font-size: 18px;
            font-weight: 400;
            line-height: 26px;
        }
    }
`


export const StepsInfo = styled.div`
    margin-bottom: 50px;
    >h1 {
    font-size: 30px;
    font-weight: 700;
    line-height: 18px;
    margin-bottom: 20px ;
    }
    >h3 {
        font-size: 20px;
        font-weight: 600;
        line-height: 23px;
    }
    >h2 {
        font-size: 30px;
        font-weight: 700;
        line-height: 47px;
        margin-bottom: 20px;
    }
    h4 {
        margin-top: -20px;
    }
    >p {
        font-size: 18px;
        font-weight: 400;
        line-height: 26px;
        width: 85%;
        margin-bottom: 10px;
        &.challenge { 
            width: 100%;
            color: #23262B;
        }
    }
    @media (max-width: 600px) {
        padding: 0 20px;
        margin-bottom: 0;
    }
`


export const StepsCovers = styled.div`
    height: 400px;
    width: 100%;
    margin: 120px auto;
    margin-top: 0;
    background:url('${props=>props?.bgImg?props?.bgImg:''}') no-repeat;
    background-size:cover;
    display: flex;
    >img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: fill;
    }
    >h1{
        margin: auto 170px;
        color:white;
        font-size:40px;
        width: 300px;
        @media (max-width: 600px) {
            margin: auto 20px;
            font-size:36px;
        }
    }
    @media (max-width: 600px) {
        height: 200px;
        margin-bottom: 0;
    }
`

export const ChallengeCard = styled.div`
    padding: 40px;
    background-color: ${props=>props?.bgStatic?props?.bgStatic:'#FBFBFB'};
    transition: all 0.3s linear;
    height: 100%;
    >h1 {
        font-size: 24px;
        font-weight: 700;
        line-height: 29px;
        margin-bottom: 20px
    }
    >p {
        font-size: 18px;
        font-weight: 400;
        line-height: 26px;
    }
    &:hover {
        background-color: ${props=>props?.bgHover?props?.bgHover:'#4079BD'} ;
        color: #fff;
    }
`

export const LayoutiProdImagesSec = styled.div`
    margin: 120px 0;
    margin-bottom: 60px;
    h2 {
        font-size: 30px;
        font-weight: 700;
        line-height: 36px;
        text-transform: capitalize;
    }
    p {
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 35px;
        width: 75%;
    }
    img{
        width: 100%;
    }
    @media (max-width: 600px) {
        margin: 50px 0;
        padding: 0px 20px;
        .container  {
            padding: 0;
        }
    }
`



