import styled from "styled-components";
import { ColorGreen } from "../Utilities";

export const ArticaleSection = styled.section
`
padding:70px 0;
.left-side{
    .article-content{
        //padding:10px;
        //margin-bottom:20px;
        width: 100%;
        h3{
            font-weight: 700;
            //padding-bottom: 20px;
            padding-top: 30px;
            padding-bottom: 10px;
            margin: 0px;
            font-size: 32px;
            line-height: 40px;
        }
        p{
            font-family: "Arial", Sans-serif;
            font-size: 17px;
            font-weight: 400;
            padding: 10px 0px;
            margin: 0px;
            color: #23262B;
        }
        img{
          //margin-bottom:110px;
          padding: 10px 0px;
          object-fit: cover;
        }
    }
    .social{
      h4{
        font-size: 20px;
        font-weight: 600;
        margin: 30px 0 20px;
      }
      a{
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #23262B!important;
        color: #FFF;
        display: flex;
        justify-content: center;
        align-items: center;
        transition:all 0.3s ease-in-out;
        svg{
          font-size: 24px;
        }
        &:hover{
          background:${ColorGreen};
        }
      }
    }
}
.banner{
    // height: 100vh;
    position:sticky;
    top:0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .auther-blox{
        img{
          width: 80px;
          height: 80px;
          border-radius: 50px;
        }
        .auther-info{
          h5{
            font-size: 25px;
            font-weight: 700;
            line-height: 30px;
            letter-spacing: 0.3px;
            margin-bottom: 3px;
          }
          p{
            font-size: 20px;
            font-weight: 400;
            margin-bottom: 2px;
            color: #23262B;
          }
        }
    }
}
.article-img-cont{
  transform: translateY(-500px);
}
.article-img{
  width: 100%;
  height: 30rem;
  object-fit: cover;
}
.article-link{
  font-weight: 600;
  font-size: 18px;
  color: white;
}
.article-title{
  line-height: 50px;
  font-size: 45px;
  color: white;
  font-weight: 700;
  @media(max-width:992px){
    color:black
  }
}
.article-content-cont{
  padding-top:80px;
  @media(max-width:992px){
    padding-top: 200px;
  }
}
`