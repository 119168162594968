import React from 'react'
import Card from '../Card'
import Masonry from 'react-masonry-css'
import CardSkelaton from '../../365HomeCard/CardSkelaton'

const Male = (props) => {
    const breakpointColumnsObj = {
        default: 4,
        600: 1
    };
    return (
        <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column">
                {props.data.map((person, i) => <Card key={i} person={person}/>)}
                {props.data.length === 0&&[1,2,3,4,5,6,7,8,9,10,11,12].map((i) => <CardSkelaton key={i}/> )}
        </Masonry>
                
    )
}

export default Male