import React from 'react'
import { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { ChallengeCard, StepsCovers, StepsInfo } from '../Styled/Index'
import './style.scss'

const Challenges = ({cardDetails, lets}) => {
    // useEffect(()=>{
    //     console.log('cardDetails',cardDetails)
    // },[cardDetails])
    return (
        <>
           {cardDetails?.title&& <div className='challenges'>
                <Container>
                    <Row className='mb-md-5'>
                        <Col md={5}>
                            <StepsInfo >
                               {cardDetails?.title&& <h2>{cardDetails?.title}</h2>}
                            </StepsInfo>
                        </Col>
                        <Col md={7}>
                            <StepsInfo >
                                {cardDetails?.description&&<p className='challenge'>{cardDetails?.description}</p>}
                            </StepsInfo>
                        </Col>
                    </Row>
                    <Row>
                            {
                                cardDetails&&cardDetails?.cards.map((card,index)=>(
                                    <Col className="p-0 col-md-4 col-12" key={index}>
                                        <ChallengeCard bgStatic={cardDetails?.staticColor} bgHover={cardDetails?.hoverColor}>
                                            <h1>{card?.title}</h1>
                                            <p>{card?.description}</p>
                                        </ChallengeCard>
                                    </Col>
                                ))
                            }
                    </Row>
                </Container>
            </div>}
            {
                lets?.image &&
                <StepsCovers bgImg={lets?.image}>
                    <h1>{lets?.title}</h1>
                </StepsCovers>
            }
        </>
    )
}

export default Challenges