import React from "react";
import './ResoursesHeader.scss'
import { Container } from "reactstrap";

const ResoursesHeader = ({data}) => {
    return (
        <div className="header mt-5 mb-5">
            <Container>
                <h2 className="header-resourses_title" data-aos='fade-right' data-aos-delay='500' data-aos-duration='1000' data-aos-once='true'>
                    {data?.title ? data?.title : 'Resources that will help your mind grow.'}
                </h2>
                <p className="header-resourses_description" data-aos='fade-right' data-aos-delay='700' data-aos-duration='1000' data-aos-once='true'>
                {data?.description ? data?.description : 'Layouti enjoys spreading knowledge and assisting you in learning more about what you are interested in. So, these are some resources that will help you in all aspects of user interface and user experience designs.'}
                </p>
            </Container>
        </div>
    );
};

export default ResoursesHeader;
