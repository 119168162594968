import React from 'react';
import { Col, Row } from 'reactstrap';
import useWidth from '../../../../Hooks/useWidth';
import './style.scss';

const AboutTextSection = ({ title, content }) => {
  const size = useWidth();
  return (
    <div className="about_text">
      <div
        className="d-flex
            flex-wrap justify-content-between"
      >
        <h2>{title}</h2>
        {size < 600
          ? null
          : content &&
            content
              ?.split('|')
              .map((parg) => <p dangerouslySetInnerHTML={{ __html: parg }} />)}
      </div>
    </div>
  );
};

export default AboutTextSection;
