import React from "react";
import {
  BoxDev,
  Img,
  Overlay,
  BoxSpan,
  CardBody,
  SectionTitle,
  Section,
} from "../../Blog/style.js";
import { BsArrowRight } from "react-icons/bs";
import UseScrollHover from "../../../../Hooks/UseScrollHover.js";
import { Link } from "react-router-dom";

function News({ title, dataContent }) {
  const [showCursor, hideCursor] = UseScrollHover();
  return (
    <>
      {dataContent?.length > 0 && (
        <Section>
          <div className="container" data-aos="fade-up" data-aos-once="true">
            <SectionTitle>{title}</SectionTitle>
            <div className="row">
              {dataContent?.map((e, i) => (
                <div
                  key={i}
                  className="col-md-6 col-lg-4 mb-3"
                  data-aos="fade-up"
                  data-aos-once="true"
                >
                  <div className="card p-0 border-0 move-up">
                    <Link
                      to="/article"
                      onMouseEnter={showCursor}
                      onMouseLeave={hideCursor}
                    >
                      <BoxDev className="card-img-top">
                        <Img src={e.image} alt="" />
                      </BoxDev>
                    </Link>
                    <CardBody className="card-body">
                      <span className="card-title">{e.category?.name}</span>
                      <h4 className="card-text">{e.title}</h4>
                      <div className="d-flex auther-blox">
                        <img src={e.author?.image} alt="" />
                        <div className="ms-3 auther-info">
                          <h5>{e.author?.name}</h5>
                          <p>{e.author?.position}</p>
                        </div>
                      </div>
                    </CardBody>
                  </div>
                </div>
              ))}
              {/* <div className='col-md-6 col-lg-4 mb-3' data-aos='fade-up' data-aos-once='true'>
                        <div className='card p-0 border-0 move-up'>
                        <Link to="/article" onMouseEnter={showCursor} onMouseLeave={hideCursor}>
                                <BoxDev  className="card-img-top">
                                    <Img src="images/layouti/blog/Awrads_etoyapp.jpeg" alt=""/>
                                </BoxDev>
                            </Link>
                            <CardBody className="card-body">
                                <span className="card-title">Layouti blog</span>
                                <h4 className="card-text">Awards World Usability Day Challenge for eToy App</h4>
                                <div className='d-flex auther-blox'>
                                    <img src="images/layouti/blog/personal-image.jpg" alt=""/>
                                    <div className='ms-3 auther-info'>
                                        <h5>Yaser Nazzal</h5>
                                        <p>Co-founder & Product Manager</p>
                                    </div>
                                </div>
                            </CardBody>
                        </div>
                    </div>
                    <div className='col-md-6 col-lg-4 mb-3' data-aos='fade-up' data-aos-once='true'>
                        <div className='card p-0 border-0 move-up'>
                        <Link to="/article" onMouseEnter={showCursor} onMouseLeave={hideCursor}>
                                <BoxDev  className="card-img-top">
                                    <Img src="images/layouti/blog/Awrads_etoyapp.jpeg" alt=""/>
                                </BoxDev>
                            </Link>
                            <CardBody className="card-body">
                                <span className="card-title">Layouti blog</span>
                                <h4 className="card-text">Awards World Usability Day Challenge for eToy App</h4>
                                <div className='d-flex auther-blox'>
                                    <img src="images/layouti/blog/personal-image.jpg" alt=""/>
                                    <div className='ms-3 auther-info'>
                                        <h5>Yaser Nazzal</h5>
                                        <p>Co-founder & Product Manager</p>
                                    </div>
                                </div>
                            </CardBody>
                        </div>
                    </div> 
                    <div className='col-md-6 col-lg-4 mb-3'>
                        <div className='card p-0 border-0 mx-2' onMouseEnter={showCursor} onMouseLeave={hideCursor}>
                            <BoxDev  className="card-img-top">
                                <Img src="images/layouti/blog/Awrads_etoyapp.jpeg" alt=""/>
                            </BoxDev>
                            <CardBody className="card-body ">
                                <span className="card-title">Layouti blog</span>
                                <h4 className="card-text popular">Awards World Usability Day Challenge for eToy App</h4>
                            </CardBody>
                        </div>
                    </div>*/}
            </div>
          </div>
        </Section>
      )}
    </>
  );
}

export default News;
