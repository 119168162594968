import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { StepsInfo } from '../Styled/Index'
import './style.scss'


const ThanksForWatching = ({cardsData, color}) => {
    return (
        <div className='thanks_for_watch'>
            <Container>
                <Row className="gap-5">
                    <Col>
                        <h2>{cardsData?.title}</h2>
                        <p>{cardsData?.description}</p>
                        <button style={{backgroundColor: color}}>
                            <span >{cardsData?.buttonName}</span>
                        </button>
                    </Col>
                    <Col md={4} className="d-md-block d-none">
                    {
                        cardsData?.cards&&cardsData?.cards.map((card,index)=>(
                            <StepsInfo key={index}>
                                <h2>{card?.text}</h2>
                                <h4>{card?.label} </h4>
                            </StepsInfo>
                        ))
                    }
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ThanksForWatching