import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import SingleArticle from "../../../Components/LayoutiComponents/Article/SingleArticle";
import LayoutiSlider from "../../../Components/LayoutiComponents/Global/LayoutiSlider/LayoutiSlider";
import News from "../../../Components/LayoutiComponents/Global/News";
import { LayoutiActions } from "../../../store/Layouti";

function Article() {
  const [blogData, setBlogData] = useState(null);
  const sliderContent = {
    img: "#23262b",
    head: "",
    text: "",
  };
  const dispatch = useDispatch();
  const params = useParams();
  useEffect(() => {
    // console.log(params.id);
    axios
      .get(`${process.env.REACT_APP_API}frontend/blog/find`, {
        params: { search: `${params.id.split("_").join(" ")}` },
      })
      .then((res) => {
        // console.log(res.data.data);
        setBlogData(res.data.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [params]);

  useEffect(() => {
    dispatch(LayoutiActions.setHeader(2));
  }, [dispatch]);
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <LayoutiSlider data={sliderContent} />
      <SingleArticle dataContent={blogData} />
      <News title="Related articals" dataContent={blogData?.related_blog} />
    </>
  );
}

export default Article;
