import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { FixedButton, HeadTitle } from '../../Utilities';
import { Learned } from './style';

function LearnedThings({ sectionData, cardsData }) {
  return (
    <Learned className="js-100-things">
      <div className="container">
        <Row className="mb-1" data-aos="fade-up" data-aos-once="true">
          <Col sm="6">
            {sectionData?.title && (
              <h2 className="mb-4" style={HeadTitle}>
                {sectionData?.title}
              </h2>
            )}
          </Col>
          <Col sm="6" className="things_head">
            {sectionData?.description &&
              sectionData?.description
                ?.split('|')
                .map((parg) => (
                  <p dangerouslySetInnerHTML={{ __html: parg }} />
                ))}
          </Col>
        </Row>
        {cardsData?.length != 0 && cardsData && (
          <Row className="pt-5 m-1" data-aos="fade-up" data-aos-once="true">
            <Col md="6" className="mb-5 ps-0 pe-md-5 pe-0">
              {cardsData[0]?.title && (
                <h2 className="fs-3 fs-sm-1 ">{cardsData[0]?.title}</h2>
              )}
              {cardsData[0]?.description &&
                cardsData[0].description
                  ?.split('|')
                  .map((parg) => (
                    <p dangerouslySetInnerHTML={{ __html: parg }} />
                  ))}
              {cardsData[0]?.date && (
                <span className="text-muted date">{cardsData[0]?.date}</span>
              )}
            </Col>
            <Col md="6" className="mb-5 ps-0 pe-md-5 pe-0">
              {cardsData[1]?.title && (
                <h2 className="fs-3 fs-sm-1 ">{cardsData[1]?.title}</h2>
              )}
              {cardsData[1]?.description &&
                cardsData[1].description
                  ?.split('|')
                  .map((parg) => (
                    <p dangerouslySetInnerHTML={{ __html: parg }} />
                  ))}
              {cardsData[1]?.date && (
                <span className="text-muted date">{cardsData[1]?.date}</span>
              )}
            </Col>
          </Row>
        )}
       
      </div>
      <Container>
          <FixedButton
            to="/contact-us?scroll=true"
            data-aos="fade-up"
            data-aos-once="true"
          >
            Read more
          </FixedButton>
      </Container>
    </Learned>
  );
}

export default LearnedThings;
