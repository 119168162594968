/* eslint-disable eqeqeq */
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import './BuildTimesless.scss';

const BuildTimesless = ({ secionData, setActiveCategory, activeCategory }) => {
  return (
    <div className="buildTimesless">
      <Container>
        <div className="text-build">
          <Row>
            {secionData && (
              <Col md={6} sm={12}>
                <h2>{secionData.WorkWeFiredUpInnovated.title}</h2>
              </Col>
            )}
            {secionData && (
              <Col md={6} sm={12}>
                {/* <p>
                  {secionData
                    ? secionData.WorkWeFiredUpInnovated.description
                    : ''}
                </p> */}
                <p
                  dangerouslySetInnerHTML={{
                    __html: secionData
                      ? secionData.WorkWeFiredUpInnovated.description
                      : '',
                  }}
                />
              </Col>
            )}
          </Row>
        </div>
        <div className="menu">
          <ul>
            <li
              className={`${activeCategory == '' ? 'active' : ''}`}
              onClick={() => setActiveCategory('')}
            >
              All
            </li>
            {secionData?.categories &&
              secionData?.categories.map((category, index) =>
                category.productsCount != 0 ? (
                  <li
                    key={index}
                    onClick={() => setActiveCategory(category.name)}
                    className={`${
                      activeCategory == category.name ? 'active' : ''
                    }`}
                  >
                    {category?.name}
                  </li>
                ) : null,
              )}
          </ul>
        </div>
        <div className="menu_mobile">
          <select
            value={activeCategory}
            onChange={(e) => setActiveCategory(e.target.value)}
          >
            <option value="">All</option>
            {secionData?.categories &&
              secionData?.categories.map((category, index) => (
                // category.productsCount!=0?
                // <li key={index} onClick={()=>setActiveCategory(category.name)} className={`${activeCategory==category.name?'active':''}`}>{category?.name}</li>
                // :null
                <option value={category.name} key={index}>
                  {category.name}
                </option>
              ))}
          </select>
        </div>
      </Container>
    </div>
  );
};

export default BuildTimesless;
