import React from 'react';
import './AboutProjectCard.scss';

const AboutProjectCard = (props) => {
  return (
    <div
      className="about-features__project-card"
      data-aos="fade-up"
      data-aos-once="true"
    >
      <img
        src={props.data.image}
        alt="project"
        className="about-features__project-img"
      />
      {props?.data?.description?.split('|').map((parg) => (
        <p
          className="about-features__project-desc"
          dangerouslySetInnerHTML={{ __html: parg }}
        />
      ))}
      <p>
        <span className="about-features__project-cat-name">
          {props.data.industry}
        </span>
      </p>
    </div>
  );
};

export default AboutProjectCard;
