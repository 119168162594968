import React, { useEffect, useState } from "react";
import "./HomeLearnUi.scss";
import {
  Container,
  Col,
  Row,
  FormGroup,
  Label,
  Input,
  Form,
  Button,
} from "reactstrap";
import UserInterfaceLearnUi from "../../../Components/UserInterfaceLearnUi/UserInterfaceLearnUi";
import Card from "../../../Components/365HomeCard/Card";
import TestmonialsLearnui from "../../../Components/TestmonialsLearnui/TestmonialsLearnui";
import PowerCardsLearnui from "../../../Components/PowerCardsLearnui/PowerCardsLearnui";
import Steps3Learnui from "../../../Components/Steps3Learnui/Steps3Learnui";
import QuestionsLearnui from "../../../Components/QuestionsLearnui/QuestionsLearnui";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import AdsLearnui from "./Ads/AdsLearnui";
import axios from "axios";
import { Link } from "react-router-dom";
import logoSuccessMs from "../../../assets/images/successMsReg.svg";
import logoErrorMs from "../../../assets/images/errorMsReg.svg";

const HomeLearnUi = () => {
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [packageData, setPackage] = useState("");
  const [dataContent, setDataContent] = useState([]);
  const [countryCode, setCountryCode] = useState(null);
  const [displayOfPoppSuccess, setdDisplayOfPoppSuccess] = useState("d-none");
  const [displayOfPoppError, setdDisplayOfPoppError] = useState("d-none");

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const setValuePackage = (value) => {
    setPackage(value);
  };

  function submit() {
    let data = {
      phone,
      fullName,
      email,
      package: packageData,
      country: countryCode.nameEn,
    };

    axios
      .post(
        "https://laravel.layouti.com/api/frontend/learnUi/sendRegister",
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        // console.log(res.data);
        if (res.data.error === null) {
          setdDisplayOfPoppSuccess(``);
          setFullName("");
          setPhone("");
          setEmail("");
          setPackage("");
        } else {
          setdDisplayOfPoppError(``);
        }
      })
      .catch((err) => {
        console.log(err);
        setdDisplayOfPoppError(``);
      });
  }

  useEffect(() => {
    axios
      .get("https://laravel.layouti.com/api/frontend/learnUi", {
        headers: {
          "Content-Type": "application/json",
          language: `en`,
        },
      })
      .then((res) => {
        //console.log(res.data.data);
        if (res.data) {
          setDataContent(res.data.data);
        }
      });
    axios
      .get("https://laravel.layouti.com/api/frontend/learnUi/getCountry", {
        headers: {
          "Content-Type": "application/json",
          language: `en`,
        },
      })
      .then((res) => {
        setCountryCode(res.data.data);
      });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="content-learnui">
        {/* <div className="background-overlay-learnui"></div> */}
        <div
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            top: "0",
            left: "0",
            backgroundImage: `${
              dataContent?.HeaderContent?.image
                ? `url(${dataContent?.HeaderContent?.image}`
                : "#c5c5c5"
            } )`,
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            transition: "background 0.3s, border-radius 0.3s, opacity 0.3s",
          }}
        ></div>
        <div className="content-ui">
          <Container>
            <Row>
              <Col lg="7" sm="12">
                <div className="text">
                  <h2>{dataContent?.HeaderContent?.title}</h2>
                  <h4>{dataContent?.HeaderContent?.subTitle}</h4>

                  <p>{dataContent?.HeaderContent?.description}</p>
                  <div className="icons-content">
                    <Row>
                      <Col className="info" md="4" sm="12">
                        <div className="sty-link">
                          {/* <i className="fas fa-user"></i> */}
                          {dataContent?.HeaderContent?.iconOfCreated ? (
                            <img
                              className="img-iconr"
                              src={dataContent?.HeaderContent?.iconOfCreated}
                              alt="icon Of Created "
                            />
                          ) : null}
                          <span className="ms-2">
                            {/* <a href="/*">Yaser Nazzal</a> */}
                            <Link to="/learnui" className="">
                              {dataContent?.HeaderContent?.createBy}
                            </Link>
                          </span>
                        </div>
                      </Col>
                      <Col className="info" md="4" sm="12">
                        {/* <i className="fas fa-clock"></i> */}
                        {dataContent?.HeaderContent?.iconInCreated ? (
                          <img
                            className="img-iconr"
                            src={dataContent?.HeaderContent?.iconInCreated}
                            alt="icon In Created "
                          />
                        ) : null}
                        {/* <span className="ms-2">Started in November </span> */}
                        <span className="ms-2">
                          {dataContent?.HeaderContent?.createdIn}{" "}
                        </span>
                      </Col>
                      <Col className="info" md="4" sm="12">
                        {/* <i className="fas fa-regular fa-headphones"></i> */}
                        {dataContent?.HeaderContent?.iconOfSpeaker ? (
                          <img
                            className="img-iconr"
                            src={dataContent?.HeaderContent?.iconOfSpeaker}
                            alt="icon Of Speaker "
                          />
                        ) : null}
                        {/* <span className="ms-2">Arabic </span> */}
                        <span className="ms-2">
                          {dataContent?.HeaderContent?.speaker}{" "}
                        </span>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>

              <Col lg="5" sm="12">
                <div className="form">
                  <div className="headerForm">
                    <h2>Register to the UI Course Design</h2>
                  </div>
                  <Form onSubmit={handleSubmit}>
                    <FormGroup>
                      <Label for="fullName">
                        Full name <span className="star-form">*</span>
                      </Label>
                      <div className="borderForm">
                        <Input
                          id="fullName"
                          name="fullName"
                          placeholder="e.g. Yaser Nazzal"
                          type="text"
                          value={fullName}
                          onChange={(e) => setFullName(e.target.value)}
                        />
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <Label for="email">
                        Email address <span className="star-form">*</span>
                      </Label>
                      <div className="borderForm">
                        <Input
                          id="email"
                          name="email"
                          placeholder="e.g. Hello@layout.com"
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <Label for="mobile-number">
                        Mobile number <span className="star-form">*</span>
                      </Label>
                      <div className="mobileBorder">
                        {countryCode && (
                          <PhoneInput
                            defaultCountry={countryCode.code}
                            id="mobile-number"
                            placeholder="eg. 522372878"
                            value={phone}
                            onChange={(e) => setPhone(e)}
                          />
                        )}
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <Label for="Select">Packaging course</Label>
                      <div className="borderForm">
                        <Input
                          id="Select"
                          name="select"
                          type="select"
                          value={packageData}
                          onChange={(e) => setPackage(e.target.value)}
                        >
                          <option>Select</option>
                          <option
                            value={`UI Design ($${dataContent?.UIDesignPackage?.price}) ${dataContent?.UIDesignPackage?.hours}H`}
                          >
                            UI Design (${dataContent?.UIDesignPackage?.price}){" "}
                            {dataContent?.UIDesignPackage?.hours}H
                          </option>
                          <option
                            value={`UX Design ($${dataContent?.UXDesignPackage?.price}) ${dataContent?.UXDesignPackage?.hours}H`}
                          >
                            UX Design (${dataContent?.UXDesignPackage?.price}){" "}
                            {dataContent?.UXDesignPackage?.hours}H
                          </option>
                          <option
                            value={`UX/UX Design ($${dataContent?.UXUIDesignPackage?.price}) ${dataContent?.UXUIDesignPackage?.hours}H`}
                          >
                            UX/UX Design ($
                            {dataContent?.UXUIDesignPackage?.price})
                            {dataContent?.UXUIDesignPackage?.hours}H
                          </option>
                        </Input>
                      </div>
                    </FormGroup>
                    <Button
                      color="success"
                      className="register"
                      type="button"
                      onClick={submit}
                    >
                      Register Now
                    </Button>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
          <div className={`success-errMs ${displayOfPoppSuccess}`}>
            <div className="success-errMs-content">
              <h2>Congratulations!</h2>
              <p>
                You have successfully registered on Layouti UI Design Course
              </p>
              <img className="mb-4" src={logoSuccessMs} alt="success logo" />
              <div>
                <button
                  className="popBtn"
                  onClick={() => setdDisplayOfPoppSuccess(`d-none`)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
          <div className={`success-errMs ${displayOfPoppError}`}>
            <div className="success-errMs-content">
              <p className="fs-3">Sorry but something going wrong!</p>
              <img className="mb-4" src={logoErrorMs} alt="error logo" />
              <div>
                <button
                  className="popBtn"
                  onClick={() => setdDisplayOfPoppError(`d-none`)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end content-learn-ui */}
      <UserInterfaceLearnUi data={dataContent} />
      <div className="highlight">
        <Container className="p-0">
          {/*<h2 className="text-center">Highlights of our UI Design Work</h2>*/}
          <h2 className="text-center">{dataContent?.HighlitsDesign?.title}</h2>
          {dataContent?.HighlitsDesign?.subTitle ? (
            <p>{dataContent?.HighlitsDesign?.subTitle}</p>
          ) : null}
          <Row className="mt-5 justify-content-center">
            {dataContent?.HighlitsDesign?.highlits?.map((card, index) => (
              <>
                <Col md="4" className="mb-3">
                  <Card data={card} />
                </Col>
              </>
            ))}
          </Row>
        </Container>
      </div>
      {/* end highlight */}
      <TestmonialsLearnui data={dataContent} />
      <PowerCardsLearnui data={dataContent} setValuePackage={setValuePackage} />
      <Steps3Learnui data={dataContent} />
      <QuestionsLearnui data={dataContent} />
      <AdsLearnui />
    </>
  );
};

export default HomeLearnUi;
