import styled, { keyframes } from 'styled-components';

const ButtonAnimation = keyframes`50% {
    transform: scale(0.8);
}
100% {
    transform: scale(1);
}`;

export const Slider = styled.div`
  width: 100%;
  // height: 700px;
  height: 80vh;
  background-color: #23262b;
  background-image: url('${(props) => (props.bg ? props.bg : '')}');
  background-size: cover;
  background-attachment: fixed;
  position: relative;
  transition: all 0.1s linear;
  @media only screen and (max-width: 600px) {
    height: 350px;
  }
  .layouti_slider_overlay {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00122fde;
    color: #fff;
    @media (max-width: 600px) {
      padding-top: 0px;
      display: flex;
      align-items: center;
      .open-page {
        margin: 20px 12px 0 !important;
      }
    }
    .container {
      h1 {
        font-size: 50px;
        font-weight: 700;
        line-height: 60px !important;
        width: 55%;
      }
      div {
        p {
          font-size: 18px;
          font-weight: 400;
          line-height: 26px;
          width: 50%;
          margin-top: 20px;
          a {
            color: #ffffff;
            text-decoration: underline;
            color: #26e71e !important ;
          }
        }
      }
      .open-page__cont {
        /* color: #26e71e !important; */
        display: flex;
        @media only screen and (max-width: 600px) {
          display: none;
        }
      }
      .open-page {
        // padding: 10px 30px;
        text-decoration: none;
        width: 240px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #26e71e;
        border-radius: 34.7209px;
        border: 1px solid #42bf64;
        font-size: 16px;
        line-height: 30px;
        color: #000;
        font-weight: bold;
        margin-top: 40px;
        /* color: #26e71e !important; */
        // transition:transform .3s ease-in-out;
        &:hover {
          animation: ${ButtonAnimation} 0.3s linear 1;
        }
      }
      // @media
      @media only screen and (max-width: 769px) {
        h1 {
          padding-left: 20px;
          width: 70%;
        }
      }
      @media only screen and (max-width: 600px) {
        h1 {
          font-size: 35px;
          line-height: 45px !important;
          width: 85%;
          margin: 0px;
          margin-top: 60px;
          margin-left: 0px;
        }
        p {
          display: none;
        }
      }
    }
  }
`;
