
import styled, { keyframes } from 'styled-components'
import { ColorGreen ,transitonEffect} from '../Utilities'

export const Section = styled.div  
  `
  margin:0 0 100px;
  padding:21px 0 0;
  .box{
    a{
      display:block;
    }
  }

  `

export const SectionTitle = styled.h2
`    font-size: 40px;
    font-weight: 700;
    line-height: 69px;
    margin-bottom: 19px;
    @media (max-width:768px){
      font-size: 35px;
    }
    @media (max-width:426px){
      line-height: 40px;
    }
`
export const Title = styled.h2 
 ` text-align: center;
  font-size: 35px;
`
export const TitleSpan = styled.span 
 ` font-weight: normal;`


export const List = styled.ul 
`  list-style: none;
  text-align: center;
  margin: 80px 0 70px;
  display:flex;
  align-items:center;
  flex-wrap:wrap;
`

export const ListItem = styled.li
 ` display: inline-block;
  padding: 10px 31px;
  background: ${props => props.active ? "#42BF64" : ""};
  color:${props => props.active ? "#FFF" : "#000"};
  cursor:pointer;
  text-transform:capitalize;
  border: 1px solid #42BF64;
  margin-right:10px;
  margin-bottom:10px;
  transition:${transitonEffect};
  &.active{
    background-color: #42BF64;
    color:white;
  }
  `


export const BoxDev = styled.div 
` height:250px;
  font-size: 0;
  position: relative;
  &:hover > p{
    opacity:1
  }
`

export const Img = styled.img 
 ` width: 100%;
   height:100%;`


export const Overlay = styled.p 
`  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #42bf64d9;
  font-size: 15px;
  opacity: 0;
  margin-bottom:0;
  transition :${transitonEffect}
`
const animationSvg = keyframes
`0% {
  margin-left: -10px;
}

100% {
  margin-left: 10px;
}
`
export const BoxSpan = styled.span 
`  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // background: #fff;
  display: block;
  padding: 10px 20px;
  cursor: pointer;
  // border: 2px solid #eb5424;
  svg{
    font-size: 90px;
    color: #FFF;
    opacity: 0.7;
    animation: ${animationSvg} .5s ease infinite alternate;
  }
`

export const CardBody = styled.div
`   padding:0; 
    padding-bottom: 20px;
span{
  color:${ColorGreen};
  margin: 18px 0;
  display: inline-block;
}
h4{
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
  margin-bottom:40px;
  transition:${transitonEffect};
  &:hover{
    color:${ColorGreen}
  }
}
.popular{
  font-size: 20px;
  line-height:26px;
}
.auther-blox{
  img{
    width: 65px;
    height: 65px;
    border-radius: 50px;
    transition:all .5s ease-in-out;
    &:hover{
        transform:scale(1.04)
    }
  }
  .auther-info{
    h5{
      font-size: 20px;
      font-weight: 700;
      line-height: 30px;
      letter-spacing: 0.3px;
      margin-bottom: 3px;
    }
    p{
      font-size: 16px;
    font-weight: 400;
    margin-bottom: 2px;
    }
  }
}
`
// Subscribe section style

export const SubSection = styled.section
`
.sub-box{
  padding: 60px 150px 70px 150px;
  margin-bottom: 100px;
  background-color: #F1F3F6;
  @media (max-width:768px){
    padding: 60px;
  }
  @media (max-width:426px){
    padding: 30px;
  }
  p{
    font-family: "Arial", Sans-serif;
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
  }
  input{
    border-radius: 40px;
    padding: 14px 20px;
    width: 430px;
    margin-right:35px;
    &::placeholder{
      color:#c4c4c4
    }
    @media (max-width:768px){
      width: 100%;
      margin-right: 0;
    }
    
  }
}
`