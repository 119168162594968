import styled, { keyframes } from "styled-components";

export const Slider = styled.div
`
        height: 90vh;
        background-color: red;
        background: url('${props=>props.bg? props.bg : ''}') no-repeat fixed;
        background-size:cover;
        background-position: center;
`



export const worksDetails = styled.div
`
       
`


