import React from 'react'
import { OurClients } from './styled'

const OurClient = ({bg, data}) => {
  return (
    <OurClients bg={bg}>
        <div>
            <img src="/images/layouti/works/qoute.png" alt="" />
            <p> 
                {data.description}
            </p>
            {data.useName&&<div className='user_info'>
                <h3>{data.userName}</h3>
                <p>
                    {data.postision}
                </p>
            </div>}
        </div>
    </OurClients>
  )
}

export default OurClient