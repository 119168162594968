import React, { useEffect } from 'react'
import { Accordion} from 'react-bootstrap'
import styles from './ContactUsAccordion.module.css'
import {ReactComponent as MinusIcon } from '../../../../assets/images/minus.svg'
import Aos from 'aos'
function ContactUsAccordion({isVisible,cardData}) {
    useEffect(()=>{
        Aos.refresh()
    },[isVisible])
  return (
    <div className={`${styles['contact-us__accordion-cont']} ${!isVisible? styles['contact-us__accordion-cont--hidden']:''} `}>
        <Accordion defaultActiveKey="0">
            {
                cardData&&cardData.map((card,index)=>(
                    <Accordion.Item eventKey={index} key={index} className={styles['contact-us__accordion-item']} data-aos='fade-up' data-aos-once='true'>
                        <Accordion.Header className={styles['contact-us__accordion-header']}>
                            <span className={styles['contact-us__accordion-title']}>{card?.question}</span>
                            <div className={styles['contact-us__accordion-icon-cont']}>
                                <MinusIcon className={styles['contact-us__accordion-minus-icon']}/>
                                <MinusIcon className={styles['contact-us__accordion-minus-icon']}/>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body className={styles['contact-us__accordion-body']}>
                            <div  
                                dangerouslySetInnerHTML={{__html: card?.answer}}
                                className="w-75"
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                ))
            }
        </Accordion>
    </div>
  )
}

export default ContactUsAccordion