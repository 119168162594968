import React from 'react';
import { ThingsSection } from './style';

function ThingsComponent({ sectionData }) {
  return (
    <ThingsSection>
      <div className="container">
        <div className="row">
          <div className="col-md-7 d-flex flex-column">
            {sectionData &&
              sectionData.map((card, index) => (
                <div className="card" key={index}>
                  <div className="card-body">
                    {card.title && (
                      <h2 className="card-title">
                        {index + 1}. {card.title}
                      </h2>
                    )}
                    {card.description &&
                      card?.description
                        ?.split('|')
                        .map((parg) => (
                          <p
                            className="card-text"
                            dangerouslySetInnerHTML={{ __html: parg }}
                          />
                        ))}
                    {card.date && (
                      <p className="card-text">
                        <small className="text-muted">{card.date}</small>
                      </p>
                    )}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </ThingsSection>
  );
}

export default ThingsComponent;
