import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
// import AcademyLogo from "../../../assets/images/AcademyLogo.svg";
import sideImage from '../../../assets/images/365UiDesign.svg';
import useWidth from '../../../Hooks/useWidth';

function ProductItem({ product }) {
  const size = useWidth();
  return (
    <>
      {size > 600 ? (
        <div className="prod-card">
          <Row className="p-0 m-0 h-100">
            <Col lg="7" xs="12" className="order-2 order-lg-1 px-0  h-100">
              <div className="prod-card__content-cont  h-100">
                <div className="prod-card__content--padding">
                  <div className="prod-card__logo-cont">
                    <img
                      className="prod-card__logo"
                      src={product?.logo ? product?.logo : sideImage}
                      alt="logo"
                    />
                    <div className="prod-card__project-info">
                      <h2 className="prod-card__project-title">
                          {product?.title}
                      </h2>
                      <h3 className="prod-card__project-field">
                        {product?.subTitle}
                      </h3>
                    </div>
                  </div>

                  <p className="prod-card__desc">{product?.description}</p>
                  <div className="prod-card__actions-cont">
                    {product?.downloadLink ? (
                      <a
                        className="prod-card__action prod-card__action--download"
                        href={product?.downloadLink}
                      >
                        Download Free
                      </a>
                    ) : (
                      <a
                        className="prod-card__action prod-card__action--disable"
                        href={product?.downloadLink}
                      >
                        Download Free
                      </a>
                    )}
                    {product?.reviewLink ? (
                      <a
                        className="prod-card__action prod-card__action--preview"
                        href={product?.reviewLink}
                        target="blank"
                      >
                        Review
                      </a>
                    ) : (
                      <a
                        className="prod-card__action prod-card__action--disable"
                        href={product?.reviewLink}
                        target="blank"
                      >
                        Review
                      </a>
                    )}
                  </div>
                </div>
                <div className="prod-card__more-details-cont--padding">
                  <div className="prod-card__more-details-cont">
                    {product?.cards?.map((card, i) => (
                      <div key={i} className="prod-card__more-detail">
                        <h6 className="prod-card__more-details-title">
                          {card.title}
                        </h6>
                        <p className="prod-card__more-details-desc">
                          {card.description}
                        </p>
                        {/* <p className="prod-card__more-details-desc">{card.created_at}</p> */}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </Col>
            <Col lg="5" xs="12" className="order-1 order-lg-2 px-0">
              <div className="prod-card__imgcon  prod-card__img--padding">
                <img
                  className="prod-card__img"
                  src={product?.slide ? product?.slide : sideImage}
                  alt="img"
                />
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        <>
          <div className="card-mobile">
            <div className="card-header">
              <div className="mobile-card-header-content">
                <div className="mobile-card-header-title">
                  <img
                    className="mobile-card__logo"
                    src={product?.logo ? product?.logo : sideImage}
                    alt="logo"
                  />
                  <div className="prod-card__project-info">
                    <h2 className="prod-card__project-title">
                     
                        {product?.title}
                    </h2>
                    <h3 className="prod-card__project-field">
                      {product?.subTitle}
                    </h3>
                  </div>
                </div>
                <p className="mobile-card__headertext">
                  {product?.description} 
                </p>
              </div>
              <div className="mobile-card__buttons">
                <a className={`download-free ${product.downloadLink?"":"disabled"}`} href={product?.downloadLink} >
                  Download Free
                </a>
                <a href={product?.reviewLink} className="review">
                  Review
                </a>
              </div>
            </div>
            <div></div>
            <div className="card-header">
              <div className="mobile-card-header-content">
                <div>
                  {product?.cards?.map((card, i) => (
                    <div key={i} className="card__mobile-details">
                      <h6 className="card__mobile-header">{card.title}</h6>
                      <p className="card__mobile-text">{card.description} </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div>
              <img
                className="prod-card__img"
                src={product?.slide ? product?.slide : sideImage}
                alt="img"
              />
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default ProductItem;
