import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import Footer from '../../../Components/Footer/Footer';
import Header365 from '../../../Components/Header365/Header365';
import ScrollTop from '../../../Components/ScrollTop/ScrollTop';
import Searchbar from '../../../Components/SearchBar/Searchbar';
import Cursor from '../../../Cursor';
import SideBar from '../../../Components/SideBar/SideBar';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { DesignActions } from '../../../store/Design365';
import MetaTags from 'react-meta-tags';

import './style.scss';
import LoadingPage from '../../../Components/LoadingPage/LoadingPage';

const Index365 = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState(null)
  const [loadactive, setLoadActive] = useState(false);


  useEffect(() => {
    setLoadActive(true)
    axios
      .get(`${process.env.REACT_APP_API}frontend/365Dsign/projects?perPage=9`)
      .then((res) => {
        dispatch(DesignActions.setDesignData(res.data.data));
        setTimeout(() => {
          setLoadActive(false)
        }, 2500)
      });
    axios.get(`${process.env.REACT_APP_API}frontend/365Dsign`).then((res) => {
      dispatch(DesignActions.setCatgory(res.data.data));
      console.log(res.data.data);
    });
    axios.get(`${process.env.REACT_APP_API}frontend/365Dsign/navbar`).then((res) => {
     setData(res.data.data);
    });
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="index365">
      <Header365 data={data}/>
      <MetaTags>
        <link rel='icon' href={data?.favIcon} />
      </MetaTags>
      <Searchbar />
      <SideBar />
      {loadactive&&<LoadingPage />}
      <Cursor />
      <Outlet context={[loadactive, setLoadActive]}/>
      <ScrollTop />
      <Footer />
    </div>
  );
};

export default Index365;
