import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import Card from './Card';
import Masonry from 'react-masonry-css';
import { useSelector, useDispatch } from 'react-redux';
import { InfinitySpin } from 'react-loader-spinner';
import axios from 'axios';
import { DesignActions } from '../../store/Design365';
import CardSkelaton from './CardSkelaton';
const HomeCard365 = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const designs = useSelector((state) => state.design.data);
  const next = designs?.pagination?.nextPage || designs?.pagination?.nexrPage;
  const [perPage, setPerPage] = useState(18);

  const breakpointColumnsObj = {
    default: 3,
    600: 1,
  };

  function getMoreData() {
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API}frontend/365Dsign/projects?perPage=${perPage}`,
      )
      .then((res) => {
        setPerPage(perPage + 9);
        dispatch(DesignActions.setDesignData(res.data.data));
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  useEffect(() => {
    if (designs.length !== 0) {
      setData(designs.projcts);
    }
  }, [designs]);
 
  return (
    <Container className=" card365_cont ">
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {data.length !== 0?data.map((d, i) => (
          <Card key={i} data={d} />
        )):  [1,2,3,4,5,6,7,8,9,10,11,12].map(e =><CardSkelaton key={e}/>)}
      </Masonry>
      {next ? (
        <>
          {loading ? (
            <div className="spin">
              <InfinitySpin color="#3aaa35" width="150" height="45" />
            </div>
          ) : (
            <button className="load_more" onClick={getMoreData}>
              Load More
            </button>
          )}
        </>
      ) : (
        <h2 className="notload-h2 d-none">You've reached the end of the list</h2>
      )}
    </Container>
  );
};

export default HomeCard365;
