import React from 'react'
import { Col, Row } from 'reactstrap'
import CV2022Experience from '../CV2022Experience/CV2022Experience'
import './CV2022Content.scss'

import {ReactComponent as StarIcon} from '../../../assets/images/star.svg'
import { useRef } from 'react'
import { useEffect } from 'react'
function CV2022Content() {
    const jobData = [
        {
            id:1,
            job_year:'Present - 2019',
            job_title:'Product Experience Manager/UX Consultants - Full Time',
            job_place:'Publicis Sapient / UAD - Dubai',
            job_category:[
            {
                title:'Job Responsibilities',
                desc:'Responsible for the user experience towards the marketing and technical teams, and in charge of user interface design for all assets.',
                items:[
                    {
                        id:1,
                        name:'Act as the user advocate during the development process, offering implementation suggestions from a user perspective and ensuring adherence to best practices and standards in user interface design',
                    },
                    {
                        id:2,
                        name:' Define information architecture, navigation model for the project, creating mockups, prototypes, user flows, personas, and low/high fidelity prototypes',
                    },
                    {
                        id:3,
                        name:'Translating wireframes into exciting and compelling visual designs with a user interface best practices and standards',
                    },
                    {
                        id:4,
                        name:'Communicate and envision concepts and ideas to team members, stakeholders, and clients.',
                    },
                    {
                        id:5,
                        name:'Execute all visual design stages from concept to final hand-off to engineering.',
                    },
                    {
                        id:6,
                        name:'Establish and promote design guidelines, best practices, and standards.',
                    },
                    {
                        id:6,
                        name:'Recommend best approaches for implementation of user experience.',
                    },
                    {
                        id:7,
                        name:'Lead through rapidly evolving requirements, including multiple projects with dependencies on other internal teams.',
                    },
                    {
                        id:8,
                        name:'Help the team explore a variety of design directions, analyzing the pros and cons of each.',
                    },
                ]
            },
            {
                title:'References',
                desc:'',
                items:[
                    {
                        id:1,
                        name:'Ms. Hiba Mojabber – hiba.mojabber@publicissapient.com',
                    },
                    {
                        id:2,
                        name:'Mr. Ahmed Elhenedy – ahmed.elhenedy@publicissapient.com',
                    },
                    
                ]
            },

            ]
        },
        {
            id:2,
            job_year:'2019 - 2018',
            job_title:'Lead of UI/UX and Graphic Design - Full Time',
            job_place:'Equiti Group Limited Jordan / Jordan - Amman',
            job_category:[
            {
                title:'Job Responsibilities',
                desc:'Responsible for the user experience towards the marketing and technical teams, and in charge of user interface design for all assets.',
                items:[
                    {
                        id:1,
                        name:'Act as the user advocate during the development process, offering implementation suggestions from a user perspective and ensuring adherence to best practices and standards in user interface design',
                    },
                    {
                        id:2,
                        name:' Define information architecture, navigation model for the project, creating mockups, prototypes, user flows, personas, and low/high fidelity prototypes',
                    },
                    {
                        id:3,
                        name:'Translating wireframes into exciting and compelling visual designs with a user interface best practices and standards',
                    },
                    {
                        id:4,
                        name:'Communicate and envision concepts and ideas to team members, stakeholders, and clients.',
                    },
                    {
                        id:5,
                        name:'Execute all visual design stages from concept to final hand-off to engineering.',
                    },
                    {
                        id:6,
                        name:'Establish and promote design guidelines, best practices, and standards.',
                    },
                    {
                        id:6,
                        name:'Recommend best approaches for implementation of user experience.',
                    },
                    {
                        id:7,
                        name:'Lead through rapidly evolving requirements, including multiple projects with dependencies on other internal teams.',
                    },
                    {
                        id:8,
                        name:'Help the team explore a variety of design directions, analyzing the pros and cons of each.',
                    },
                ]
            },
            {
                title:'References',
                desc:'',
                items:[
                    {
                        id:1,
                        name:'Ms. Hiba Mojabber – hiba.mojabber@publicissapient.com',
                    },
                ]
            },

            ]
        },
        {
            id:3,
            job_year:'2018 - 2016',
            job_title:'Senior UI/UX Designer - Full Time',
            job_place:'PMinistry of Economy & Commerce / Qatar - Doha',
            job_category:[
            {
                title:'References',
                desc:null,
                items:[
                    {
                        id:1,
                        name:'Mr. Tariq Al-Sada – taalsada@mec.gov.qa',
                    },
                    {
                        id:2,
                        name:'Mr. Faissal Al-Haithami – falhaithami@mec.gov.qa',
                    },
                    
                ]
            },

            ]
        },
        {
            id:4,
            job_year:'2016 - 2014',
            job_title:'Senior Graphic Designer - Full Time',
            job_place:'Kayan Web Design / Jordan - Amman',
            job_category:[
            {
                title:null,
                desc:'Motion Graphic, UI Web Design, Corporate Identity, and Printing Communications.',
                items:null
            },

            ]
        },
    ]
    const educationData = [
        {
            id:1,
            job_year:'2010 - 2009',
            job_title:'Major: MA, Illustration & Animation',
            job_place:'Coventry University - UK',
            job_category:null
        },
        {
            id:2,
            job_year:'2009 - 2005',
            job_title:'Major: Bachelor’s Degree, Graphic Designer',
            job_place:'Applied Science University - Jordan',
            job_category:null
        },
       
        
    ]
    const coursesData = [
        {
            id:1,
            job_year:'2014',
            job_title:'Certified Usability Analyst (CUA) HFI in Dubia -USA',
            job_place:null,
            job_category:null
        },
        {
            id:2,
            job_year:'2014',
            job_title:'JAVA Advanced Course Mobile Platform, Gaming Lab in Jordan.',
            job_place:null,
            job_category:null
        },
        {
            id:3,
            job_year:'2013',
            job_title:'Web Design Advanced Course, SAE in Jordan.',
            job_place:null,
            job_category:null
        },
        {
            id:4,
            job_year:'2012',
            job_title:'After Effect CS6 & Final Cut Por 10x Advanced Course, SAE in Jordan.',
            job_place:null,
            job_category:null
        },
    ]
    const languagesData = [
        {
            id:1,
            job_year:null,
            job_title:'English',
            job_place:'Very Good',
            job_category:null
        },
        {
            id:1,
            job_year:null,
            job_title:'Arabic',
            job_place:'Native',
            job_category:null
        },
        
    ]
    
    const skillsData = [
        {
            id:1,
            name:'Adobe Illustrator CC 2021',
            rate:4.5
        },
        {
            id:2,
            name:'Adobe Illustrator CC 2021',
            rate:4
        },
        {
            id:13,
            name:'Adobe Illustrator CC 2021',
            rate:2.5
        },
        {
            id:4,
            name:'Adobe Illustrator CC 2021',
            rate:1.4
        },
    ]
   
    const inteestData = ['Handmade products','Drawing','Mountain climbing','Watching animation movies','Photography']

    const experienceRef=useRef(null)
    const educationRef=useRef(null)
    const coursesRef=useRef(null)
    const languagesRef=useRef(null)
    const skillsRef=useRef(null)
    const interestRef=useRef(null)

    const experienceLinkRef=useRef(null)
    const educationLinkRef=useRef(null)
    const coursesLinkRef=useRef(null)
    const languagesLinkRef=useRef(null)
    const skillsLinkRef=useRef(null)
    const interestLinkRef=useRef(null)
    const linksRefs= [experienceLinkRef ,educationLinkRef ,coursesLinkRef ,languagesLinkRef ,skillsLinkRef ,interestLinkRef]

    let navigateToSection =(type)=>{
        window.scrollTo({
            top:type.current.offsetTop-150
        })
    }
    let checkCurrentElementVisible =(eleRef)=>{
        return window.scrollY <=eleRef.current.offsetTop +eleRef.current.clientHeight -200
    }
    let checkCurrentElement =()=>{
        linksRefs.forEach(val=>{
            val.current.classList.remove('active')
        })
        if(checkCurrentElementVisible(experienceRef)){
            experienceLinkRef.current.classList.add('active')
        }else if(checkCurrentElementVisible(educationRef)){
            educationLinkRef.current.classList.add('active')
        }else if(checkCurrentElementVisible(coursesRef)){
            coursesLinkRef.current.classList.add('active')
        }else if(checkCurrentElementVisible(languagesRef)){
            languagesLinkRef.current.classList.add('active')
        }else if(checkCurrentElementVisible(skillsRef)){
            skillsLinkRef.current.classList.add('active')
        }else if(checkCurrentElementVisible(interestRef)){
            interestLinkRef.current.classList.add('active')
        }
    }
    useEffect(()=>{
        window.addEventListener('scroll',checkCurrentElement)
        return ()=>{
            window.removeEventListener('scroll',checkCurrentElement)
        }
    },[])
  return (
    <section className='cv-content'>
        <Row>
            <Col lg='2'>
                <h2 className='cv-cover__title'>
                    Cover Letter
                </h2>
            </Col>
            <Col lg='10'>
                <Row>
                    <Col lg='3'></Col>
                    <Col lg='9'>
                        <p className='cv-cover__desc'>An obsessed designer with the virtual world that can be turned into reality with smart designs! Adept at creating ideas that no one would have thought of.
                            <br/><br/>12 years of experience in UI design and linking it to a smart UX plan. Able to plan, manage and implement various projects and follow up with Team. Skilled at communicating with developers especially in the handover stage.
                            <br/><br/>My goal is to share all the experiences that I have reached in practical life for beginners and professionals. We strive together to reach the goal in the smartest way without entering into conflicts with customers or employers.
                        </p>
                    </Col>
                </Row>
            </Col>
        </Row>
        <Row>
            <Col lg='2' className='d-none d-lg-flex'>
                <ul className='cv-experience__list sticky-lg-top'>
                    <li className='cv-experience__item'>
                        <button className='cv-experience__link active' ref={experienceLinkRef} onClick={()=>{navigateToSection(experienceRef)}}>
                            Experience
                        </button>
                    </li>
                    <li className='cv-experience__item'>
                        <button className='cv-experience__link' ref={educationLinkRef} onClick={()=>{navigateToSection(educationRef)}}>
                        Education
                        </button>
                    </li>
                    <li className='cv-experience__item'>
                        <button className='cv-experience__link' ref={coursesLinkRef} onClick={()=>{navigateToSection(coursesRef)}}>
                            Courses
                        </button>
                    </li>
                    <li className='cv-experience__item'>
                        <button className='cv-experience__link' ref={languagesLinkRef} onClick={()=>{navigateToSection(languagesRef)}}>
                            Languages
                        </button>
                    </li>
                    <li className='cv-experience__item'>
                        <button className='cv-experience__link' ref={skillsLinkRef} onClick={()=>{navigateToSection(skillsRef)}}>
                            Skills
                        </button>
                    </li>
                    <li className='cv-experience__item'>
                        <button className='cv-experience__link' ref={interestLinkRef} onClick={()=>{navigateToSection(interestRef)}}>
                            Interests
                        </button>
                    </li>
                </ul>
            </Col>
            <Col lg='10'>
                <div className='cv-sections__cont' ref={experienceRef} data-link='interestLinkRef'>
                    {jobData && jobData.map(job=>(
                        <CV2022Experience job={job} key={job.id+job.job_title}/>
                    ))}
                </div>

                <div className='cv-sections__cont' ref={educationRef} data-link='educationLinkRef'>
                    {educationData && educationData.map(job=>(
                        <CV2022Experience job={job} key={job.id+job.job_title}/>
                    ))}
                </div>

                <div className='cv-sections__cont' ref={coursesRef} data-link='coursesLinkRef'>
                    {coursesData && coursesData.map(job=>(
                        <Row className='cv-category--courses align-items-center' key={job.id+job.job_title}>
                            <Col lg='3' className='cv-courses__year-cont'>
                                {job.job_year &&<p className='cv-categoreis__year--courses'>{job.job_year}</p>}
                            </Col>
                            <Col lg='9' className='cv-courses__content'>
                                {job.job_title &&<p className='cv-courses__content--para'>{job.job_title}</p>}
                            </Col>
                        </Row>
                    ))}
                </div>

                <div className='cv-sections__cont' ref={languagesRef} data-link='languagesLinkRef'>
                    {languagesData && languagesData.map(job=>(
                        <CV2022Experience job={job} key={job.id+job.job_title}/>
                    ))}
                </div>

                <div className='cv-sections__cont' ref={skillsRef} data-link='skillsLinkRef'>
                    <Row>
                        <Col lg='3'></Col>
                        <Col lg='9' className='cv-categories__content'>
                            {
                                skillsData && skillsData.map(skill=>(
                                    <div className='cv-skills__skill-cont d-flex' key={skill.id+skill.name}>
                                        <p className='cv-skills__skill-title'>{skill.name}</p>
                                        <div className='cv-skills__rate-cont ms-auto'>
                                            <StarIcon className={`cv-skills__rate-icon ${skill.rate >=1 ?'filled':''}`}/>
                                            <StarIcon className={`cv-skills__rate-icon ${skill.rate >=2 ?'filled':''}`}/>
                                            <StarIcon className={`cv-skills__rate-icon ${skill.rate >=3 ?'filled':''}`}/>
                                            <StarIcon className={`cv-skills__rate-icon ${skill.rate >=4 ?'filled':''}`}/>
                                            <StarIcon className={`cv-skills__rate-icon ${skill.rate >=5 ?'filled':''}`}/>
                                        </div>
                                    </div>
                                ))
                        }
                        </Col>
                    </Row>
                </div>

                <div className='cv-sections__cont' ref={interestRef} data-link='interestLinkRef'>
                    <Row>
                        <Col lg='3'></Col>
                        <Col lg='9' className='cv-categories__content'>
                            {inteestData && inteestData.map((item,index)=>(
                                <p className='cv-interest__para' key={index+item}>
                                    {item}
                                </p>
                                ))}
                        </Col>
                    </Row>
                    {/* ))} */}
                </div>

            </Col>
        </Row>
    </section>
  )
}

export default CV2022Content