/* eslint-disable no-unused-vars */
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styles from './ContactUsDeck.module.css';
import ContactImgSrc from '../../../../assets/images/contactDeckImg.png';
import { ReactComponent as RightArrow } from '../../../../assets/images/RightArrowContactUS.svg';
import { Link } from 'react-router-dom';
function ContactUsDeck({ sectionData }) {
  return (
    <div className={styles['contact-us__deck']}>
      <Container>
        <Row>
          <Col
            lg="6"
            className="d-flex flex-column mb-md-0"
            data-aos="fade-up"
            data-aos-once="true"
          >
            {sectionData?.title && (
              <h2 className={styles['contact-us__deck-heading']}>
                {sectionData?.title}
              </h2>
            )}
            {sectionData?.description && (
              <p
                className={styles['contact-us__deck-content']}
                dangerouslySetInnerHTML={{ __html: sectionData?.description }}
              />
              //   <p className={styles['contact-us__deck-content']}>
              //     {sectionData?.description}
              //   </p>
            )}
            <div className={styles['contact-us__submit-form-cont']}>
              {sectionData?.pdf && (
                <a
                  href={sectionData.pdf}
                  target="_blank"
                  className={styles['contact-us__submit-form']}
                  rel="noopener noreferrer"
                >
                  Download PDF
                </a>
              )}
            </div>
          </Col>
          {sectionData?.image && (
            <Col lg="6">
              <div className={styles['contact-us__deck-img-cont']}>
                <div data-aos="new-animation" data-aos-once="true">
                  <img
                    src={sectionData?.image}
                    alt="contact images"
                    className=""
                  />
                </div>
              </div>
            </Col>
          )}
          <div className={styles['hide']}>
            <Col
              lg="6"
              className="d-flex flex-column mb-md-5 "
              data-aos="fade-up"
              data-aos-once="true"
            >
              <div
                className={styles['contact-us__submit-form-cont hideButton']}
              >
                {sectionData?.pdf && (
                  <a
                    href={sectionData.pdf}
                    target="_blank"
                    className={styles['contact-us__submit-form']}
                    rel="noopener noreferrer"
                  >
                    Download PDF
                  </a>
                )}
              </div>
            </Col>
          </div>
        </Row>
      </Container>
    </div>
  );
}

export default ContactUsDeck;
