import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styles from './ContactUsFAQ.module.css';
function ContactUsFAQ({ sectionData }) {
  return (
    <div className={styles['contact-us__FAQ']}>
      <Container>
        <Row>
          {sectionData && (
            <Col lg="6">
              <h2
                className={styles['contact-us__FAQ-heading']}
                data-aos="fade-up"
                data-aos-once="true"
              >
                {sectionData?.title}
              </h2>
            </Col>
          )}
          {sectionData?.description && (
            <Col lg="6" data-aos="fade-up" data-aos-once="true">
              <p className={styles['contact-us__FAQ-content']}>
                {sectionData?.description}
              </p>
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
}

export default ContactUsFAQ;
