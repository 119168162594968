import React from 'react';
import { Container } from 'reactstrap';
import CardStepsLearnui from '../CardStepsLearnui/CardStepsLearnui';
import './Steps3Learnui.scss';

const Steps3Learnui = ({data}) => {
  return (
    <div className='steps'>
        <Container>
          <div className='steps-header text-center'>
            {/* <h2>Only 3 steps to reach us</h2> */}
            <h2>{data?.StepsReachCards?.title}</h2>
            {/* <p>Get Free UI sessions, tutorials & live videos as a starting point of your UI career path</p> */}
            <p>{data?.StepsReachCards?.subTitle}</p>
          </div>
                
          <CardStepsLearnui data= {data}/>
                
        </Container>
    </div>
  )
}

export default Steps3Learnui