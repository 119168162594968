import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { Testim } from './style';
import { FixedButton, HeadTitle } from '../../Utilities';

function Testimonial({ sectionData }) {
  return (
    <Testim className="js-tesmonial">
      <div className="container">
        <Row>
          <Col md="6" className="p-0">
            <div className="testim-content sticky-lg-top " style={{ top: 160 }}>
              {sectionData?.title && (
                <h2 style={HeadTitle}>{sectionData?.title}</h2>
              )}
              {sectionData?.title &&
                sectionData?.description
                  ?.split('|')
                  .map((parg) => (
                    <p dangerouslySetInnerHTML={{ __html: parg }} />
                  ))}
              <FixedButton to="/contact-us" className="d-none d-md-flex">
                Request a proposal
              </FixedButton>
            </div>
          </Col>
          <Col md="6">
            <Row className="second-side ">
              {sectionData?.cards.length != 0 &&
                sectionData?.cards.map((card) => (
                  <Col xs={12} className="p-0" key={card.title}>
                    <Card className="border-0 p-4">
                      <CardBody className="card_body_test">
                        <svg
                          width="31"
                          height="25"
                          viewBox="0 0 31 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17.1665 19.1745C18.2087 18.9403 19.1329 18.5988 19.9391 18.1499C20.2931 17.9547 20.6372 17.7303 20.9715 17.4766C21.2861 17.2424 21.5712 16.9594 21.8268 16.6276C22.0825 16.3154 22.2889 15.9543 22.4462 15.5445C22.6036 15.1542 22.6822 14.7248 22.6822 14.2564L16.4881 14.2564L16.4881 -6.38679e-07L31 -1.90735e-06L31 9.86534C31 12.8318 30.6854 15.281 30.0561 17.2131C29.4269 19.1647 28.5224 20.7162 27.3425 21.8677C26.143 23.0191 24.6879 23.829 22.9772 24.2974C21.2664 24.7658 19.3295 25 17.1665 25L17.1665 19.1745ZM0.737394 19.1745C1.77957 18.9403 2.70377 18.5988 3.50999 18.1499C3.86394 17.9547 4.20806 17.7303 4.54234 17.4766C4.85696 17.2424 5.14209 16.9594 5.39772 16.6276C5.65335 16.3154 5.85982 15.9543 6.01713 15.5445C6.17444 15.1542 6.25309 14.7248 6.25309 14.2564L9.68117e-07 14.2564L-2.78221e-07 8.02757e-07L14.5709 -4.7107e-07L14.5709 9.86534C14.5709 12.8318 14.2563 15.281 13.627 17.2131C12.9978 19.1647 12.0834 20.7162 10.8839 21.8677C9.68443 23.0191 8.23914 23.829 6.54805 24.2974C4.8373 24.7658 2.90041 25 0.737394 25L0.737394 19.1745Z"
                            fill="#26e71e"
                          ></path>
                        </svg>
                        {card?.description && <p>{card?.description}</p>}
                        <Row className="info align-items-center">
                          <Col md="2" xs="3" className="client{img">
                            <img
                              src={card?.image}
                              className=""
                              alt="testim-img"
                            />
                          </Col>
                          <Col md="10" xs="12" className="ps-3">
                            {card?.title && <h4>{card?.title}</h4>}
                            {card?.jobTitle && <p>{card?.jobTitle}</p>}
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
            </Row>
          </Col>
          <Col>
            <FixedButton
              to="/contact-us?scroll=true"
              className="d-md-none hidden"
            >
              Request a proposal
            </FixedButton>
          </Col>
        </Row>
      </div>
    </Testim>
  );
}

export default Testimonial;
