import styled from 'styled-components';

export const Section = styled.div`
  padding: 70px 0 100px;
  h2 {
    font-family: 'Cairo', Sans-serif;
    font-size: 50px;
    line-height: 60px;
    font-weight: 700;
    margin-bottom: 30px;
  }
  p {
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
  }
  @media (max-width: 426px) {
    padding: 70px 20px 20px;
    h2 {
      font-size: 35px;
      margin-bottom: 20px;
      line-height: 43px;
    }
    p {
      font-size: 18px;
      line-height: 26px;
      &.aboutServ {
        display: none;
      }
    }
  }
`;
