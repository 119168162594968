import React, { useEffect } from 'react';
import { useState } from 'react';
import axiosConfig from '../../../axiosConfig';
import ThingsComponent from '../../../Components/LayoutiComponents/100-things';
import LayoutiSlider from '../../../Components/LayoutiComponents/Global/LayoutiSlider/LayoutiSlider';
import { useOutletContext } from 'react-router-dom';

function Things() {
  const [pageData, setPageData] = useState(
    localStorage.getItem('thingsPage')
      ? JSON.parse(localStorage.getItem('thingsPage'))
      : [],
  );
  const [loadactive, setLoadActive] = useOutletContext()
  const [sliderContent, setSliderContent] = useState({
    img: 'images/layouti/100-things/bg.jpg',
    head: '100 things we have learned the past years',
    text: 'Holding myself to the highest standards of creative and technical excellence. Every one, regardless of the scope of his/her lifecycle can expect nothing but the best from what I’ve learned..',
  });

  useEffect(() => {
    setSliderContent({
      img: pageData?.HeaderContent?.image
        ? pageData?.HeaderContent?.image
        : null,
      head: pageData?.HeaderContent?.title
        ? pageData?.HeaderContent?.title
        : null,
      text: pageData?.HeaderContent?.description
        ? pageData?.HeaderContent?.description
        : null,
    });
  }, [pageData]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  function getData() {
    setLoadActive(true)
    axiosConfig
      .get(`frontend/things`, {
        headers: {
          // 'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`,
          language: `en`,
        },
      })
      .then((res) => {
        setLoadActive(false)
        localStorage.setItem('thingsPage', JSON.stringify(res.data.data));
        setPageData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <LayoutiSlider data={sliderContent} />
      <ThingsComponent sectionData={pageData?.ThingsOpportunityAlwaysExists} />
    </>
  );
}

export default Things;
