import React from 'react'
import { Container } from 'react-bootstrap'
import { LayoutiProdImagesSec } from '../Styled/Index'

const ProjectImages = ({title, desc, img}) => {
    return (
        <LayoutiProdImagesSec>
            <Container>
                <h2>{title}</h2>
                <p>{desc}</p>
                <img src={img} alt='' className=''/>
            </Container>
        </LayoutiProdImagesSec>
    )
}

export default ProjectImages