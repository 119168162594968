import React from 'react'

const Card = ({cardData}) => {
    return (
        <div className='layouti_proccess_card mt-0'>
            {cardData?.image&&<img src={cardData?.image} width="90" height="71" alt='card icon'/>}
            {cardData?.title&&<h2 className='mt-5 mb-3'>{cardData?.title}</h2>}
            <div>
                {
                    cardData?.processPoints && cardData?.processPoints.map((point,index)=>(
                        <p className='layouti_proccess_card-para' key={index}>{point}</p>
                    ))
                }
            </div>
        </div>
    )
}



export default Card