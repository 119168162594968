import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Collapse } from 'reactstrap';
import classnames from 'classnames';
import { Link, NavLink } from 'react-router-dom'; /* eslint-disable-line */
import './sidebar.scss';
import { useSelector } from 'react-redux';
import { ReactComponent as LogoWhite } from '../../assets/images/newLogoLight.svg';
import { ReactComponent as ExitButtonWhite } from '../../assets/images/ExitButtonWhite.svg';
import { ReactComponent as TopRightArrow } from '../../assets/images/TopRightArrow.svg';
import { ReactComponent as AcademyLogo } from '../../assets/images/AcademyLogo.svg';
import { ReactComponent as UiAvatarLogo } from '../../assets/images/UiAvatarLogo.svg';
import { ReactComponent as Ui365Design } from '../../assets/images/365UiDesign.svg';
import { ReactComponent as EtoyApp } from '../../assets/images/etoyApp.svg';
import { ReactComponent as ScanSmile } from '../../assets/images/ScanSmile.svg';
import axiosConfig from '../../axiosConfig';

const SideBar = () => {
  const [active, setActive] = useState({
    ui: '',
    content: '',
  }); /* eslint-disable-line */
  const linkType = useSelector((state) => state.linkactive.type);
  const [col1, setcol1] = useState(false);
  const [col2, setcol2] = useState(false);
  const [col3, setcol3] = useState(false);
  const [col4, setcol4] = useState(false);
  const [col5, setcol5] = useState(false);

  const [footerOurProducts, setFooterOurProducts] = useState([]);
  const [footerInHouseWorks, setFooterInHouseWorks] = useState([]);
  const [footerContent, setFooterContent] = useState({});
  const [footerColors, setFooterColors] = useState({});
  const [footerSocialMedia, setFooterSocialMedia] = useState([]);

  const t_col1 = () => {
    setcol1(!col1);
    setcol2(false);
    setcol3(false);
    setcol4(false);
    setcol5(false);
  };

  const t_col2 = () => {
    setcol2(!col2);
    setcol1(false);
    setcol3(false);
    setcol4(false);
    setcol5(false);
  };

  const t_col3 = () => {
    setcol3(!col3);
    setcol1(false);
    setcol2(false);
    setcol4(false);
    setcol5(false);
  };

  const t_col4 = () => {
    /* eslint-disable-line */
    setcol4(!col4);
    setcol1(false);
    setcol2(false);
    setcol3(false);
    setcol5(false);
  };
  const t_col5 = () => {
    /* eslint-disable-line */
    setcol5(!col5);
    setcol1(false);
    setcol2(false);
    setcol4(false);
    setcol3(false);
  };
  function sideToggle() {
    document.querySelector('.sidebar').classList.toggle('active');
  }
  useEffect(() => {
    switch (linkType) {
      case 'ui':
        setActive({ ui: 'active', content: '' });
        break;
      case 'content':
        setActive({ ui: '', content: 'active' });
        break;
      default:
        break;
    }
  }, [linkType]);

  function getData() {
    axiosConfig
      .get(`/frontend/footer`, {
        headers: {
          language: `en`,
        },
      })
      .then((res) => {
        // localStorage.setItem('aboutPage',JSON.stringify(res.data.data))
        setFooterOurProducts(res.data.data.FooterOurProducts);
        setFooterInHouseWorks(res.data.data.FooterInHouseWorks);
        setFooterContent(res.data.data.FooterContent);
        setFooterColors(res.data.data.FooterColors);
        setFooterSocialMedia(res.data.data.FooterSocialMedia);
      })
      .catch((err) => {
        console.error(err);
      });
  }
  useEffect(() => {
    getData();
  }, []);
  return (
    <div
      style={{ backgroundColor: footerColors.meddleColor }}
      className="sidebar"
    >
      <Container className="sidebar_container">
        {/**********  desktop version  *************/}
        <Row className="justify-content-between align-items-center side_header">
          <Col className="p-0">
            <Row className="align-items-center flex-nowrap gap-2 m-0">
              <Col className="p-0">
                <Link to="/">
                  <img
                    className="logo"
                    src={footerContent.logo}
                    alt=""
                    height="45px"
                  />
                </Link>
              </Col>
              <Col lg={11} md={10} xs={8} className=" side_header_title">
                {/* <h2 className="footer_brand m-0 fs-md-3 fs-4">Layouti</h2> */}
              </Col>
            </Row>
          </Col>
          <Col xs="2" className="">
            <button className="toggle_close" onClick={sideToggle}>
              <ExitButtonWhite />
            </button>
          </Col>
        </Row>
        <Row className="m-0 gap-4">
          {/* <p className="footer_slug m-0 fs-md-6 fw-lighter px-0 mx-0">
              Great idea, desevers great layout
            </p> */}
          <Col className="p-0 mt-5 desc_foot">
            <Row>
              <Col className="side_navbar_one p-0">
                <ul className="footer_navs ">
                  <li className="">
                    <NavLink
                      to="/"
                      className="sideBar_main_links"
                      exact="true"
                      onClick={sideToggle}
                    >
                      Home
                    </NavLink>
                  </li>
                  <li className="">
                    <NavLink
                      to="/services"
                      className="sideBar_main_links"
                      onClick={sideToggle}
                    >
                      Services
                    </NavLink>
                  </li>
                  <li className="">
                    <NavLink
                      to="/works"
                      className="sideBar_main_links"
                      onClick={sideToggle}
                    >
                      Works
                    </NavLink>
                  </li>
                  <li className="">
                    <NavLink
                      to="/about"
                      className="sideBar_main_links"
                      onClick={sideToggle}
                    >
                      About
                    </NavLink>
                  </li>
                  <li className="">
                    <NavLink
                      to="/contact-us"
                      className="sideBar_main_links"
                      onClick={sideToggle}
                    >
                      Contact Us
                    </NavLink>
                  </li>
                  <li className="">
                    <NavLink
                      to="/100-things"
                      className="sideBar_main_links"
                      onClick={sideToggle}
                    >
                      100 Things
                    </NavLink>
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>

          <Col className="p-0 mt-5 desc_foot col-4 ">
            <h3 className="fs-5 mb-4 fw-bolder">Our Projects</h3>
            {footerOurProducts.map((product) => (
              <Row className="m-0 mb-4">
                <Col className="p-0">
                  <div className="footer__link__row d-flex align-items-center gap-4">
                    <img src={product.image} alt="" />
                    <h3 className="m-0">{product.title}</h3>
                  </div>
                </Col>
                <Col
                  className="d-flex align-items-center justify-content-end"
                  style={{ marginRight: '30px' }}
                >
                  <a
                    href={product.link}
                    className="external__link"
                  >
                    <TopRightArrow />
                  </a>
                </Col>
              </Row>
            ))}

            <Row>
              <Col className="">
                <h3 className="fs-5 mb-4 mt-1 fw-bolder"> In-house Product </h3>
                {footerInHouseWorks.map((product) => (
                  <Row className=" m-0 mb-4">
                    <Col className="p-0">
                      <div className="footer__link__row d-flex align-items-center gap-4">
                        <img src={product.image} alt="" />
                        <h3 className="m-0">{product.title}</h3>
                      </div>
                    </Col>
                    <Col
                      className="d-flex align-items-center justify-content-end"
                      style={{ marginRight: '30px' }}
                    >
                      <a
                        href={product.link}
                        className="external__link"
                      >
                        <TopRightArrow />
                      </a>
                    </Col>
                  </Row>
                ))}
              </Col>
            </Row>
          </Col>

          <Col className="p-0 mt-5 desc_foot">
            <Row className="m-0 mb-4">
              <Col>
                <h3 className="fs-5 fw-bolder">UI/UX Education</h3>
                <ul className="footer_navs mt-4">
                  <li className="">
                    <a
                      href="https://resourchub.com/resources"
                      className="footer_navs_link"
                      onClick={sideToggle}
                    >
                      ResourcHub
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
            <Row className="m-0 mb-4">
              <Col>
                <h3 className="fs-5 mt-2 fw-bolder">Other Links</h3>
                <ul className="footer_navs mt-4">
                  <li className="">
                    <Link
                      to="/365design"
                      className="footer_navs_link"
                      onClick={sideToggle}
                    >
                      365 Design
                    </Link>
                  </li>
                  <li className="">
                    <Link
                      to="/uiavatar"
                      className="footer_navs_link"
                      onClick={sideToggle}
                    >
                      UI Avatar
                    </Link>
                  </li>
                  <li className="">
                    <Link
                      to="/uiavatar/contentgenerator"
                      className="footer_navs_link"
                      onClick={sideToggle}
                    >
                      Content Generator
                    </Link>
                  </li>
                  <li className="">
                    <Link
                      to="/365design/links"
                      className="footer_navs_link"
                      onClick={sideToggle}
                    >
                      Links
                    </Link>
                  </li>
                  <li className="">
                    <Link
                      to="/365design/products"
                      className="footer_navs_link"
                      onClick={sideToggle}
                    >
                      Products
                    </Link>
                  </li>
                  {/* <li className="">
                    <Link
                      to="/365design/CV2022"
                      className="footer_navs_link"
                      onClick={sideToggle}
                    >
                      CV 2022
                    </Link>
                  </li>
                  <li className="">
                    <Link
                      to="/resources"
                      className="footer_navs_link"
                      onClick={sideToggle}
                    >
                      Resources
                    </Link>
                  </li> */}
                </ul>
              </Col>
            </Row>
          </Col>

          <Col className="p-0 mt-5 desc_foot">
            <Row className="mb-4">
              <Col xs={12}>
                <h3 className="fs-5 fw-bolder">Help?</h3>
                <ul className="side_navs mt-4">
                  <li className="">
                    <Link
                      to="/term&condition"
                      className="footer_navs_link"
                      onClick={sideToggle}
                    >
                      Terms & Conditions
                    </Link>
                    <li className="pt-2">
                      <Link
                        to="/"
                        className="footer_navs_link"
                        onClick={sideToggle}
                      >
                        Sitemap
                      </Link>
                    </li>
                  </li>
                </ul>
              </Col>
              <Col>
                <h3 className="fs-5 fw-bolder">{footerContent.title}</h3>
                <ul className="side_navs mt-4">
                  <li>
                    <p className="m-0  w-70">{footerContent.subDescription}</p>
                  </li>
                  <li className="nav_item nav_item_btn mt-2">
                    {/* <a className='nav_item_link' onClick={sideToggle} href='https://365design.layouti.com/layouti-tree/' target='blank'>Reach out</a> */}
                    <Link
                      className={`nav_item_link`}
                      onClick={sideToggle}
                      to="/contact-us?scroll=true"
                    >
                      Reach out
                    </Link>
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>

          {/**********  mobile version  *************/}
          <Col lg={6} sm={12} className="mt-4 p-0 mobile_foot">
            <div className="accordion" id="accordion">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className={classnames(
                      'accordion-button',
                      'accordion-button-footer',
                      'fw-medium',
                      { collapsed: !col1 },
                    )}
                    type="button"
                    onClick={t_col1}
                    style={{ cursor: 'pointer' }}
                  >
                    <h3 className="fs-md-5 fs-6 m-0">Main Menu</h3>
                  </button>
                </h2>

                <Collapse isOpen={col1} className="accordion-collapse">
                  <div className="accordion-body">
                    <ul className="footer_navs">
                      <li className="">
                        <NavLink
                          to="/"
                          className="footer_navs_link"
                          exact="true"
                          onClick={sideToggle}
                        >
                          Home
                        </NavLink>
                      </li>
                      <li className="">
                        <NavLink
                          to="/services"
                          className="footer_navs_link"
                          onClick={sideToggle}
                        >
                          Services
                        </NavLink>
                      </li>
                      <li className="">
                        <NavLink
                          to="/works"
                          className="footer_navs_link"
                          onClick={sideToggle}
                        >
                          Works
                        </NavLink>
                      </li>
                      <li className="">
                        <NavLink
                          to="/about"
                          className="footer_navs_link"
                          onClick={sideToggle}
                        >
                          About
                        </NavLink>
                      </li>
                      <li className="">
                        <NavLink
                          to="/contact-us"
                          className="footer_navs_link"
                          onClick={sideToggle}
                        >
                          Contact Us
                        </NavLink>
                      </li>
                      <li className="">
                        <NavLink
                          to="/100-things"
                          className="footer_navs_link"
                          onClick={sideToggle}
                        >
                          100 Things
                        </NavLink>
                      </li>
                      {/* <li className=''>
                                                        <a href='/#' className='footer_navs_link'>Layouti History</a>
                                                    </li> */}
                    </ul>
                  </div>
                </Collapse>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className={classnames(
                      'accordion-button',
                      'accordion-button-footer',
                      'fw-medium',
                      { collapsed: !col4 },
                    )}
                    type="button"
                    onClick={t_col4}
                    style={{ cursor: 'pointer' }}
                  >
                    <h3 className="fs-md-5 fs-6 m-0">Our Products</h3>
                  </button>
                </h2>

                <Collapse isOpen={col4} className="accordion-collapse">
                  <div className="accordion-body">
                    {footerOurProducts.map((item) => (
                      <Row className="mb-4 mt-3">
                        <Col>
                          <div className="footer__link__row d-flex align-items-center gap-4">
                            <img src={item.image} alt="" />
                            <h3 className="m-0">{item.title}</h3>
                          </div>
                        </Col>
                        <Col className="d-flex align-items-center justify-content-end">
                          <a href={item.link} className="external__link">
                            <TopRightArrow />
                          </a>
                        </Col>
                      </Row>
                    ))}
                  </div>
                </Collapse>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingTwo">
                  <button
                    className={classnames(
                      'accordion-button',
                      'accordion-button-footer',
                      'fw-medium',
                      { collapsed: !col5 },
                    )}
                    type="button"
                    onClick={t_col5}
                    style={{ cursor: 'pointer' }}
                  >
                    <h3 className="fs-md-5 fs-6 m-0">In-house Works</h3>
                  </button>
                </h2>

                <Collapse isOpen={col5} className="accordion-collapse">
                  <div className="accordion-body">
                    {footerInHouseWorks.map((item) => (
                      <Row className="mb-4 mt-3">
                        <Col>
                          <div className="footer__link__row d-flex align-items-center gap-4">
                            <img src={item.image} alt="" />{' '}
                            <h3 className="m-0">{item.title}</h3>
                          </div>
                        </Col>
                        <Col className="d-flex align-items-center justify-content-end">
                          <a
                            href="https://etoyapp.store/"
                            className="external__link"
                          >
                            <TopRightArrow />
                          </a>
                        </Col>
                      </Row>
                    ))}
                  </div>
                </Collapse>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header" id="headingTwo">
                  <button
                    className={classnames(
                      'accordion-button',
                      'accordion-button-footer',
                      'fw-medium',
                      { collapsed: !col2 },
                    )}
                    type="button"
                    onClick={t_col2}
                    style={{ cursor: 'pointer' }}
                  >
                    <h3 className="fs-md-5 fs-6 m-0">Other Links</h3>
                  </button>
                </h2>

                <Collapse isOpen={col2} className="accordion-collapse">
                  <div className="accordion-body">
                    <ul className="footer_navs">
                      <li className="">
                        <Link
                          to="/365design/"
                          className="footer_navs_link"
                          onClick={sideToggle}
                        >
                          365 Design
                        </Link>
                      </li>
                      <li className="">
                        <Link
                          to="/uiavatar"
                          className="footer_navs_link"
                          onClick={sideToggle}
                        >
                          UI Avatar
                        </Link>
                      </li>
                      <li className="">
                        <Link
                          to="/uiavatar/contentgenerator"
                          className="footer_navs_link"
                          onClick={sideToggle}
                        >
                          Content Generator
                        </Link>
                      </li>
                      <li className="">
                        <Link
                          to="/365design/links"
                          className="footer_navs_link"
                          onClick={sideToggle}
                        >
                          Links
                        </Link>
                      </li>
                      <li className="">
                        <Link
                          to="/365design/products"
                          className="footer_navs_link"
                          onClick={sideToggle}
                        >
                          Products
                        </Link>
                      </li>

                      {/* <li className="">
                        <Link
                          to="/365design/CV2022"
                          className="footer_navs_link"
                          onClick={sideToggle}
                        >
                          CV 2022
                        </Link>
                      </li>
                      <li className="">
                        <Link
                          to="/resources"
                          className="footer_navs_link"
                          onClick={sideToggle}
                        >
                          Resources
                        </Link>
                      </li> */}
                    </ul>
                  </div>
                </Collapse>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header" id="headingThree">
                  <button
                    className={classnames(
                      'accordion-button',
                      'accordion-button-footer',
                      'fw-medium',
                      { collapsed: !col3 },
                    )}
                    type="button"
                    onClick={t_col3}
                    style={{ cursor: 'pointer' }}
                  >
                    <h3 className="fs-md-5 fs-6 m-0">Helps?</h3>
                  </button>
                </h2>
                <Collapse isOpen={col3} className="accordion-collapse">
                  <div className="accordion-body">
                    <ul className="footer_navs">
                      <li>
                        <a className="footer_navs_link" href="/#">
                          Terms & Conditions
                        </a>
                      </li>
                      <li>
                        <a className="footer_navs_link" href="/#">
                          Sitemap
                        </a>
                      </li>
                    </ul>
                  </div>
                </Collapse>
              </div>
            </div>
            <Row
              style={{ paddingLeft: '20px', paddingRight: '20px' }}
              className="m-0 ml-2 mr-2"
            >
              <Col className="mt-4 p-0">
                <h3 className="fs-5 fw-bolder">Hire us</h3>
                <ul className="side_navs">
                  <li>
                    <p className="side_navs_link  w-70">
                      Great ideas get more hits with great layouts. Therefore,
                      we design.
                    </p>
                  </li>
                  <li className="nav_item nav_item_btn mt-2">
                    <Link
                      className={`nav_item_link`}
                      onClick={sideToggle}
                      to="/contact-us?scroll=true"
                    >
                      Reach out
                    </Link>
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SideBar;
