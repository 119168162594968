import React, { useEffect } from 'react';
import BuildTimesless from './BuildTimesless/BuildTimesless';
import HeaderContent from './HeaderContent/HeaderContent';
import Projects from './Projects/Projects';
import { LayoutiActions } from '../../../store/Layouti';
import { useDispatch } from 'react-redux';
import axiosConfig from '../../../axiosConfig';
import { useState } from 'react';
import { useOutletContext } from 'react-router-dom';

const Works = () => {
  const dispatch = useDispatch();
  const [pageData, setPageData] = useState(null);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [activeCategory, setActiveCategory] = useState('');
  const [loadactive, setLoadActive] = useOutletContext()
  useEffect(() => {
    dispatch(LayoutiActions.setHeader(1));
  }, [dispatch]);

  function getData(activeCategory) {
    !activeCategory&&setLoadActive(true)
    axiosConfig
      .get(`frontend/work?category=${activeCategory}`, {
        headers: {
          language: `en`,
        },
      })
      .then((res) => {
        !activeCategory && window.scrollTo(0, 0);
        setPageData(res.data.data);
        setTimeout(() => {
          !activeCategory&&setLoadActive(false)
        }, 1500)
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getData(activeCategory);
  }, [activeCategory]);

  useEffect(() => {
    setFilteredProjects(pageData?.Projcts);
  }, [pageData]);

  // console.log(filteredProjects);

  function filterProjects(categoryId) {
    let modififedFilteredProjects = pageData?.Projcts
      ? [...pageData?.Projcts]
      : [];
    // eslint-disable-next-line
    if (categoryId != -1) {
      modififedFilteredProjects = modififedFilteredProjects.filter(
        (project) => {
          // eslint-disable-next-line
          return project?.category?.id == categoryId;
        },
      );
      setFilteredProjects(modififedFilteredProjects);
    } else {
      setFilteredProjects(pageData?.Projcts);
    }
  }
  useEffect(() => {
    filterProjects(activeCategory);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCategory]);

  return (
    <div>
      <HeaderContent secionData={pageData?.WorkHeaderContent} />
      <BuildTimesless
        secionData={pageData}
        activeCategory={activeCategory}
        setActiveCategory={setActiveCategory}
      />
      <Projects filteredProjects={filteredProjects} />
    </div>
  );
};

export default Works;
