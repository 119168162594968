import React from 'react'
import Masonry from 'react-masonry-css'
import CardSkelaton from '../../365HomeCard/CardSkelaton'
import Card from '../Card'
const Headlines = (props) => {
    const breakpointColumnsObj = {
        default: 3,
        600: 1
    };
    return (
            <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column">
                {props.data.map((d, i) => <Card key={i} data={d}/>)}
                {props.data.length === 0&&[1,2,3,4,5,6,7,8,9,10,11,12].map((i) => <CardSkelaton key={i}/> )}
            </Masonry>
    )
}

export default Headlines