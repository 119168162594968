import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './style.scss'

const Persentation = ({data}) => {
  return (
    <div className='presentation'>
        <Container>
            {data?.BodyBranding.length > 0 &&<div className='body_presentation'>
                <Row>
                    <Col>
                        <h2>{data?.BodyBranding[1]?.title}</h2>
                    </Col>
                    <Col>
                        {data?.BodyBranding[1]?.description?.split("/").map((text,i) => <p key={i}>{text}</p> )}
                    </Col>
                </Row>
            </div>}
        </Container>

        {data?.BodyBrandingImages&&<div className='body_branding_images d-block mb-5'> 
            {data.BodyBrandingImages.map((image, i) => <img src={image.image} key={i} className='w-100' alt=""/> )}
        </div>}
    </div>
  )
}

export default Persentation