import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import AboutFeactureCard from '../AboutFeactureCard/AboutFeactureCard.jsx';
import './AboutFeaturesSection.scss';
import { ReactComponent as TeamIcon } from '../../../../assets/images/teamIcon.svg';
const AboutFeaturesSection = ({ sectionData }) => {
  return (
    <section className="about-features js-about-features">
      <Container className="about-features-cont">
        {sectionData?.title && (
          <h2 className="about-features__title">{sectionData?.title}</h2>
        )}
        <Row>
          {sectionData?.cards.length != 0 &&
            sectionData?.cards.map((feature, index) => (
              <Col md="6" key={index} className="mb-1">
                <AboutFeactureCard
                  cardData={feature}
                  cardIndex={index}
                  comp={<TeamIcon />}
                />
              </Col>
            ))}
        </Row>
      </Container>
    </section>
  );
};

export default AboutFeaturesSection;
