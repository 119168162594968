function UseScrollHover() {
    const showCursor =()=>{
        let cursor = document.querySelector('.cursor-dot-outline')
        cursor.classList.add('cursor-active-view-title')
    }
    const hideCursor =()=>{
        let cursor = document.querySelector('.cursor-dot-outline')
        cursor.classList.remove('cursor-active-view-title')
    }
    
    return [showCursor,hideCursor]
}

export default UseScrollHover
