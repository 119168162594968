import styled from 'styled-components'

export const ProcessCards = styled.div
`
    margin-top: -240px;
    position: relative;
    z-index: 2;
    h2{
        color:#FFF;
        font-weight: bold;
        
    }
    .process_nav_btns {
        @media (max-width: 600px) {
            display: none
        }
    }
    .next-arrow,
    .prev-arrow{
        background: #FFF;
        width: 80px;
        height: 80px;
        svg {
            color: #2C2C2C;
            font-size: 40px;
        }
        &.not-active{
            opacity: .35;
        }
    }
    .card_col  {
        @media(max-width:426px){
            padding: 0 !important;
        }
    }
    .card-swiper {
        padding-bottom: 50px;
        .swiper-scrollbar {
            height: 3px !important;
            width: 100% !important;
            left: 0 !important;
            border-radius: 0 !important;
        }
        
        .swiper-wrapper{
            cursor: grab;
            .scope_card {
                padding: 40px;
                background-color: #FFF;
                border: 1px solid #E3E3E3;
                margin: 0 auto;
                width:90%;
                svg{
                    margin-bottom: 15px;
                }
                >h3 {
                    font-size: 30px;
                    line-height: 40px;
                    font-weight: 700;
                    font-family: 'Cairo';
                    margin-bottom: 20px;
                    color: #23262B;
                }
                >p {
                    font-size: 18px;
                    line-height: 27px;
                    font-weight: 400;
                    font-family: 'Cairo';
                    color: #23262B;
                }
                @media(max-width:426px){
                    width: 95%;
                    height: 410px 
                }
            }
            
        }
    }

    @media(max-width:767px){
        h2{
            font-size:30px;
            color:#23262b;
            margin-top: 50px;
        }
    }
    @media(max-width:426px){
        margin-top: 40px;
        h2{
            margin-left: 20px;
            margin-bottom:10px;
        }
        .next-arrow,
        .prev-arrow{
            background: #23262b;
            margin-bottom:20px;
            svg path{
                fill:white
            }
        }
    }

.horizontal-wrapper{
    padding:20px 0;
    background-color:#F7F9FC;
}
.sticky-wrapper{
    position:sticky;
    top:100px;
    width:100%;
    overflow:hidden;
    
}
.element-wrapper{
    display:flex;
    position: relative;
}
.scope_card {
    padding: 40px;
    background-color: #FFF;
    border: 1px solid #E3E3E3;
    // margin: 0 auto;
    width:30%;
    flex-shrink:0;
    margin-right:20px;
   
    svg{
        margin-bottom: 15px;
    }
    &:last-of-type{
       margin-right:200px 
    }
    >h3 {
        font-size: 30px;
        line-height: 40px;
        font-weight: 700;
        font-family: 'Cairo';
        margin-bottom: 10px;
        color: #23262B;
        margin-top: 30px;
    }
    >p {
        font-size: 18px;
        line-height: 27px;
        font-weight: 400;
        font-family: 'Cairo';
        color: #23262B;
    }
}

`




