import React from 'react'
import { Container, Col, Row} from 'react-bootstrap'

import './styles.scss'
const MobileApps = ({data}) => {
  return (
    <>{data?.DesignAppMobileApps?.title&&<div className='mobile_apps'>
        <Container>
            <Row>
                <Col md={5}>
                <h1 className="mobile_apps_title">{data?.DesignAppMobileApps?.title}</h1>
                </Col>
                <Col md={7}>
                {data?.DesignAppMobileApps?.description?.split('/').map((text, i) => <p key={i} className='mobile_apps_desc'>{text}</p>)}
                </Col>
            </Row>
            
            
            
            <Row className="justify-content-between">
                <Col md={12} >
                    <img className="mobile_apps_img" src={data?.DesignAppMobileApps?.cards[0]?.image} alt=""/>
                </Col>
                <Col md={6} >
                    <img className="mobile_apps_img" src={data?.DesignAppMobileApps?.cards[1]?.image} alt=""/>
                </Col>
                <Col md={6}>
                    <img className="mobile_apps_img" src={data?.DesignAppMobileApps?.cards[2]?.image} alt=""/>
                </Col>
                <Col md={12}>
                    <img className="mobile_apps_img w-100" src={data?.DesignAppMobileApps?.cards[3]?.image} alt=""/>
                </Col>
            </Row>
        </Container>
    </div>}</>
  )
}

export default MobileApps