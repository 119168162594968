import { useState, useEffect } from 'react';

const useWidth = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const widthHandler = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', widthHandler);
    return () => {
      window.removeEventListener('resize', widthHandler);
    };
  }, []);

  return width;
};

export default useWidth;
