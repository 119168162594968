import React, { useEffect } from 'react';
import { Route, Navigate, Routes, useLocation } from 'react-router-dom';

//Layouti
import LayoutiIndex from '../Pages/Layouti/Index/LayoutiIndex';
import Works from '../Pages/Layouti/Works/Works';
import WorksDetails from '../Pages/Layouti/WorksDetails/WorksDetails';

//UI Avatar
import Index from '../Pages/UiAvatar/Index/Index';
import UIGenerator from '../Pages/UiAvatar/UIGenerator/UIGenerator';
import ContentGenerator from '../Pages/UiAvatar/ContentGenerator/ContentGenerator';

//365Design
import Index365 from '../Pages/365Design/Index/Index365.jsx';
import Home365 from '../Pages/365Design/Home/Home365';
import Category from '../Pages/365Design/Category/Category';
import Links from '../Pages/365Design/Links/Links';
import Details from '../Pages/365Design/Details/Details';
import Products from '../Pages/365Design/Products/Products';
import LearnUi from '../Pages/LearnUi/Index/LearnUi';
import HomeLearnUi from '../Pages/LearnUi/HomeLearnUi/HomeLearnUi';

import axios from 'axios';
import { useDispatch } from 'react-redux';
import { MainDataActions } from '../store/MainData';
import LayoutiHomePage from '../Pages/Layouti/LayoutiHomePage/LayoutiHomePage';
import LayoutiServices from '../Pages/Layouti/Services';
import LayoutiBlog from '../Pages/Layouti/Blog';
import Article from '../Pages/Layouti/Article';
import Things from '../Pages/Layouti/100-things';
import ContactUs from '../Pages/Layouti/ContactUs';
import About from '../Pages/Layouti/About/About';
import CV2022 from '../Pages/365Design/CV2022/CV2022';
import Aos from 'aos';
import { AnimatePresence } from 'framer-motion';
import HomeResourses from '../Pages/Resourses/Home/HomeResourses';
import IndexResourses from '../Pages/Resourses/Index/IndexResourses';
import CategoryResoursesPage from '../Pages/Resourses/CategoryResoursesPage/CategoryResoursesPage';
import TermsIndex from '../Pages/Terms/TermsIndex';
import Terms from '../Pages/Terms/Terms';
function AnimatedRoutes() {
  const dispatch = useDispatch();

  // useEffect(() => {
  //   axios.get(`${process.env.REACT_APP_API}mainData`)
  //   .then(res => {
  //     dispatch(MainDataActions.setData(res.data.data))
  //   })
  // }, [dispatch])
  useEffect(() => {
    Aos.init({
      duration: 500,
    });
    Aos.refresh();
    let animationFunction = ({ detail }) => {};
    document.addEventListener('aos:in:super-duper', animationFunction);

    return () => {
      document.removeEventListener('aos:in:super-duper', animationFunction);
    };
  }, []);
  const location = useLocation();
  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        {/* Layouti Router */}
        <Route path="/" element={<LayoutiIndex />}>
          <Route index element={<LayoutiHomePage />} />
          <Route path="works" element={<Works />} />
          <Route path="works/details/:id" element={<WorksDetails />} />
          <Route path="about" element={<About />} />
          <Route path="/services" element={<LayoutiServices />} />
          {/* <Route path="/blog" element={<LayoutiBlog />} /> */}
          <Route path="/blog/:id" element={<Article />} />
          <Route path="/100-things" element={<Things />} />
          <Route path="/contact-us" element={<ContactUs />} />
        </Route>

        {/* uiavatar Router */}
        <Route path="/uiavatar" element={<Index />}>
          <Route index element={<UIGenerator />} />
          <Route path="contentgenerator" element={<ContentGenerator />} />
          <Route path="*" element={<Navigate to="/uiavatar" replace />} />
        </Route>

        {/* 365design Router */}
        <Route path="/365design" element={<Index365 />}>
          <Route index element={<Home365 />} />
          <Route path="category/:id" element={<Category />} />
          <Route path="details/:id" element={<Details />} />
          <Route path="links" element={<Links />} />
          <Route path="products" element={<Products />} />
          <Route path="CV2022" element={<CV2022 />}></Route>
          <Route path="*" element={<Navigate to="/365design" replace />} />
        </Route>

        {/* resourses Router */}
        <Route path="/resources" element={<IndexResourses />}>
          <Route index element={<HomeResourses />} />
          <Route path="*" element={<Navigate to="/resources" replace />} />
        </Route>
        <Route
          path="/resources/:id"
          element={<CategoryResoursesPage />}
        ></Route>

        {/* learnui Router */}
        <Route path="/learnui" element={<LearnUi />}>
          <Route index element={<HomeLearnUi />} />
          <Route path="*" element={<Navigate to="/learnui" replace />} />
        </Route>

        {/* uiavatar Router */}
        <Route path="/term&condition" element={<TermsIndex />}>
          <Route index element={<Terms />} />
        </Route>

      </Routes>
    </AnimatePresence>
  );
}

export default AnimatedRoutes;
